@import "plugins/bootstrap/bootstrap";
@import "cms-style";
//@import "../js/plugins/lightbox/self-plugin-lightbox.scss";
@import "../js/plugins/select2/select2";
$special_font:'Canela Web', 'Spectral', serif;
$main_font:'HK Grotesk';
$font_color1:#002043;
$font_color2:#5b5b5b;
$font_color3:#696969;
$theme_color1:#edeae6;
$theme_color2:#efefef;
$theme_color3:#1a1c19;
$header_color:#1a243c;

$white:#ffffff;
$black:#000000;

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin vetical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin h1{
    font-size: 130px;
}

@mixin h2{
    font-size: 60px;
    @media only screen and (max-width: 1500px) {
        font-size: 48px;
    }
    @media only screen and (max-width: 1200px) {
        font-size: 36px;
    }
}

@mixin h3{
    font-size: 48px;
    @media only screen and (max-width: 1500px) {
        font-size: 40px;
    }
    @media only screen and (max-width: 1200px) {
        font-size: 32px;
    }
}

@mixin h4{
    font-size: 36px;
    @media only screen and (max-width: 1500px) {
        font-size: 30px;
    }
}

@mixin h5{
    font-size: 24px;
    @media only screen and (max-width: 1500px) {
        font-size: 21px;
    }
}

@mixin h6{
    font-size: 21px;
    @media only screen and (max-width: 1500px) {
        font-size: 18px;
    }
}

@mixin p{
    font-size: 16px;
    @media only screen and (max-width: 1500px) {
        font-size: 14px;
    }
}

.default_font{
    font-size: 13px;
    line-height: 25px;
    @media only screen and (max-width: 991px) {
        iframe{
            width: 100%;
            height: auto;
        }
    }
    a{
        text-decoration: underline !important;
    }
}

.page{
    overflow: hidden;
    font-family: $main_font;
}

body{
    font-family: $main_font;
    color:$font_color1;
    em{
        font-family: 'Arial', sans-serif;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,img{
        margin: 0;
        font-weight: 400;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none !important;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    iframe{
        border:none;
    }
}

.row{
    &.inline_row{
        > div{
            display: inline-block;
            vertical-align: middle;
            float: none;
        }
    }
}

.section_header2{
    font-family: $special_font;
    color: $font_color1;
    text-transform: uppercase;
    margin-top: -8px;
    @media only screen and (max-width: 991px) {
        margin-top: 0;
    }
    &.white{
        color: $white;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
        text-align: center;
    }
    h2, p{
        letter-spacing: 2px;
        @include h5;
        line-height: 35px;
        font-weight: 600;
        @media only screen and (max-width: 1300px) {
            font-size: 17px !important;
            line-height: 27px;
        }
    }
    .date{
        margin-top: -21px;
        padding-top: 18px;
        p{
            @include p;
            font-family: $main_font;
            color: #838383;
        }
    }
}

.more_btn{
    letter-spacing: 2px;
    font-family: $special_font;
    @include p;
    text-transform: uppercase;
    display: block;
    font-weight: 400;
    color: #696969;
    display: inline-block;
    &:after{
        content: url("./../img/more_arr_icon2.png");
        position: relative;
        bottom: 2px;
    }
    &:hover{
        &:after{
            content: url("./../img/more_arr_icon.png");
        }
    }
    &.white{
        color: $white;
        &:after{
            content: url("./../img/more_arr_icon3.png");
        }
    }
}

.more_btn1{
    letter-spacing: 1px;
    font-family: $special_font;
    font-weight: 700;
    display: inline-block;
    @include p;
    text-transform: uppercase;
    padding-right: 70px;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/img/more_arr2.png");
    color: $font_color1;
    &.white{
        color: $white;
    }
}

.back_btn{
    letter-spacing: 1px;
    font-family: $special_font;
    font-weight: 700;
    display: inline-block;
    @include p;
    text-transform: uppercase;
    padding-left: 70px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url("/img/back_arr.png");
    color: $font_color1;
    &.white{
        color: $white;
    }
}

.choice_list{
    > div{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.show_more_btn{
    @include p;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: right center;
    font-family: $special_font;
    text-transform: uppercase;
    color: $black;
    background-image: url("/img/show_more_arr.png");
    letter-spacing: 1px;
    padding-right: 64px;
    font-weight: 600;
    &:hover{
        color:$font_color1;
    }
}

.default_font{
    @include p;
    line-height: 36px;
}

.slider{
    height: 100vh !important;
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    @media only screen and (max-width: 991px) {
        height: 100vh !important;
    }
    > ul{
        @include fill;
        background: $black;
        > li{
            &:first-of-type {
                display:block;
            }

            display:none;
            background-size:cover;

            @include fill;
            .slider_image{

                background-size:cover;
                //display: none;
                opacity: 0.8;
                @include fill;
                @include bg_pos;
                &.slider_image_pc{
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
                &.slider_image_tablet{
                    display: none;
                }
                &.slider_image_mobile{
                    display: none;
                }
                //@media (min-width: 850px) and (max-width: 1920px) {
                //    &.slider_image_pc{
                //        display: unset;
                //    }
                //}
                //
                //@media (min-width: 551px) and (max-width: 850px) {
                //    &.slider_image_tablet{
                //        display: unset;
                //    }
                //}
                //
                //@media (min-width: 1px) and (max-width: 550px) {
                //    &.slider_image_mobile{
                //        display: unset;
                //    }
                //}

                @media only screen and (max-width: 991px) {
                   &.slider_image_tablet{
                            display: block;
                    }
                }
                @media only screen and (max-width: 768px) {
                    &.slider_image_tablet{
                        display: none;
                    }
                }
                @media only screen and (max-width: 768px) {
                    &.slider_image_mobile{
                        display: block;
                    }
                }
                .cover{
                    @include fill;

                    background: rgba(0,0,0,1);
                    background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, rgba(0,0,0,1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(24%, rgba(0,0,0,0)), color-stop(76%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1)));
                    background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, rgba(0,0,0,1) 100%);
                    background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, rgba(0,0,0,1) 100%);
                    background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, rgba(0,0,0,1) 100%);
                    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, rgba(0,0,0,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
                }
            }
            .slider_content{
               // padding-top: 18vw;
                //padding-top: 16vw;
               // position: relative;
                @include fill;
                @include vetical;

                @media only screen and (max-width: 1920px) {
                    z-index: 2;
                    //bottom: 6vw;
                    top: 23vw;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    display: block;
                    height: auto;
                    top: auto;
                    bottom: 100px;
                }

                @media only screen and (max-width: 1500px) {
                    bottom: 4vw;
                }
                @media only screen and (max-width: 991px) {
                    padding-top: 0;
                    @include fill;
                    @include vetical;
                    bottom: auto;
                    > div{
                        text-align: center;
                        width: 100%;
                    }
                }
                header.slider_header{
                    //padding: 30px 30px 30px 0;
                    padding: 30px 30px 0px 0;
                   // background: rgba(237, 234, 230, 0.2);
                    /*
                    background: rgba(237,234,230,0);
                    background: -moz-linear-gradient(left, rgba(237,234,230,0) 0%, rgba(237,234,230,0.3) 30%, rgba(237,234,230,0.3) 70%, rgba(237,234,230,0) 100%);
                    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(237,234,230,0)), color-stop(30%, rgba(237,234,230,0.3)), color-stop(70%, rgba(237,234,230,0.3)), color-stop(100%, rgba(237,234,230,0)));
                    background: -webkit-linear-gradient(left, rgba(237,234,230,0) 0%, rgba(237,234,230,0.3) 30%, rgba(237,234,230,0.3) 70%, rgba(237,234,230,0) 100%);
                    background: -o-linear-gradient(left, rgba(237,234,230,0) 0%, rgba(237,234,230,0.3) 30%, rgba(237,234,230,0.3) 70%, rgba(237,234,230,0) 100%);
                    background: -ms-linear-gradient(left, rgba(237,234,230,0) 0%, rgba(237,234,230,0.3) 30%, rgba(237,234,230,0.3) 70%, rgba(237,234,230,0) 100%);
                    background: linear-gradient(to right, rgba(237,234,230,0) 0%, rgba(237,234,230,0.3) 30%, rgba(237,234,230,0.3) 70%, rgba(237,234,230,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edeae6', endColorstr='#edeae6', GradientType=1 );
                    */
                    @media only screen and (max-width: 1500px) {
                        padding: 15px 15px 15px 0;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: left;
                    }
                    ul{
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                        }
                        li{
                            display: inline-block;
                            vertical-align: middle;
                           // color: #a9a8a7;
                            color: $white;
                            @media only screen and (max-width: 991px) {

                            }
                            &.date{
                                font-family: $special_font;
                                font-size: 1.5vw;
                                line-height: 1.5vw;

                                padding-right: 21px;
                                margin-right: 21px;
                                border-right: 1px solid rgba(255,255,255,0.2);
                                @media only screen and (max-width: 991px) {
                                    @include h6;
                                    line-height: 18px;
                                    color: $white;
                                    text-align: left;
                                }
                            }
                            &.date2{
                               // @include p
                                font-size: 0.85vw;
                                @media only screen and (max-width: 991px) {
                                    @include p;
                                    color: $white;
                                }
                            }
                        }
                    }
                    .sl_desc{
                        color: $white;
                        font-family: $special_font;
                        font-size: 36px;
                        line-height: 46px;
                        .desc_bottom{
                            white-space: nowrap;
                            @media only screen and (max-width: 991px) {
                                white-space: normal;
                            }
                        }
                        @media only screen and (max-width: 1920px) {
                            @include h6;

                            line-height: 35px;
                        }
                        @media only screen and (max-width: 991px) {
                            @include p;
                            text-align: left;
                            h1{
                                margin-bottom: 20px;
                            }
                            line-height: 20px;
                        }
                        p{
                            color: $white;
                            display: inline-block;
                            vertical-align: middle;
                            //background-repeat: no-repeat;
                            //background-position: right top;
                            //background-image: url("/img/more_arr1.png");
                            //padding-right: 123px;
                           // padding-right: 110px;
                            padding-right: 56px;
                            //min-height: 60px;
                            //padding-top: 10px;
                            margin-bottom: 1.7vw;
                            @media only screen and (max-width: 991px) {
                                //padding-right: 0;
                                //padding-bottom: 60px;
                                //background-position: right bottom;
                                text-align: left;
                                //background-repeat: no-repeat;
                                //background-position: right top;
                                //background-image: url("/img/more_arr1.png");
                                //padding-right: 123px;
                                 padding-right: 110px;
                                line-height: 20px;
                                //min-height: 60px;
                                position: relative;
                                &.show_button {
                                    &:after{
                                        content: url("/img/more_arr1.png");
                                        position: absolute;
                                        //top:0;
                                        //right: 0;
                                        top: calc(100% + 20px);
                                        left: 0;
                                    }
                                }
                            }
                        }
                        span{
                            display: inline-block;
                            vertical-align: middle;
                            background-repeat: no-repeat;
                            background-position: center top;
                            background-image: url("/img/more_arr1.png");
                            width: 56px;
                            height: 56px;
                            background-size: 100% 100%;
                            @media only screen and (max-width: 991px) {
                                display: none;
                            }
                        }
                    }
                    h1{
                        margin: 3vw 0;
                        color: $white;
                        font-size: 4vw;
                        @media only screen and (max-width: 1920px) {
                            //margin: 2.7vw 0;
                            margin: 1.7vw 0;
                            color: $white;
                            font-size: 3.5vw;
                        }
                        @media only screen and (max-width: 1700px) {
                            margin: 1.7vw 0;
                        }
                        @media only screen and (max-width: 1500px) {
                            margin: 1.5vw 0;
                        }
                        @media only screen and (max-width: 1300px) {
                            margin: 1.3vw 0;
                        }
                        @media only screen and (max-width: 991px) {
                            @include h3;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .slider_nav{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 35px;
        @media only screen and (max-width: 1500px) {
            bottom: 15px;
        }
        @media only screen and (max-width: 991px) {
            z-index: 5;
        }
         div{
            &.slide_choice{
                display: inline-block;
                vertical-align: middle;
                button{
                    cursor: pointer;
                    width: 12px;
                    height: 0;
                    padding-bottom: 12px;
                    border-radius: 50%;
                    background:#7c8285;
                    @include anim;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 16px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.on{
                        background: $white;
                    }
                }
                //> ul{
                //    > li{
                //        cursor: pointer;
                //        width: 12px;
                //        height: 0;
                //        padding-bottom: 12px;
                //        border-radius: 50%;
                //        background:#7c8285;
                //        @include anim;
                //        display: inline-block;
                //        vertical-align: top;
                //        margin-right: 16px;
                //        &:last-child{
                //            margin-right: 0;
                //        }
                //        &.on{
                //            background: $white;
                //        }
                //    }
                //}
            }
             &.stop_slider{
                 display: inline-block;
                 position: absolute;
                 right: 0;
                 bottom: -10px;
                 vertical-align: middle;
                 margin-left: 40px;
                 margin-bottom: 5px;
                 .scripts_btn{
                     border-radius: 50%;
                     width: 40px;
                     height: 40px;
                     background-color: #7c8285;
                     @include bg_pos;
                     &.scripts_off_btn{
                         background-image: url("./../img/pause.svg");
                     }
                     &.scripts_on_btn{
                         display: none;
                         background-image: url("./../img/play.svg");
                     }
                 }
             }
            &.arrs{
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                .arr{
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    background: #353535;
                    @media only screen and (max-width: 1500px) {
                        img{
                            max-width: 15px;
                        }
                    }
                        .arr_holder{
                            @include vetical;
                            height: 100%;
                            position: relative;
                            z-index: 2;
                           > div{
                               width: 100%;
                               text-align: center;
                           }
                        }
                        .image{
                            &:hover{
                             opacity: 1;
                            }
                        }

                    .image{
                        @include fill;
                        @include bg_pos;
                        @include anim;
                        background-size: cover;
                        opacity: 0.5;
                    }
                    &.arr_right{
                        position: relative;
                        .arr_left{
                            @include fill;
                            top: auto;
                            left: auto;
                            bottom: 100%;
                            right: 100%;
                        }
                    }
                }
            }
        }
    }
}
//
//.small_search_box{
//    @include anim;
//    width: 0px;
//    padding-right: 0px;
//    white-space: nowrap;
//    overflow: hidden;
//    &.small_open{
//        padding-right: 9px;
//        width: 120px;
//    }
//    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//        color: #908f8e;
//    }
//    ::-moz-placeholder { /* Firefox 19+ */
//        color: #908f8e;
//    }
//    :-ms-input-placeholder { /* IE 10+ */
//        color: #908f8e;
//    }
//    :-moz-placeholder { /* Firefox 18- */
//        color: #908f8e;
//    }
//    input{
//        //outline: none;
//        border: none;
//        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
//        height: 30px;
//        background: transparent;
//        vertical-align: top;
//        display: inline-block;
//
//        &.i1{
//            font-size: 11px;
//            //width: calc(100% - 60px);
//            width: 100%;
//
//            color: $white;
//        }
//        &.i2{
//            text-transform: uppercase;
//            width: 50px;
//            padding-left: 30px;
//            color: $white;
//            //font-family: $special_font;
//            font-family: $main_font;
//            letter-spacing: 1px;
//            //padding: 0;
//        }
//    }
//}

.page_header{
    .socials{
        > ul{
            > li{
                margin-right: 10px;
                display: inline-block;
                vertical-align: top;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    &.lp_header{
        //padding-top: 97px;
        padding-top: 5%;
        .rwd_btn{
            display: none;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 30px !important;
            .logo_box{
                img{
                    width: 100%;
                    margin-top: -10px;
                    display: block !important;
                }
            }
        }
        .logo_box{
            padding-top: 0;
        }
        .back_to_unversity{
            padding-top: 59px;
            text-align: right;
            width: 100%;
            border-bottom: 1px solid $white;
            padding-bottom: 30px;
            @include anim;
            > div{
                display: inline-block;
                vertical-align: middle;
                margin-right: 50px;
                @media only screen and (max-width: 767px) {
                    margin-right: 4px;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            @media only screen and (max-width: 991px) {
                padding-top: 15px;
                padding-bottom: 15px;
                text-aling:center;
            }
            @media only screen and (max-width: 767px) {
                margin-left: -15px;
                width: calc(100% + 15px);
                margin-top: -6px;
                padding-top: 0 !important;
            }
            a.back_to_unversity_btn{
                background-image: url("./../img/back_arr_lp.png");
                background-repeat: no-repeat;
                background-position: right center;
                @include p;
                color: rgba(255,255,255,0.5);
                padding-right: 130px;
                text-transform:uppercase;
                letter-spacing: 2px;
                @include anim;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                    display: block;
                    background-image: none;
                    padding-right: 0;
                    font-size:12px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 9px;
                }
                &:hover{
                    color: $white;
                }
            }
        }
    }
    &.library_header{
        padding-top: 20px;
        .header_border{
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
        @media only screen and (max-width: 991px) {
            .header_bottom {
                .menu_box{
                    margin: 0 !important;
                }
            }
            .header_bottom .header_top > ul > li.header_top_right{
                margin: 0 !important;
            }
        }
        .logo_box{
            padding-top: 58px;
            @media only screen and (max-width: 991px) {
                padding-top: 32px;
                img{
                    margin-top: 0;
                }
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 87px;
                    @media only screen and (max-width: 1700px) {
                        margin-right: 66px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .header_top {
            margin-top: 0;
            &:before{
                display: none;
            }
            > ul {
                > li{
                    &.header_top_left {
                        .fast_links {
                            padding-right: 47px;
                        }
                    }
                    &.header_top_right {
                        > div{
                            &.right_box2{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .header_bottom{
            padding-top: 49px;
            border-bottom: none;
            div.menu_box {
                nav.menu {
                    > ul {
                        > li{
                            &:last-child{
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @include anim;
    width: 100%;
    position: fixed;
    padding-bottom: 30px;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0,0,0,1);
    background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.66) 50%, rgba(0,0,0,0.6) 59%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.45) 76%, rgba(0,0,0,0.38) 80%, rgba(0,0,0,0.23) 89%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(50%, rgba(0,0,0,0.66)), color-stop(59%, rgba(0,0,0,0.6)), color-stop(70%, rgba(0,0,0,0.5)), color-stop(76%, rgba(0,0,0,0.45)), color-stop(80%, rgba(0,0,0,0.38)), color-stop(89%, rgba(0,0,0,0.23)), color-stop(100%, rgba(0,0,0,0)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.66) 50%, rgba(0,0,0,0.6) 59%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.45) 76%, rgba(0,0,0,0.38) 80%, rgba(0,0,0,0.23) 89%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.66) 50%, rgba(0,0,0,0.6) 59%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.45) 76%, rgba(0,0,0,0.38) 80%, rgba(0,0,0,0.23) 89%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.66) 50%, rgba(0,0,0,0.6) 59%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.45) 76%, rgba(0,0,0,0.38) 80%, rgba(0,0,0,0.23) 89%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.66) 50%, rgba(0,0,0,0.6) 59%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.45) 76%, rgba(0,0,0,0.38) 80%, rgba(0,0,0,0.23) 89%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    @media only screen and (max-width: 991px) {
        margin-top: 0 !important;
    }
    .logo_box{
        padding-top: 97px;
        @include anim;
        @media only screen and (max-width: 1500px) {
           // padding-top: 40px;
            padding-top: 44px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 33px;
            a{
                img{
                    margin-top: -60px;
                    display:none;
                }
            }

           > a {
                -moz-transform: scale(0.5) translateX(-50%) translateY(-50%);
                -webkit-transform: scale(0.5) translateX(-50%) translateY(-50%);
                -o-transform: scale(0.5) translateX(-50%) translateY(-50%);
                -ms-transform: scale(0.5) translateX(-50%) translateY(-50%);
                transform: scale(0.5) translateX(-50%) translateY(-50%);
            }
        }

        //a {
        //    padding-bottom:0!important;
        //}

        &.logo_hidden{
            visibility: hidden;
            opacity: 0;
            @media only screen and (max-width: 991px) {
                opacity: 1;
                visibility: visible;
            }


        }

        svg {
            width:180px;
            height:170.518px;
        }


    }
    .rwd_btn{
        width: 40px;
        height: 22px;
        //position: absolute;
        //top: 33px;
        position: relative;
        right: 15px;
        //z-index: 9;
        overflow: hidden;
        &.menu_open{
            > div{
                &:nth-child(1){
                    width: 100%;
                    transform: rotate(-45deg);
                    top: 7px;
                }
                &:nth-child(2){
                    right: 100%;
                }
                &:nth-child(3){
                    width: 100%;
                    transform: rotate(45deg);
                    top: 12px;
                }
            }
        }
        > div{
            position: absolute;
            right: 0;
            background: $white;
            @include anim;
            &:nth-child(1){
                top: 0;
                height: 3px;
                width: 100%;
            }
            &:nth-child(2){
                top: 9px;
                height: 3px;
                width: 100%;
            }
            &:nth-child(3){
                top: 19px;
                height: 3px;
                width: 70%;
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .logo_box{
            img{
                max-width: 100px;
            }
        }
    }
    &.page_header2{
        .header_background{
            padding-bottom: 43px;
            position: relative;
            @media only screen and (max-width: 1500px) {
                padding-bottom: 25px;
            }
            @media only screen and (max-width: 1200px) {
                padding-bottom: 10px;
            }
            @media only screen and (max-width: 991px) {
                padding-bottom: 0;
            }
            &:after{
                content: '';
                @include fill;
                width: 105%;
                background: $white;
                z-index: -1;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
        }
        .header_top {
            > div {
                > div{
                    &.header_top_left {
                        .fast_links {
                            border-right: 1px solid rgba(158,158,158,0.4);
                            ul {
                                li {
                                    a{
                                        color: $font_color1;
                                    }
                                }
                            }
                        }
                    }
                    &.header_top_right {
                        > div {
                            .fonts {
                                ul {
                                    li {
                                        p {
                                            font-weight: 600;
                                            color: $font_color1;
                                        }
                                    }
                                }
                            }
                        }
                        div.right_box1 {
                            > div.langs {
                                .dropdown {
                                    .btn{
                                        color: $font_color1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .header_bottom {
            div.menu_box {
                nav.menu {
                    > ul {
                       > li {
                          >  div {
                              >  a {
                                    color: $font_color1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header_top{
        position: relative;
        &:before{
            border-top: 1px solid rgba(255,255,255,0.2);
            content: '';
            width: calc(100% - 200px);
            right: 0;
            top: 0;
            position: absolute;
        }
        margin-top: 20px;
        padding-top: 12px;
        text-align: right;
        white-space: nowrap;
        margin-left: -200px;
        width: calc(100% + 200px);
        @media only screen and (max-width: 991px) {
            display: none;
        }
        > div{
            > div{
                display: inline-block;
                vertical-align: middle;
                &.header_top_left{
                    .fast_links{
                        padding-right: 101px;
                        @media only screen and (max-width: 1700px) {
                            padding-right: 41px;
                        }
                        @media only screen and (max-width: 1500px) {
                            padding-right: 0px;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: middle;
                                &:last-child{
                                    margin-right: 0;
                                }
                                a{
                                    font-size: 11px;
                                    line-height: 25px;
                                    color: #908f8e;
                                    text-transform: uppercase;
                                    font-weight: 700;
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 9px !important;
                                    }
                                }
                            }
                        }
                    }
                }
                &.header_top_right{
                    @media only screen and (max-width: 1500px) {
                        padding-left: 25px;
                    }
                    @media only screen and (max-width: 1200px) {
                        padding-left: 15px;
                    }
                    > div{
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 45px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 20px;
                        }
                        @media only screen and (max-width: 1200px) {
                            margin-right: 15px;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        &.right_box1{
                            > div{
                                margin-right: 20px;
                                @media only screen and (max-width: 1200px) {
                                    margin-right: 15px;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                //&.langs{
                                //    margin-right: 0;
                                //    @media only screen and (max-width: 1200px) {
                                //        margin-right: 0;
                                //    }
                                //    .dropdown{
                                //        &.open{
                                //            .dropdown-menu{
                                //                top: 50%;
                                //                opacity: 1;
                                //                visibility: visible;
                                //            }
                                //        }
                                //        .btn{
                                //            padding: 0;
                                //            width: 35px;
                                //            border: none;
                                //            background: transparent;
                                //            color: $white;
                                //            font-size: 13px;
                                //            font-weight: 700;
                                //            position: relative;
                                //            z-index: 1111;
                                //            .caret{
                                //                margin-left: 6px;
                                //                border:none;
                                //                width: 6px;
                                //                height: 4px;
                                //                @include bg_pos;
                                //                background-image: url("/img/langs_arr.png");
                                //            }
                                //        }
                                //        .dropdown-menu{
                                //            top: 0%;
                                //            padding: 20px 0 5px 0;
                                //            min-width: 0;
                                //            width: 35px;
                                //            text-align: left;
                                //            text-transform: uppercase;
                                //            border: 2px solid $white;
                                //            display: block;
                                //            visibility: hidden;
                                //            opacity: 0;
                                //            @include anim;
                                //            border-radius: 0;
                                //            > li{
                                //                width: 100%;
                                //                > a{
                                //                    padding: 0;
                                //                    font-size:13px;
                                //                    @include anim;
                                //                    position: relative;
                                //                    color: $black;
                                //                    font-weight:700;
                                //                    &:hover{
                                //                        background: $font_color1;
                                //                        color: $white;
                                //                    }
                                //                }
                                //            }
                                //        }
                                //    }
                                //}
                            }
                        }
                        &.right_box2{
                            margin-right: 34px;
                            @media only screen and (max-width: 1200px) {
                                margin-right: 20px;
                            }
                        }
                        &.right_box3{
                            .logotypes{
                                ul{
                                    li{
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-right: 34px;
                                        @media only screen and (max-width: 1200px) {
                                            margin-right: 20px;
                                        }
                                        &:last-child{
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                        > div{
                            display: inline-block;
                            vertical-align: middle;
                        }
                        .fonts{

                            .dark li a {
                                color:#000!important;
                            }

                            ul{
                                margin: 0 2.5px;
                                li{
                                    padding: 0 2.5px;
                                    line-height: 24px;
                                    display: inline-block;
                                    //vertical-align: bottom;
                                    text-transform: uppercase;
                                    cursor: pointer;
                                    &.smaller{
                                        font-size: 11px;
                                        a{
                                            //position: relative;
                                            //bottom: 1px;
                                        }
                                    }
                                    &.normal{
                                        font-size: 14px;
                                    }
                                    &.larger{
                                        font-size: 17px;
                                        a{
                                            //position: relative;
                                            //top: 1px;
                                        }
                                    }
                                    a{
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header_bottom{
        //padding-top: 96px;
        padding-top: 109px;
        text-align: right;
        border-bottom: 2px solid rgba(255,255,255,0.5);
        @media only screen and (max-width: 1500px) {
           // padding-top: 53px;
            padding-top: 59px;
        }
        @media only screen and (max-width: 1300px) {
           // padding-top: 3.45vw;
           // padding-top: 64px;
            padding-top: 4.1vw;
        }
        @media only screen and (max-width: 1200px) {
           // padding-top: 3.3vw;
            //padding-top: 64px;
            padding-top: 3.9vw;
        }
        @media only screen and (max-width: 991px) {
            text-align: left;
            padding-top: 0;
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #2a2a2a;
            overflow-y: scroll;
            z-index: 9999;
            a{
                color: $white !important;
                padding-right: 60px;
            }
        }
        div.menu_box{
            nav.menu{
                @media only screen and (max-width: 991px) {
                    padding-top: 30px;
                }
                .has_more{
                    @media only screen and (max-width: 991px) {
                        background-image: none;
                        position: relative;
                        .rwd_more_btn{
                            height: 59px;
                            width: 59px;
                            background-color:#1a243c;

                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 10;
                            &.btn_open{
                                > div{
                                    transform: rotate(90deg);
                                }
                            }
                            > div{
                                @include fill;
                                @include bg-pos;
                                background-image: url("/img/rwd_arr1.png")!important;
                            }
                        }
                    }
                    @media only screen and (min-width: 992px) {
                        &:hover{
                            > ul{
                                display: block;
                            }
                        }
                    }
                }
                > ul{
                   > li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 10px;
                       position: relative;
                       //padding: 9px 28px;
                       padding: 4px 28px;
                       @media only screen and (max-width: 1700px) {
                           margin-right: 0;
                       }
                       @media only screen and (max-width: 1500px) {
                           padding: 4px 18px;

                       }
                       @media only screen and (max-width: 1200px) {
                           padding: 4px 13px;
                       }
                       @media only screen and (max-width: 991px) {
                           padding: 0;
                           width: 100%;
                           border-bottom: 1px solid #3a3a3a;
                           > div{
                               > a{
                                   padding: 20px 25px;
                               }
                           }
                       }
                       div{
                           position: relative;
                           z-index: 2;
                       }
                       &:hover{
                           > ul{
                               @media only screen and (min-width: 991px) {
                                    display: block;
                               }
                               //opacity: 1;
                               //visibility: visible;
                               //display: block !important;
                           }
                       }
                       @media only screen and (min-width: 991px) {
                           &.child_on_right{
                               > ul{
                                   left: auto;
                                   right: 0;
                                   border-left: none;
                                   border-right: 4px solid #001f46;
                                   > li{
                                       text-align: right;
                                       &.has_more{
                                           background-position: left 10px center;
                                           background-image:url("./../img/menu_arr22.png");
                                       }
                                       > ul{
                                           left: auto;
                                           right: 100%;
                                       }
                                   }
                               }
                           }
                       }
                       > ul{
                           display: none;
                           position: absolute;
                           top: 0%;
                           left: 0;
                           text-align: left;
                           width: 360px;
                           //border-left: 4px solid #892f33;
                           border-left: 4px solid #001f46;
                           //padding-top: 40px;
                           padding-top: 32px;
                           //@include anim;
                           //visibility: hidden;
                           //opacity: 0;
                           @media only screen and (max-width: 1700px) {
                               width: 320px;
                           }
                           @media only screen and (max-width: 1500px) {
                                width: 260px;
                               padding-top: 29px;
                           }
                           @media only screen and (max-width: 1200px) {
                               width: 200px;
                               padding-top: 27px;
                           }
                           @media only screen and (max-width: 991px) {
                               background: transparent;
                               position: relative;
                               padding-top: 0;
                               visibility: visible;
                               opacity: 1;
                               width: 100%;
                               display: none;
                               transition: none;
                           }
                           > li{
                               //background-color: #2a2a2a;
                               background-color: #dfdad3;
                               width: 100%;
                               border-bottom: 1px solid #3a3a3a;
                               @include anim;
                               @media only screen and (max-width: 991px) {
                                   background: transparent;
                               }
                               &.has_more{
                                   background-repeat: no-repeat;
                                   background-position: right 10px center;
                                   background-image: url("/img/menu_arr.png");
                                   position: relative;
                                   @media only screen and (max-width: 1500px) {
                                        background-position: right 20px center;
                                   }
                                   @media only screen and (max-width: 991px) {
                                       background-image: none !important;
                                   }
                               }
                               @media only screen and (min-width: 991px) {
                                   &:hover{
                                       //background-color: #201c1d;
                                       background-color: #001f46;
                                       > div{
                                           a{
                                               color: $white;
                                           }
                                       }
                                       > ul{
                                           display: block !important;
                                           //opacity: 1;
                                           //visibility: visible;
                                       }
                                   }
                               }

                               > ul{
                                   position: absolute;
                                   left: 100%;
                                   top:0;
                                   display: none;
                                   //visibility: hidden;
                                   //opacity: 0;
                                   height: 100%;
                                   width: 90%;
                                   @media only screen and (max-width: 991px) {
                                       left: 0;
                                       top: 0;
                                       position: relative;
                                       visibility: visible;
                                       opacity: 1;
                                       display: none;
                                       transition: none;
                                       padding-left: 15px;
                                   }
                                   > li{
                                       //background: #201c1d;
                                       background:#dfdad3;
                                       @media only screen and (max-width: 991px) {
                                        background: transparent;
                                       }
                                       @media only screen and (min-width: 991px) {
                                           &:hover{
                                               div{
                                                   background-color: #001f46;
                                                   a{
                                                       color: $white;
                                                   }
                                               }
                                           }
                                       }
                                       > div{
                                           a{
                                               //padding: 20px 25px;
                                               display: block;
                                               //color: #919191;
                                               color: $font_color1;
                                               padding: 8px 28px;
                                               @include anim;
                                               @media only screen and (max-width: 1500px) {
                                                    padding: 8px 20px;
                                               }
                                               @media only screen and (max-width: 1200px) {
                                                   padding: 8px 15px;
                                               }
                                               @media only screen and (max-width: 991px) {
                                                   padding: 20px 25px;
                                               }
                                           }
                                       }
                                   }
                               }
                               > div{
                                   a{
                                       display: block;
                                       //padding: 20px 25px;
                                       //color: #cdcdcd;
                                       color: $font_color1;
                                       padding: 8px 28px;
                                       @media only screen and (max-width: 1500px) {
                                            padding: 8px 20px;
                                       }
                                       @media only screen and (max-width: 1200px) {
                                           padding: 8px 15px
                                       }
                                       @media only screen and (max-width: 991px) {
                                           padding: 20px 25px;
                                       }
                                   }
                               }
                           }
                       }
                        &:last-child{
                            margin-right: 0;
                        }
                        div{
                            a{
                                @include p;
                                color: $white;
                                text-transform: uppercase;
                                font-weight: 700;
                                display: block;
                                @media only screen and (max-width: 1200px) {
                                    font-size: 12px;
                                }
                                @media only screen and (max-width: 991px) {
                                    @include p;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .fast_links{
        > ul {
            display: inline-block;
            vertical-align: middle;
            &:first-child{
                > li{
                    margin-right: 22px;
                }
            }
            &.fast_btns{
                padding-left: 33px;
                > li{
                    margin-right: 9px;
                }
                @media only screen and (max-width: 1200px) {
                    padding-left: 15px;
                }
            }
            li {
                .search_btn1{
                    cursor: pointer;
                }
            }
        }
    }
    @media only screen and (max-width: 1220px) {
        .fast_links, .socials{
            img{
                max-width: 20px;
            }
        }
    }
}

.section_border{
    border-top: 2px solid $font_color1;
    padding-top: 17px;

}

.page_content{
    background: $theme_color1;
}

.partners{
    background: $theme_color1;
    //padding-bottom: 36px;
    padding-bottom: 15px;

    @media only screen and (max-width: 991px) {
        padding-bottom: 13px;
    }
    .part_carousel{
        overflow: hidden;
        > ul{
            position: relative;
            white-space: nowrap;
            @media only screen and (max-width: 991px) {
                padding-top: 20px;

            }
            > li{
                padding-left: 15px;
                padding-right: 15px;
                display: inline-block;
                vertical-align: top;
                width: 25%;
                img{
                    max-height: 81px;
                    width: auto;
                }
                @media only screen and (max-width: 1500px) {
                    width: 33.33%;
                }
                @media only screen and (max-width: 1300px) {

                    img{
                        max-height: 55px;
                        width: auto;
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 40px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
}

.event_calendar{
    background: $theme_color1;
    position: relative;
    //padding-bottom: 23px;
    margin-top: 21px;
    .flexed{
        > div {
            .left_more {
                > div{
                    height: 75px;
                    @media only screen and (max-width: 1500px) {
                        height: 52px;
                    }
                    @media only screen and (max-width: 991px) {
                        height: auto;
                        padding-top: 0;
                    }
                }
            }
        }
    }
    .events_list {
        overflow: hidden;
        > ul {
            position: relative;
            > li{
                margin-bottom: 0;
                &:hover{
                    box-shadow: none;
                }
            }
        }
    }
    .section_header{
        margin-bottom: 100px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
    }
    .image{
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        width: 63%;
        @include bg_pos;
        background-size: cover;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        > div {
            @include fill;
            opacity:0;
            background-size:cover;
            background-position:center;
            -webkit-transition: ease 0.3s;
            -moz-transition: ease 0.3s;
            -ms-transition: ease 0.3s;
            -o-transition: ease 0.3s;
            transition: ease 0.3s;

            &.active {
                opacity:1;
            }
        }
    }
    .container{
        position: relative;
        z-index: 2;
    }
    .calendar_title{
        padding-left: 50px;
        padding-top: 33px;
        padding-bottom: 17px;
        font-size: 18px;
        text-transform: uppercase;
        color:$font_color3;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            padding-top: 17px;
        }
        p{
            font-weight: 600;
        }
    }
    .event_box{
        @media only screen and (max-width: 991px) {
            display: flex;
            flex-flow: column;
        }
        .calendar{
            //margin-top: -51px;
            //margin-top: -84px;
            //@media only screen and (max-width: 1500px) {
            //    margin-top: -119px;
            //}
            //@media only screen and (max-width: 991px) {
            //    margin-top: 0;
            //}
        }
        //margin: 0 -17px;
        > div{
            //display: inline-block;
            //vertical-align: top;
            //padding: 0 17px;
            @media only screen and (max-width: 991px) {
                display: block;
                align-items: stretch;
            }
            &.events{
                //width: calc(100% - 485px);
                width: calc(100% - 460px);
                @media only screen and (max-width: 1700px) {
                    width: calc(100% - 413px);
                }
                @media only screen and (max-width: 1500px) {
                    width: calc(100% - 385px);
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    order: 2;
                }
            }
            &.calendar{
                //height: 311px;
                margin-top: -61px;
                //width: 485px;
                width: 460px;
                @media only screen and (max-width: 1700px) {
                    width: 413px;
                }
                @media only screen and (max-width: 1500px) {
                    width: 385px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    order: 1;
                    margin-bottom: 30px;
                    margin-top: 0;
                    height: auto;
                }
            }
        }
    }
    .events_holder{
        display: flex;
        //height: 250px;
        height: 100%;
        position: relative;
        @media only screen and (max-width: 1500px) {
           // height: 180px;
        }
        @media only screen and (max-width: 991px) {
            display: block;
            height: auto;
        }
        > div{
            &.arrs{
                //width: 16px;
                width: 50px;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                > div{
                    width: 100%;
                    //height: 43%;
                    height: 50%;
                    @include anim;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    @media only screen and (max-width: 1500px) {
                        height: 44%;
                        background-size: auto 38px;
                    }
                    &.arr_top{
                        background-image: url("/img/e_arr_top.png");
                        background-position: left top;
                    }
                    &.arr_bottom{
                        background-position: left bottom 30px;
                        background-image: url("/img/e_arr_bottom.png");
                        @media only screen and (max-width: 1500px) {
                           background-position:  left bottom;
                        }
                    }
                }
            }
            &.events_list {
                width: calc(100% - 50px);
                overflow:hidden;
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    width: 100%;
                    overflow: visible;
                }
                > div {
                    height:100%;
                }
                ul{
                    position:relative;
                    height: 100%;
                    li{
                        height: calc(100% / 4);
                        border-top: 1px solid #cbcbcb;
                        &:hover{
                            p{
                                font-weight: 700;
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            height: auto;
                            margin-bottom: 15px;
                        }
                        @media only screen and (min-width: 991px) {
                            &:hover{

                            }
                        }
                        a{
                            div.event_info{
                                height: 100%;
                                //margin-left: -17.5px;
                                //width: calc(100% + 35px);
                                width: 100%;
                                //display: table;
                                @include vetical;
                                @media only screen and (max-width: 991px) {
                                    display: block;
                                    padding:20px 10px;
                                    background: $white;
                                    box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.2);
                                    border: none;
                                    width: 100%;
                                }
                                > div{
                                    //display: table-cell;
                                    //vertical-align: middle;
                                    height: 100%;
                                    min-width: 50%;
                                    max-width: 100%;
                                    flex: 1;
                                    @include vetical;
                                    &.date_holder_box{
                                        gap: 30px;
                                        @media only screen and (max-width: 1300px) {
                                            gap: 15px;
                                        }
                                    }
                                    @media only screen and (max-width: 991px) {
                                        display: block;
                                    }
                                    .date{
                                        white-space: nowrap;
                                        font-family: $special_font;
                                        color: $font_color1;
                                        color: #696969;
                                        @include h6;
                                        //width: 190px;
                                        //width: 140px;
                                        @media only screen and (max-width: 1500px) {
                                            //width: 120px;
                                            @include p;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            display: inline-block;
                                            vertical-align: middle;
                                            width: auto;
                                            @include p;
                                            padding-right: 10px;
                                        }
                                    }
                                    &.active{
                                        p{
                                            font-weight: 700;
                                        }
                                    }
                                    .day{
                                        white-space: nowrap;
                                        //width: 190px;
                                        //width: 240px;
                                        font-size: 18px;
                                        color: #696969;
                                        font-size: 18px;
                                        @media only screen and (max-width: 1700px) {
                                            width: 192px;
                                        }
                                        @media only screen and (max-width: 1500px) {
                                            width: 158px;
                                            font-size: 14px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            display: inline-block;
                                            vertical-align: middle;
                                            @include p;
                                            width: auto;
                                        }
                                    }
                                    &.desc{
                                        padding-left: 20px;
                                        //padding-left: 21px;
                                        //padding-left: 24px;
                                        color: $font_color1;
                                        font-family: $special_font;
                                        @include h6;
                                        line-height: 30px;
                                        //width: calc(100% - (190px * 2));
                                        background-repeat: no-repeat;
                                        background-position: right center;
                                        @include anim;
                                        p{
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                        @media only screen and (max-width: 1500px) {
                                            @include p;
                                        }
                                        //@media only screen and (max-width: 1300px) {
                                        //    padding-left: 0;
                                        //}
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                            padding-right: 0;
                                            padding-top: 15px;
                                            @include h5;
                                            padding-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .more{
        padding:55px 250px 55px 0;
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
            text-align: center;
            a{
                color: $font_color1;
            }
        }
    }
}


.section_header{
    font-family: $special_font;
    margin-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
    }
    h2{
        @include h4;
        line-height: 60px;

        color: $font_color1;
        text-transform: uppercase;
        font-weight: 600;
        @media only screen and (max-width: 1500px) {
            line-height: 54px;
        }
    }
}

.filter_box{
    display: inline-block;
    border-bottom: 1px solid #f1ebe4;
    @media only screen and (max-width: 991px) {
        display: block;
    }
    > ul{
        > li{
            display: inline-block;
            vertical-align: bottom;
            //font-size: 13px;
            font-size: 15px;
            color: $font_color1;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: left;
            line-height: 43px;
            letter-spacing: 2px;
            @media only screen and (max-width: 991px) {
                &:first-child{
                    width: 100%;
                    text-align: center;
                }
            }
            &:last-child{
                width: 320px;
                padding-left: 21px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
    }
    .chosen-container-single {
        .chosen-single{
            border: none;
            height: 43px;
            //background: $white;
            background: #edeae6;
            box-shadow: none;
            padding: 0 25px 0 0px;
            font-size: 13px;
            border-radius: 0;
            line-height: 43px;
            color: $font_color1;
            font-weight: 700;
            letter-spacing: 2px;
            background-repeat: no-repeat;
            background-position: left 30px center;

            div{
                width: 50px;
                b{
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-image: url('/img/select_arr.png');
                }
            }
        }
        .chosen-search{
            input{
                color: $font_color1;
            }
        }
        .chosen-drop{
            background: #edeae6;
            border-top: none;
            //width: calc(100% + 6px);
            width: 100%;
            //left: -3px;
            left: 0;
            border: 1px solid #f1ebe4;
            border-top: 0;
            top: calc(100% + 4px);
            .highlighted{
                background: $font_color1;
                color: $white;
            }
        }
        .chosen-results {
            li{
                color: $font_color1;
                font-weight: 700;
                font-size: 12px;
            }
        }
    }
}

.mycalendar2_slider {
    .slide{
        &.months{
            background: #2a2a2a;
            font-family: $special_font;
            .year{

            }
            .mounth{
                color: $white;
            }
        }
    }
}

.mycalendar2_datepicker {
    .calendar_content{
        background: $font_color1;
        font-family: $special_font;
        header.months_change{
            .title{
                color: $white;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    &.month{
                        font-size: 18px;
                        text-transform: uppercase;
                    }
                    &.year{
                        padding-left: 5px;
                        font-size: 18px;
                    }
                }
            }
        }

        .days_table {
            .tr {
                .td {
                    p{
                        color:$white;
                    }
                }
            }
        }
    }
}

html[lang="pl"] .days_table .tr .td p {
    text-transform: lowercase;
}

html[lang="en"] .days_table .tr .td p {
    text-transform: uppercase;
}

html[lang="en"] .event_info .day p::first-letter {
    text-transform: uppercase;
}

.news{
    position: relative;
    @media only screen and (max-width: 991px) {
        .more_btn1{
            padding-right: 0;
            background-image: none;
            text-align: center;
            width: 100%;
        }
        .section_header{
            text-align: center;
            margin-bottom: 0px;
        }
    }
    .container{
        position: relative;
        z-index: 2;
        > .row{
            &:last-child{
                @media only screen and (max-width: 991px) {
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                        &.col-lg-11{
                            order: 2;
                        }
                        &.col-lg-1{
                            order: 1;
                        }
                    }
                }
            }
        }
    }
    /*&:after{
        content: '';
        width: 100%;
        top: 0;
        left: 0;
        background: $theme_color1;
        height: 320px;
        position: absolute;
    }*/
    .carousel_content{
        overflow: hidden;
        //padding-bottom: 50px;
        //padding-bottom: 58px;
        //padding-bottom: 61px;
        padding-bottom: 36px;
        ul{
            position: relative;
            white-space: nowrap;
            z-index: 2;
            li{
                white-space: normal;
                display: inline-block;
                vertical-align: top;
                //width: 32%;
                //margin-right: 2%;
                float: none;
                &:hover{
                    article{
                        .image{
                            div{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                div.image{
                    width: 100%;
                    height: 0;
                    //padding-bottom: 70.5%;
                    //padding-bottom: 300px;
                    padding-bottom: 71.5%;
                    overflow: hidden;
                    position: relative;
                    > div{
                        @include fill;
                        @include bg_pos;
                        background-size: cover;
                        @include anim;
                    }
                }
                div.desc_holder{
                    .desc{
                        header{
                            padding: 12px 0;
                            margin-bottom: 12px;
                            border-bottom: 1px solid #002043;
                            > div{
                                display: inline-block;
                                vertical-align: middle;
                            }
                            .date{
                                width: 100%;
                                font-family: $special_font;
                                color: $font_color1;
                                //@include h6;
                                @include p;
                                text-align: right;
                                p{
                                    font-weight: 600;
                                }
                            }
                        }
                        .title{
                            //width: calc(100% - 130px);
                            @media only screen and (max-width: 991px) {
                                text-align: left;
                            }
                            h3{
                                @include p;
                                line-height: 26px;
                                color: $font_color1;
                                font-family: $special_font;
                                font-weight: 600;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .default_font{
                            line-height: 25px;
                            color: $font_color2;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 13px;
                            line-height: 18px;
                            @media only screen and (max-width: 991px) {
                                text-align: left;
                            }
                            p{
                                text-align: justify;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                height: 90px;
                                @media only screen and (max-width: 991px) {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .go_details{
                            color:$font_color2;
                            font-size: 13px;
                            line-height: 13px;
                            text-transform: uppercase;
                            border-top: 1px solid #bfbbb7;
                            padding-top: 18px;
                            margin-top: 18px;
                            p{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }

    .arrs{
        padding-top: 130px;
        @media only screen and (max-width: 991px) {
            padding-top: 0;
            text-align: right;
            padding: 30px 0;
        }
        ul{
            li{
                width: 55px;
                height: 16px;
                @include bg_pos;
                cursor: pointer;
                @media only screen and (max-width: 991px) {

                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                }
                &.arr_left{
                    background-image: url("/img/c_arr_left.png");
                    margin-bottom: 12px;
                    @media only screen and (max-width: 991px) {
                        background-position: left center;
                        margin-bottom: 0;
                    }
                }
                &.arr_right{
                    background-image: url("/img/c_arr_right.png");
                    margin-left: 30px;
                    @media only screen and (max-width: 991px) {
                        background-position: right center;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.gallery{

    position:relative;
    top:2px;

    background-color:$theme_color3;
    background-position: left top -180px;
    background-repeat: no-repeat;
    background-image: url("/img/gal_bg.png");
    padding-top: 19px;
    padding-bottom: 30px;
    @media only screen and (max-width: 1500px) {
        padding-top: 50px;
    }
    .flexed {
        > div {
            .left_more {
                .movies_box {
                    margin-bottom: 59px;
                }
                > div{
                    //border-top: 1px solid rgba(237, 234, 230, 0.3);
                    border-bottom: 1px solid rgba(237, 234, 230, 0.3);
                    border-top: 0;
                    margin-bottom: 27px;
                    height: 67px;
                    @media only screen and (max-width: 991px) {
                        height: auto;
                    }
                }
                > a {

                }
            }
        }
    }
    .arrs{
        padding-top: 0px;
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
        }
        ul{
            li{
                width: 55px;
                height: 16px;
                @include bg_pos;
                cursor: pointer;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                    display: inline-block;
                    vertical-align: middle;
                }
                &.arr_left{
                    background-image: url("/img/w_arr_left.png");
                    margin-bottom: 12px;
                    @media only screen and (max-width: 991px) {
                        background-position: left center;
                        margin-bottom: 0;
                    }
                }
                &.arr_right{
                    background-image: url("/img/w_arr_right.png");
                    margin-left: 30px;
                    @media only screen and (max-width: 991px) {
                        margin-left: 0;
                        background-position: right center;
                    }
                }
            }
        }
    }

    .section_header{
        h2{
            color: $white;
        }
    }
    .galery_content{
        overflow: hidden;
        //margin-bottom: 81px;
        margin-bottom: 35px;
        @media only screen and (max-width: 991px) {
            padding-right: 0 !important;
            margin-bottom: 0;
        }
        > ul{
            white-space: nowrap;
            position: relative;
            padding-bottom: 17px;
            border-bottom: 1px solid rgba(237, 234, 230, 0.3);
            > li{
                display: inline-block;
                vertical-align: top;
                white-space: normal;
                width: 100%;
            }
        }
    }
    .main_gallery{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        //grid-gap: 25px;
        grid-gap: 35px;
        grid-auto-rows: minmax(13.26vw, auto);
        grid-auto-flow: dense;
        @media only screen and (max-width: 1500px) {
            //grid-auto-rows: minmax(17vw, auto);
        }
        @media only screen and (max-width: 1300px) {
            //grid-auto-rows: minmax(22vw, auto);
            grid-auto-rows: minmax(17.26vw, auto);
        }
        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            display: block;
            width: 100%;
            padding: 0;
        }
    }

    .galery_item{
        padding: 1rem;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #929796;
        border-radius: 5px;
        position: relative;
        @media only screen and (min-width: 991px) {
            a{
                @include fill;
            }
        }
        @media only screen and (max-width: 991px) {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            white-space: normal;
            padding: 0;
        }
        .desc{
            font-family: $special_font;
            background: rgba(0,0,0,0.65);
            background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.65)), color-stop(100%, rgba(0,0,0,0)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
            .date{
                padding-top: 15px;
                @include h6;
                @media only screen and (max-width: 1500px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 1200px) {
                    padding-top: 5px;
                    font-size: 14px;
                }
                @media only screen and (max-width: 991px) {
                    @include p;
                }
            }
        }
        &.small{
            grid-column-end: span 1;
            grid-row-end: span 1;
            &:nth-child(2){
                .image{
                    &:hover{
                        .desc{
                            background: rgba(148, 84, 28, 0.8);
                        }
                    }
                }
            }
            &:nth-child(3){
                .image{
                    &:hover{
                        .desc{
                            background: rgba(3, 69, 66, 0.8);
                        }
                    }
                }
            }
            .desc{
                h3{
                    @include h5;
                    line-height: 34px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                    @media only screen and (max-width: 991px) {
                        @include h6;
                        line-height: 28px;
                    }
                }
            }
        }
        &.large{
            grid-column-end: span 2;
            grid-row-end: span 2;
            .image{
                &:hover{
                   .desc{
                       background: rgba(137, 47, 51, 0.8);
                   }
                }
            }
            .desc{
                h3{
                    @include h4;
                    line-height: 46px;
                    @media only screen and (max-width: 991px) {
                        @include h6;
                        line-height: 28px;
                    }
                }
            }
        }
        .image{
            @include fill;
            @include bg_pos;
            background-size: cover;
            @media only screen and (max-width: 991px) {
                width: 100%;
                height: 0;
                padding-bottom: 80%;
                position: relative;
                margin-bottom: 15px;
            }
            &:hover{
                .desc{
                  //  background: rgba(3, 69, 66, 0.8);
                }
            }
            .desc{
                @include fill;
                @include anim;
                padding: 35px 34px;
                color:$white;
               // background: transparent;
                @media only screen and (max-width: 1500px) {
                    padding: 25px 24px;
                }
                @media only screen and (max-width: 1200px) {
                    padding: 15px 14px;
                }
                h3{
                    margin-top: -10px;
                }
            }
        }
    }
}

.more{
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
}

.movies{
    white-space: nowrap;
    //margin-bottom: 81px;
    margin-bottom: 59px;
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(237, 234, 230, 0.3);
    > ul{
        //margin: 0 -17px;
        position: relative;
       > li{
            //padding: 0 17px;
            display: inline-block;
            vertical-align: top;
            //width: 33.33%;
           float: none;
            white-space: normal;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            .movie_box{
                width: 100%;
                height: 0;
                padding-bottom: 56.25%;
                @include bg_pos;
                background-size: cover;
                position: relative;
                @media only screen and (max-width: 991px) {
                    padding-bottom: 80%;
                }
                &:hover{
                    .cover{
                        background: rgba(3, 69, 66, 0.8);
                    }
                }
                .cover{
                    @include anim;
                    @include fill;
                    .play_cover{
                        @include vetical;
                        @include fill;
                        > div{
                            width: 100%;
                            text-align: center;
                        }
                    }
                    .desc{
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        font-family: $special_font;
                        color: $white;

                        background: transparent;
                        @media only screen and (max-width: 991px) {
                            bottom: 6%;
                        }
                        .title{
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 15px;
                            }
                            padding: 30px;
                            h4{
                                font-weight: 400;
                                @include h5;
                                line-height: 30px;
                                @media only screen and (max-width: 991px) {
                                    @include h6;
                                    line-height: 28px;
                                }
                            }
                            text-transform: uppercase;
                        }
                        .date{
                            @include h6;
                            @media only screen and (max-width: 991px) {
                                @include p;
                            }
                        }
                    }
                }
            }
        }
    }
}

.more2{
    padding: 70px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
}

.page_content{
    @media only screen and (max-width: 991px) {
        overflow: hidden;
    }
}

.lp_footer{
    @media only screen and (max-width: 991px) {
        text-align: center;
        .text-right, .devs{
            text-align: center !important;
        }
    }
    .general_footer_top{
        > div{
            > div{
                float: none;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
    .footer_menu2{
        ul{
            li{
                width: 25%;
            }
        }
    }
    .newsletter{
        .nl_holder{
            padding: 19px 0 36px 0;
            &.n_border{
                border-top: 2px solid #002043;
            }
        }
        .padding{
            padding: 0 70px;
            @media only screen and (max-width: 991px) {
                padding: 0;
            }
        }
        .newsletter_holder{
            width: 100%;
            @media only screen and (max-width: 991px) {
                border-bottom: none;
            }
            input{
                display: inline-block;
                vertical-align: top;
                //outline: none;
                border: 0;
                height: 60px;
                border: none;
                border-radius: 10px;
                &.n_input{
                    margin-right: 35px;
                    background: $white;
                    @include p;
                    color: $white;
                    //width: calc(100% - 448px - 35px);
                    width: calc(100% - 419px - 35px);
                    padding: 0 37px;
                    color:$font_color2;
                    @media only screen and (max-width: 1700px) {
                        width: calc(100% - 372px - 35px);
                    }
                    @media only screen and (max-width: 1500px) {
                        width: calc(100% - 316px - 35px);
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        font-size: 16px;
                        height: 50px;
                        border-bottom-left-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                    }
                }
                &.n_submit{
                    //width: 200px;
                    //width: 448px;
                    width: 419px;
                    @include p;
                    font-family: $special_font;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $white;
                    text-align: center;
                    background: $font_color1;
                    letter-spacing:2px;
                    @media only screen and (max-width: 1700px) {
                        width: 372px;
                    }
                    @media only screen and (max-width: 1500px) {
                        width: 316px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        border-top-left-radius: 0 !important;
                        border-top-right-radius: 0 !important;
                    }
                }
            }

            .form_error {
                color:red;
                position:absolute;
                top:105%;
                left:0;
            }
        }
    }
    .footer_section_list{
        &.hide_section{
            display: none;
        }
        > ul{
            > li{
                padding-bottom: 33px;
            }
        }
    }

    .menu_holder{
        //padding-left: 50px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            margin: 0 -15px;
        }
        color:#000000;
        font-size: 14px;
        line-height: 34px;
        font-weight: 600;
        a{
            font-weight: 600;
        }
        p{
            @include p;
            margin-bottom: 10px;
            font-weight: 700;
        }
        .footer_menu{
            display: inline-block;
            vertical-align: top;
            width: 20%;
            @media only screen and (max-width: 991px) {
                width: 33.33%;
                padding: 0 15px;
                margin-bottom: 30px;
            }

        }
    }

    .footer_menu2 {
        width:100%;
        display: inline-block;
        vertical-align: top;
        color:$white;
        position:relative;
        bottom:6px;
        text-align: left;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            border-bottom: 1px solid rgba(255,255,255,0.1);
            padding-bottom: 20px;
            margin-bottom: 20px;
            text-align: center;
            padding-top: 30px;
        }
        p{
            @include p;
            margin-bottom: 10px;
        }
        ul{
            @media only screen and (max-width: 991px) {
                margin: 0 -15px;
            }
            li{
                width:25%;
                display:inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    vertical-align: top;
                    width: 33.33%;
                    padding: 0 15px;
                    margin-bottom: 10px;
                    width: 100%;
                }
                a{
                    font-size: 14px;
                    line-height: 34px;
                    @media only screen and (max-width: 991px) {
                        line-height: 24px;
                        font-size: 11px;
                        line-height: 21px;
                    }
                    //color:#747474
                    color: $white;
                }
            }
        }
    }

    .general_footer{
        padding: 35px 0 30px 0;
        @include bg_pos;
        background-size: cover;
        background-image: url("/img/footer_bg.jpg");
        color: $white;
        .to_up{
            //margin-top: 53px;
            cursor: pointer;
        }

        .logos_holder{
            @media only screen and (max-width: 991px) {
                margin:0 0 35px 0;
                > li{

                    display: inline-block;
                    vertical-align: middle;
                    &:first-child{
                        width: 40%;
                    }
                    &:last-child{
                        width: 60%;
                    }
                    .logotypes{
                        text-align: right;
                        width: 100%;
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 15px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }



        .devs{
            //padding-top: 60px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                text-align: right;
                display: inline-block;
                vertical-align: middle;
                width: 100%;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 12px;
                    text-transform: uppercase;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    p{
                        //color:#9e9e9e;
                        color: $white;
                        font-weight: 400;
                        margin-bottom: 0;
                    }
                    img{
                        margin-left: 10px;
                    }
                }
            }
        }
        .menu_holder{
            .footer_menu{
                ul{
                    li{
                        a{
                            color:#9e9e9e;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .general_footer_bottom{
            padding-top: 30px;
            .footer_logos_holder{
                text-align: center;
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    margin-bottom: 30px;
                }
            }
            > .row{
                > div{
                    float:none;
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.page_footer{
    background: $theme_color1;
    //&.lp_footer{
    //    .footer_menu2{
    //        ul{
    //            li{
    //                width: 25%;
    //            }
    //        }
    //    }
    //}
    .newsletter{
        .nl_holder{
            padding: 19px 0 36px 0;
            &.n_border{
                border-top: 2px solid #002043;
            }
        }
        .padding{
            padding: 0 70px;
            @media only screen and (max-width: 991px) {
                padding: 0;
            }
        }
        .newsletter_holder{
            width: 100%;
            @media only screen and (max-width: 991px) {
                border-bottom: none;
            }
            input{
                display: inline-block;
                vertical-align: top;
                //outline: none;
                border: 0;
                height: 60px;
                border: none;
                border-radius: 10px;
                &.n_input{
                    margin-right: 35px;
                    background: $white;
                    @include p;
                    color: $white;
                    //width: calc(100% - 448px - 35px);
                    width: calc(100% - 419px - 35px);
                    padding: 0 37px;
                    color:$font_color2;
                    @media only screen and (max-width: 1700px) {
                        width: calc(100% - 372px - 35px);
                    }
                    @media only screen and (max-width: 1500px) {
                        width: calc(100% - 316px - 35px);
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        font-size: 16px;
                        height: 50px;
                        border-bottom-left-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                    }
                }
                &.n_submit{
                    //width: 200px;
                    //width: 448px;
                    width: 419px;
                    @include p;
                    font-family: $special_font;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $white;
                    text-align: center;
                    background: $font_color1;
                    letter-spacing:2px;
                    @media only screen and (max-width: 1700px) {
                        width: 372px;
                    }
                    @media only screen and (max-width: 1500px) {
                        width: 316px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        border-top-left-radius: 0 !important;
                        border-top-right-radius: 0 !important;
                    }
                }
            }

            .form_error {
                color:red;
                position:absolute;
                top:105%;
                left:0;
            }
        }
    }
    .footer_section_list{
        &.hide_section{
            display: none;
        }
       > ul{
           > li{
               padding-bottom: 33px;
            }
        }
    }

    .menu_holder{
        //padding-left: 50px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            margin: 0 -15px;
        }
        color:#000000;
        font-size: 14px;
        line-height: 34px;
        font-weight: 600;
        a{
            font-weight: 600;
        }
        p{
            @include p;
            margin-bottom: 10px;
            font-weight: 700;
        }
        .footer_menu{
            display: inline-block;
            vertical-align: top;
            width: 20%;
            @media only screen and (max-width: 991px) {
                width: 33.33%;
                padding: 0 15px;
                margin-bottom: 30px;
            }

        }
    }

    .footer_menu2 {
        width:100%;
        display: inline-block;
        vertical-align: top;
        color:$white;
        position:relative;
        bottom:6px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            border-bottom: 1px solid rgba(255,255,255,0.1);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        p{
            @include p;
            margin-bottom: 10px;
        }
        ul{
            @media only screen and (max-width: 991px) {
                margin: 0 -15px;
            }
            li{
                width:25%;
                display:inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    vertical-align: top;
                    width: 33.33%;
                    padding: 0 15px;
                    margin-bottom: 10px;
                }
                a{
                    font-size: 14px;
                    line-height: 34px;
                    @media only screen and (max-width: 991px) {
                        line-height: 24px;
                        font-size: 11px;
                        line-height: 21px;
                    }
                    //color:#747474
                    color: $white;
                }
            }
        }
    }

    .general_footer{
        padding: 35px 0 30px 0;
        @include bg_pos;
        background-size: cover;
        background-image: url("/img/footer_bg.jpg");
        color: $white;
        @media only screen and (max-width: 991px) {
            padding: 40px 0;
            background-image: url("/img/footer_bg2.jpg");

        }
        .small_logo{
            @media only screen and (max-width: 991px) {
                text-align: right;
            }
        }
        .to_up{
            //margin-top: 53px;
            cursor: pointer;
        }

        .logos_holder{
            @media only screen and (max-width: 991px) {
                margin:0 0 35px 0;
                > li{

                    display: inline-block;
                    vertical-align: middle;
                    &:first-child{
                        width: 40%;
                    }
                    &:last-child{
                        width: 60%;
                    }
                    .logotypes{
                        text-align: right;
                        width: 100%;
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 15px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobile_logos{
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
        }
        .copyright{
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .copyright_mobile{
            display: none;
            @media only screen and (max-width: 991px) {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                //padding-top: 5px;
                text-align: right;
                padding-bottom: 15px;
            }
        }

        .devs{
            //padding-top: 60px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                text-align: right;
                display: inline-block;
                vertical-align: middle;
                width: 100%;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 12px;
                    text-transform: uppercase;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    p{
                        //color:#9e9e9e;
                        color: $white;
                        font-weight: 400;
                        margin-bottom: 0;
                    }
                    img{
                        margin-left: 10px;
                    }
                }
            }
        }
        .menu_holder{
            .footer_menu{
                ul{
                    li{
                        a{
                            color:#9e9e9e;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .general_footer_bottom{
            padding-top: 30px;
            .footer_logos_holder{
                text-align: center;
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    margin-bottom: 30px;
                }
            }
            > .row{
                > div{
                    float:none;
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

section.top{
    height: 560px;
    position: relative;
    width: 100%;
    background: $black;
    @media only screen and (max-width: 1500px) {
        height: 380px;
    }
    @media only screen and (max-width: 1200px) {
        height: 320px;
    }
    .image{
        @include fill;
        @include bg_pos;
        background-size: cover;
        opacity: 0.5;
    }
    .top_content{
        width: 100%;
        left: 0;
        //bottom: 0;
        bottom: -5px;
        position: absolute;
        .subpage_header{
            font-family: $special_font;
            margin-bottom: 31px;
            @media only screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 30px;
            }
            h1{
                font-size: 50px;
                color: $white;
                //font-weight: 600;
                text-transform: uppercase;
                @media only screen and (max-width: 991px) {
                    font-size: 20px;
                }
            }
        }
        .nav_way{
            padding:33px 0 35px 0;
           /* @media only screen and (max-width: 1500px) {
                padding: 30px 0;
            }*/
            @media only screen and (max-width: 991px) {
                display: none;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                    &:after{
                        content: '>';
                        color:#b2a08c;
                        display: inline-block;
                        margin: 0 21px;
                    }
                    a{
                        font-size: 12px;
                        text-transform: uppercase;
                        color: rgba(255,255,255,0.5);
                        letter-spacing: 1px;
                        font-weight: 700;
                        @include anim;
                        &:hover{
                            color: rgba(255,255,255,1);
                        }
                    }
                }
            }
        }
    }
}

.tilts{
    /*padding: 100px 0 60px 0;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 20px 0;
    }*/
    .tilts_list{
        text-align: center;
        > ul{
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
           /* margin: 0 -20px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -15px;
            }*/
            > li{
                min-height: 220px;
                float: none !important;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
                //padding: 0 20px;
                //width: 25%;
                //display: inline-block;
                //vertical-align: top;
                //margin-bottom: 40px;
                /*
                @media only screen and (max-width: 1500px) {
                    padding: 0 15px;
                    margin-bottom: 30px;
                }
                @media only screen and (max-width: 1200px) {
                    width: 50%;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                */
                margin-bottom: 35px;
                &:hover{
                    article{
                        transform: scale(1.05);
                        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
                    }
                }
                a{
                    display: block;
                    height: 100%;
                }
                article{
                    width: 100%;
                    height: 100%;
                    //height: 11.46vw;
                    //background: $black;
                    //background: $theme_color2;
                    //border: 1px solid #efefef;
                    background:#d9d4ce;
                    position: relative;
                    @include anim;
                    padding: 20px 0;
                    //@media only screen and (max-width: 991px) {
                    //    height: 66vw;
                    //}
                    //@media only screen and (max-width: 767px) {
                    //    height: 50vw;
                    //}
                    .image{
                        @include fill;
                        @include bg_pos;
                        background-size: cover;
                        opacity: 0.88;
                        background-repeat: no-repeat;
                    }
                    .box_desc{
                        //@include fill;
                        @include vetical;
                        height: 100%;
                        z-index: 2;
                        position: relative;
                        > div{
                            padding: 0 30px;
                            width: 100%;
                            text-align: center;
                            //color: $white;
                            color: $black;
                            font-family: $main_font;
                            text-transform: uppercase;
                            @media only screen and (max-width: 1200px) {
                                padding: 0 15px;
                            }
                            h2{
                                font-weight: 600;
                                @include h5;
                                line-height: 35px;
                                @media only screen and (max-width: 1300px) {
                                    font-size: 17px;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.main_events{
    .events_list_holder{
        margin-bottom: 35px;
    }
    .events_list{
        padding-top: 0px;
    }
    .mycalendar2_yearslider{
        margin-top: -9px;
    }
    .filter_box {
        > ul{
            @media only screen and (max-width: 991px) {
                display: block;
            }
            > li{
                //@media only screen and (max-width: 991px) {
                //    display: block;
                //}
                @media only screen and (max-width: 991px) {
                    line-height: 25px;
                }
                &:first-child{
                    width: 174px;
                    @media only screen and (max-width: 991px) {
                       width: auto;
                    }
                }
                &:last-child{
                    width: calc(100% - 174px);
                    @media only screen and (max-width: 991px) {
                        width: auto;
                        padding-left: 12px;
                        .chosen-container-single .chosen-single{
                            line-height: unset;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

.main_news, .main_events{
    //padding: 100px 0 40px 0;
    //border-bottom: 1px solid #efefef;
    .calendar{
        //margin-bottom: 55px;
    }

}
/*
.main_news{
    .news_list{
        ul{
            li{
                @include anim;
                div.image{
                    @include anim;
                }
                div.desc_holder{
                    .desc{
                        .default_font{
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                &:hover{
                    transform: scale(1.05);
                    div.image{
                        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}*/

.see_more{
    .section_header2{
        margin-bottom: 8px;
    }
}
.portfolio{
    .five_in_row_elements{
        .image{
            padding-bottom: 150% !important;
        }
    }
}

.main_news, .see_more, .portfolio, .elements_list{
    .news_list{
        ul{
            position: relative;
            li{
                white-space: normal;
                display: inline-block;
                vertical-align: top;
                float: none;
                margin-bottom: 35px;
                &:hover{
                    article{
                        .image{
                            div{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 71.5%;
                    overflow: hidden;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        padding-bottom: 62%;
                    }
                    > div{
                        @include fill;
                        @include bg_pos;
                        background-size: cover;
                        @include anim;
                    }
                }
                h3{
                    @include p;
                    line-height: 26px;
                    color: $font_color1;
                    font-family: $special_font;
                    font-weight: 600;
                    //display: -webkit-box;
                    //-webkit-line-clamp: 1;
                    //-webkit-box-orient: vertical;
                    //overflow: hidden;
                    //text-overflow: ellipsis;
                }
                div.desc_holder{
                    .desc{
                        header{
                            padding: 12px 0;
                            margin-bottom: 12px;
                            border-bottom: 1px solid #002043;
                            > div{
                                display: inline-block;
                                vertical-align: middle;
                            }
                            .title{
                                width: calc(100% - 130px);
                                @media only screen and (max-width: 991px) {
                                    text-align: left;
                                }
                                h3{
                                    @include p;
                                    line-height: 26px;
                                    color: $font_color1;
                                    font-family: $special_font;
                                    font-weight: 600;
                                    //display: -webkit-box;
                                    //-webkit-line-clamp: 1;
                                    //-webkit-box-orient: vertical;
                                    //overflow: hidden;
                                    //text-overflow: ellipsis;
                                }
                            }
                            .date{
                                width: 130px;
                                font-family: $special_font;
                                color: $font_color1;
                                @include p;
                                //text-align: right;
                                p{
                                    font-weight: 600;
                                }
                            }
                        }
                        .default_font{
                            line-height: 25px;
                            color: $font_color2;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 13px;
                            line-height: 18px;
                            p{
                                text-align: justify;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                // height: 90px;
                                @media only screen and (max-width: 991px) {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .go_details{
                            color:$font_color2;
                            font-size: 13px;
                            line-height: 13px;
                            text-transform: uppercase;
                            border-top: 1px solid #bfbbb7;
                            padding-top: 18px;
                            margin-top: 18px;
                            p{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*.news_list{
    > ul{
        margin: 0 -30px;
        > li{
            padding: 0 30px;
            white-space: normal;
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            margin-bottom: 60px;
            div.image{
                width: 100%;
                height: 0;
                padding-bottom: 60%;
                @include bg_pos;
                background-size: cover;
            }
            div.desc_holder{
                padding: 0 30px;
                margin-top: -60px;
                .desc{
                    padding: 30px;
                    background: $white;
                    box-shadow: 0px 5px 10px 0px rgba(23, 25, 24, 0.05);
                    header{
                        margin-bottom: 25px;
                        .date{
                            margin-bottom: 15px;
                            color: $font_color2;
                        }
                        h3{
                            @include h6;
                            color: $font_color1;
                            font-family: $special_font;
                            font-weight: 600;
                        }
                    }
                    .default_font{
                        line-height: 25px;
                        color: $font_color2;
                    }
                }
            }
        }
    }
}*/

.events_details{
    /*
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 0 0;
    }
    */

}
/*
.only_text_page{
    border-bottom: 1px solid #efefef;
}
*/
/*
.small_gallery{
    margin-bottom: 90px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
    }
    ul{
        margin: 0 -10px;
        li{
            padding: 0 10px 20px 10px;
            display: inline-block;
            vertical-align: top;
            width: 25%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            .image{
                width: 100%;
                height: 0;
                padding-bottom: 70%;
                @include bg_pos;
                background-size: cover;
            }
        }
    }
}
*/

.see_more{
    background: #edeae6;
    &.see_more2{
        padding-bottom: 18px;
    }
    /*&.see_more2{
        background: $white;
        position: relative;
        z-index: 2;
        border-bottom: 1px solid #efefef;
        padding-bottom: 50px;
        &:after{
            background: $theme_color2;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 600px;
            z-index: -1;
        }
    }*/
    /*
    .events_list{
        > ul {
            > li {
                .event_box {
                    > div.desc {
                        background: #e1e1e1;
                    }
                }
            }
        }
    }
    */
}
/*
.same_category{
    padding-left: 60px;
    ul{
        li{
            margin-bottom: 45px;
            .desc{
                background: $white;
                header{
                    margin-bottom: 25px;
                    .date{
                        margin-bottom: 15px;
                        color: $font_color2;
                    }
                    h3{
                        @include h6;
                        color: $font_color1;
                        font-family: $special_font;
                        font-weight: 600;
                    }
                }
                .default_font{
                    line-height: 25px;
                    color: $font_color2;
                }
            }
        }
    }
}

*/

.events_list{
    > ul{
        > li{
            width: 100%;
            margin-bottom: 17px;
            @include anim;
            @media only screen and (max-width: 991px) {
                &:last-child{
                    margin-bottom: 0;
                }
            }
            &:hover{
                box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.1);
                .event_box{
                    > div{
                        &.image{
                            .cover{
                                opacity: 1;
                            }
                        }
                        &.desc{
                            background: $white;
                            .event_box_top{
                                .arr_box{
                                    background-image: url("/img/event_arr2.png");
                                }
                                .date{
                                    background: $font_color1;
                                    color: $white;
                                }
                            }
                            .event_box_bottom{
                                .desc{
                                    header{
                                        color: $font_color1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .event_box{
                display: flex;
                height: 300px;
                @media only screen and (max-width: 1700px) {
                    height: 260px;
                }
                @media only screen and (max-width: 1500px) {
                    height: 210px;
                }
                @media only screen and (max-width: 991px) {
                    display: block;
                    height: auto;
                }
                div{
                    &.image{
                        height: 100%;
                        width: 100%;
                        @include bg_pos;
                        background-size: cover;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            height: 0;
                            padding-bottom: 62%;
                            width: 100%;
                        }
                        /*@media only screen and (max-width: 1200px) {
                            width: 50%;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            height: 0;
                            padding-bottom: 66%;
                        }*/
                        .cover{
                            opacity: 0;
                            @include anim;
                            @include fill;
                            background: rgba(0, 32, 67, 0.7);
                        }
                    }
                    &.desc{
                        height: 100%;
                        //width: calc(100% - 500px);
                        z-index: 2;
                        padding: 35px 36px 35px 0px;
                        @include anim;
                        position: relative;
                        width: 100%;
                        @media only screen and (max-width: 1500px) {
                            padding: 28px 36px 35px 0px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding: 28px 15px;
                        }
                        &:after{
                            content: '';
                            height: 100%;
                            width: calc(100% + 35px);
                            right: 0;
                            top: 0;
                            position: absolute;
                            background: #e6e1db;
                            z-index: -1;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                        }
                       /* @media only screen and (max-width: 1500px) {
                            width: calc(100% - 350px);
                        }
                        @media only screen and (max-width: 1200px) {
                            width: calc(100% - 50%);
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding: 30px 15px;
                        }*/
                        .event_box_top{
                            @media only screen and (max-width: 991px) {
                                .col-md-2{
                                    display: none;
                                }
                            }
                            .arr_box{
                                @include anim;
                                margin-left: auto;
                                width: 55px;
                                @include bg_pos;
                                height: 20px;
                                background-image: url("/img/event_arr1.png");
                            }
                            .date{
                                display: inline-block;
                                color: $font_color1;
                                background: $white;
                                font-size: 13px;
                                line-height: 30px;
                                padding: 0 15px;
                                box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.1);
                                margin-bottom: 15px;
                                @include anim;
                                strong{
                                    font-size: 14px;
                                }
                            }
                           .info{
                               margin-bottom: 25px;
                                ul{
                                    li{
                                        display: inline-block;
                                        vertical-align: middle;
                                        font-size: 13px;
                                        line-height: 17px;
                                        color: #838383;
                                        padding-left: 21px;
                                        background-repeat: no-repeat;
                                        background-position: left center;
                                        margin-right: 50px;
                                        @media only screen and (max-width: 1200px) {
                                            margin-right: 20px;
                                        }
                                        &:last-child{
                                            margin-right: 0;
                                        }
                                        &.start{
                                            background-image: url("/img/clock_icon.png");
                                        }
                                        &.spots{
                                            background-image: url("/img/person_icon.png");
                                        }
                                    }
                                }
                            }
                        }
                        div.event_box_bottom{
                            .short_desc{
                                h3, p{
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    @media only screen and (max-width: 1500px) {
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                                header{
                                    color: $black;
                                    font-family: $special_font;
                                    margin-bottom: 13px;
                                    @include anim;
                                    @media only screen and (max-width: 991px) {
                                        margin-bottom: 15px;
                                    }
                                    h3{
                                        @include h6;
                                    }
                                }
                                .default_font{
                                    color: $font_color2;
                                    @media only screen and (max-width: 991px) {
                                        line-height: 24px;
                                    }
                                    p{
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.events_details{
    .main_image{
        @include bg_pos;
        background-size: cover;
        width: 100%;
        display: block;
        height: 100%;
        @media only screen and (max-width: 991px) {
            height: 0;
            padding-bottom: 62%;
            margin-bottom: 15px;
        }
    }
    .join_box_holder{
        //padding-left: 20px;
        //padding-top: 102px;
        height: 100%;
        .join_box{
            height: 100%;
            .join_box_top{
                background:#01162e;
                padding:0px 36px;
                color: $white;
                text-transform: uppercase;
                letter-spacing: 1px;
                border-bottom: 1px solid #444444;
                font-size: 13px;
                line-height: 43px;
            }
            .join_box_bottom{
                height: calc(100% - 43px);
                padding:33px 36px 35px 36px;
                background:#002043;
                position: relative;
                header{
                    @include h6;
                    font-family: $special_font;
                    color: $white;
                    margin-bottom: 25px;
                }
                .date{
                    display: inline-block;
                    color: #aaaaaa;
                    font-size: 14px;
                    line-height: 30px;
                    box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.1);
                    margin-bottom: 7px;
                    @include anim;
                    text-transform: uppercase;
                    font-family: $main_font;
                    strong{
                        font-size: 14px;
                    }
                }
                .info{
                    margin-bottom: 40px;
                    ul{
                        li{
                            font-size: 13px;
                            line-height: 17px;
                            color: #aaaaaa;
                            padding-left: 21px;
                            background-repeat: no-repeat;
                            background-position: left center;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-right: 0;
                            }
                            &.start{
                                background-image: url("/img/clock_icon2.png");
                            }
                            &.spots{
                                background-image: url("/img/person_icon2.png");
                            }
                        }
                    }
                }
                .reserve_btn_holder{
                    position: absolute;
                    bottom: 35px;
                    left: 0;
                    width: 100%;
                    padding: 0 36px;
                }
                .reserve_btn{
                    width: 100%;
                    padding: 0;
                    text-align: center;
                    background: #edeae6;
                    color: $font_color1;
                    display: block;
                }
            }
        }
    }
    .events_info{
        margin-bottom: 27px;
        .date{
            > div {
                display:inline-block;
                vertical-align: top;
                margin-right: 20px;

                &.date_inner {
                    display: inline-block;
                    color: $white;
                    background: $font_color1;
                    font-size: 13px;
                    line-height: 34px;
                    padding: 0 15px;
                    box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.1);
                    margin-bottom: 15px;
                    @include anim;
                    strong{
                        font-size: 14px;
                    }
                }

                &.social {
                    position:relative;
                    bottom:-6px;
                }
            }
        }
        .info{
            //margin-bottom: 25px;
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 13px;
                    line-height: 17px;
                    color: #838383;
                    padding-left: 33px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    margin-right: 51px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.start{
                        background-image: url("/img/clock_icon.png");
                    }
                    &.spots{
                        background-image: url("/img/person_icon.png");
                    }
                }
            }
        }
    }
}

.reserve_banner{
    padding: 0 36px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    //margin-bottom: 100px;
    margin-bottom: 35px;

    .flexed{
        height: 197px;
        @media only screen and (max-width: 991px) {
            height: auto;
            padding:30px;
            .reserve_btn{
                margin-top: 15px;
            }
        }
        > div{
            height: 100%;
            @include vetical;
            > div{
                width: 100%;
            }
        }
    }
    .section_header{
        color: #838383;
        @include p;
        margin-bottom: 0;
        h2{
            color: $white;
            margin-bottom: 15px;
            font-weight: 600;
            letter-spacing: 2px;
        }
    }
}

.reserve_btn{
    display: inline-block;
    @include p;
    text-transform: uppercase;
    color: $white;
    font-family: $special_font;
    line-height: 55px;
    padding: 0 40px;
    background: $font_color1;
    letter-spacing: 1px;
    span{
        padding-right: 73px;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("/img/more_arr2.png");
    }
}

.side_menu{
    padding-top:100px;
    @media only screen and (max-width: 1500px) {
        padding-top: 50px;
    }
    .section_header{
        background:#1a1c19;
        margin-bottom: 0;
        padding: 12px 33px;
        border-bottom: 1px solid #3a3a3a;
        h2, h3{
            color: $white;
        }
        h3{
            @include h6;
            line-height: 60px;
        }
    }
    .menu{
        padding:36px 33px;
        background: #2a2a2a;
        ul{
            li{
                padding-bottom: 17px;
                margin-bottom: 17px;
                border-bottom: 1px solid #3a3a3a;
                &:last-child{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }

                &.active {
                    div{
                        a{
                            color: $white;
                        }
                    }
                }

                div{
                    a{
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 600;
                        color:#cdcdcd;
                        @include anim;
                        &:hover{
                            padding-left: 10px;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
.select_box_holder{
    width: 423px;
    padding: 0 15px;
    @media only screen and (max-width: 991px) {
        width: 100%;
    }
    .filter_box{
        &.filter_box2{
            width: 100%;
            select{
                width: 100%;
            }
        }
    }
}



.universal{
    .news, .events, .small_cadre{
        .section_header2{
            margin-bottom: 9px;
        }
    }
    .filter_box{
        margin-top: -17px;
        border-bottom: 1px solid #e5dcd2;
    }
   // padding: 100px 0 10px 0;
   // border-bottom: 1px solid #efefef;
    /*@media only screen and (max-width: 1500px) {
        padding: 50px 0 10px 0;
    }
    */
    /*.container{
        > .row{
            margin: 0 -45px;
            > div{
                padding: 0 45px;
            }
        }
    }*/

    .unversal_slider{
        position: relative;
        width: 100%;
        height: 569px;
        margin-bottom: 65px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 80%;
            height: 0;
        }
        > ul{
            @include fill;
           > li{
               @include fill;
                @include bg_pos;

               display:none;
               background-size: cover;
               background-image: url("/img/cover_bg.jpg");

               &:first-child {
                   display:block;
               }

               .image{
                   opacity: 0.7;
                   @include fill;
                   @include bg_pos;
                   background-size: cover;
               }
               .slider_content{
                   position: absolute;
                   left: 0;
                   bottom: 0;
                   //height: 100%;
                   width: 62%;
                   padding-left: 52px;
                   font-family: $special_font;
                   @include h6;
                   line-height: 35px;
                   color: $white;
                   z-index: 2;
                   padding-bottom: 40px;
                   @media only screen and (max-width: 991px) {
                       width: 100%;
                       padding-left: 0;
                   }
                   header{
                       margin-bottom: 40px;
                       @media only screen and (max-width: 1500px) {
                           margin-bottom: 25px;
                       }
                       @media only screen and (max-width: 991px) {
                            text-align: center;
                       }
                       h2{
                           font-weight: 600;
                           color: $white;
                           font-size: 48px;
                           line-height: 58px;
                           @media only screen and (max-width: 1500px) {
                               font-size: 36px;
                               line-height: 46px;
                           }
                           @media only screen and (max-width: 1200px) {
                               font-size: 30px;
                                line-height: 40px;
                           }
                           @media only screen and (max-width: 991px) {
                               font-size: 20px;
                               line-height: 30px;
                               padding: 0 15px;
                           }
                       }
                   }
                   .desc{
                       padding-top:12px;
                       padding-bottom:12px;
                       padding-right: 30%;
                       color: $white;
                       min-height:60px;
                       @media only screen and (max-width: 991px) {
                            display: none;
                       }

                       &.link {
                           background-position: right top;
                           background-repeat: no-repeat;
                           background-image: url("/img/more_arr1.png");
                       }
                   }
               }
            }
        }
        .slider_nav{
            right: 0;
            width: 38%;
            z-index: 5;
            bottom: 0;
            position: absolute;
            padding-right: 52px;
            padding-bottom: 40px;
            text-align: right;
            > div{
                display: inline-block;
                vertical-align: middle;
                margin-right: 30px;
                &.stop_slider{
                    .scripts_btn{
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        background-color: #7c8285;
                        @include bg_pos;
                        &.scripts_off_btn{
                            background-image: url("./../img/pause.svg");
                        }
                        &.scripts_on_btn{
                            display: none;
                            background-image: url("./../img/play.svg");
                        }
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            @media only screen and (max-width: 991px) {
                padding-right: 0;
                width: 100%;
                bottom: 20px;
            }
            .arr{
                text-align: right;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                    display: inline-block;
                    vertical-align: middle;
                }
                img{
                    cursor: pointer;
                }
            }
            .arr_left{
                display: block;
                margin-bottom: 25px;
                @media only screen and (max-width: 991px) {
                    text-align: left;
                    margin-bottom: 0;

                    padding-left: 15px;
                }
            }
            .arr_right{
                display: block;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 0;
                    text-align: right;
                    padding-right: 15px;
                }
            }
        }
    }
    /*
    .news{
        padding: 0;
        margin-bottom: 50px;
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
        .arrs{
            padding-top: 30px;
            text-align: right;
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 40px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 50%;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        &:after{
            display: none;
        }
    }
    .more{
        padding-top: 30px;
    }
    */
}

.harmonogram{
    border-bottom: 1px solid #efefef;
    .department{
        padding: 51px 0;
        background: $theme_color2;
        .direction{
            font-size: 14px;
            line-height: 24px;
            display: inline-block;
            padding-left: 11px;
            border-left: 3px solid #1a243c;
            text-transform: uppercase;
            height: 44px;
            overflow: hidden;
            margin-bottom: 20px;
            p{
                position: relative;
                top: -5px;
            }
            h3{
                font-weight: 600;
                font-family: $special_font;
                color:$font_color1;
                @include h6;
            }
        }
        .spec{
            font-size: 14px;
            color: #6f6f6f;
            strong{
                color: $font_color1;
                text-transform: uppercase;
            }
        }
    }
    .harmonogram_plan{
        padding: 60px 0 90px 0;
        .plan_box{
            padding-top: 120px;
            padding-bottom: 100px;
        }
        .download{
            text-align: right;
            > ul{
                display: inline-block;
                > li{
                    text-align: left;
                    display: inline-block;
                    vertical-align: middle;
                    @include p;
                    text-transform: uppercase;
                    color:$font_color1;
                    margin-right: 45px;
                    letter-spacing: 1px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .download_box{
            width: 466px;
            padding-left: 60px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("/img/pdf_icon.png");
            text-transform: uppercase;
            color: $font_color1;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 2px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #dfdfdf;
            > div{
                background-position: right center;
                background-repeat: no-repeat;
                background-image: url("/img/download_arr.png");
            }
            p{
                font-weight: 700;
                span{
                    color: #6f6f6f;
                    font-weight: 400;
                }
            }
        }
    }
}

.contact{
    /*padding: 100px 0 100px 0;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }*/
    .small_map{
        margin-top: 43px;
        margin-bottom: 35px;
        position: relative;
        border: 2px solid $white;
        width: 100%;
        height: 0;
        padding-bottom: 44.1%;
        iframe{
            border:none;
            @include fill;
        }
    }
    .section_header2{
        margin-bottom: 9px;
    }
    .last_contact_box{
        .section_header2{
            margin-bottom: 25px;
        }
        .contact_list{
            padding-top: 40px;
        }
    }
    .small_desc_box{
        @include h6;
        line-height: 36px;
        font-family: $special_font;
        color:#6f6f6f;
    }
    .contact_desc_box{
        /*padding: 60px;
        box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.2);
        @media only screen and (max-width: 1500px) {
            padding: 40px;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 15px;
        }
        header{
            font-family: $special_font;
            @include h6;
            line-height: 35px;
            color:#6f6f6f;
            h2{
                @include h6;
                color: $font_color1;
                line-height: 31px;
                margin-bottom: 25px;
            }
        }
        */
        .contact_list{
            @media only screen and (max-width: 991px) {
                padding-top: 20px;
            }
            margin-bottom: 20px;
            .list_desc{
                padding-top: 18px;
                p{
                    span{
                        background-repeat: no-repeat;
                        background-position: left center;
                        padding-left: 23px;
                        margin-bottom: 15px;
                        color: $black;
                        min-height: 25px;
                        background-image: url("/img/map_box_icon3.png");
                        margin-left: 22px;
                        @media only screen and (max-width: 1700px) {
                            margin-left: 0;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-left: 0;
                        }
                    }
                }
            }
            ul{
                li{
                    background-repeat: no-repeat;
                    background-position: left top 4px;
                    padding-left: 33px;
                    margin-bottom: 15px;
                    color: $black;
                    min-height: 25px;
                    p{
                        font-weight: 400;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.adres{
                        background-image: url("/img/map_box_icon1.png");
                    }
                    &.phone{
                        background-image: url("/img/map_box_icon2.png");
                    }
                    &.mail{
                        background-image: url("/img/map_box_icon3.png");
                    }
                }
            }
        }
    }
    .map{
        height: 484px;
        width: 100%;
        border: 2px solid $white;
        position: relative;
        @include vetical;
        @media only screen and (max-width: 991px) {
            height: 250px;
        }
        > div{
            //position: relative;
            z-index: 2;
            //width: 100%;
            position: absolute;
            right: 55px;

        }
        iframe{
            @include fill;
        }
    }
    .map_desc_box{
        display: block;
        padding: 32.5px 30px;
        margin-left: auto;
        //margin-right: 55px;
        background: $white;
        font-size: 14px;
        line-height: 20px;
        //width: 305px;
        width: 405px;
        box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.2);
        @media only screen and (max-width: 991px) {
            display: none;
        }
        &.map_desc_box_mobile{
            display: none;
            @media only screen and (max-width: 991px) {
                display: block !important;
                width: 100%;
                padding: 30px;
                margin-top: 30px;
            }
        }
        header{
            color: $font_color1;
            font-family: $special_font;
            margin-bottom: 25px;
            h2{
                @include h6;
                line-height: 31px;
            }
        }
        .contact_list{
            margin-bottom: 15px;
            ul{
                li{
                    background-repeat: no-repeat;
                    background-position: left top 4px;
                    padding-left: 33px;
                    margin-bottom: 15px;
                    color: $font_color1;
                    min-height: 25px;
                    p{
                        font-weight: 600;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.adres{
                        background-image: url("/img/map_box_icon1.png");
                    }
                    &.phone{
                        background-image: url("/img/map_box_icon2.png");
                    }
                    &.mail{
                        background-image: url("/img/map_box_icon3.png");
                    }
                }
            }
        }
        .desc{
            padding-left: 33px;
            color: $font_color2;
        }
    }
    .bank_numbers{
        padding-top: 30px;
        //margin-bottom: 90px;
       /* @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }*/
        ul{
            //margin: 0 -15px;
            > li{
                //display: inline-block;
                //vertical-align: top;
                color: #6f6f6f;
                font-size: 12px;
                line-height: 20px;
                //margin-right: 44px;
                //padding: 0 15px;
                //width: 20%;
                margin-bottom: 44px;
                @media only screen and (max-width: 991px) {
                    //width: 100%;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .contact_data_boxes{
        //margin-bottom: 60px;
        margin-bottom: 35px;
        > ul{
            //margin: 0 -30px;
            min-height: 190px;
            margin-top: -15px;
            > li{
                @media only screen and (max-width: 1300px) {
                    width: 50% !important;
                    margin-bottom: 15px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100% !important;
                }
                /*
                padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                width: 25%;
                @media only screen and (max-width: 1500px) {
                    width: 50%;
                    margin-bottom: 30px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 0;
                }
                */
                .contact_data_box{
                    padding: 30px 35px 0px 0;
                    border: 1px solid #dfd5c9;
                    width: 100%;
                    //min-height: 260px;
                    height: 100%;
                    background: $white;
                    header{
                        padding-left: 35px;
                        font-family: $special_font;
                        border-left: 3px solid #002043;
                        margin-bottom: 30px;
                        @media only screen and (max-width: 1500px) {
                            padding-left: 15px;
                        }
                        h3{
                            font-weight: 600;
                            @include h6;
                            line-height: 31px;
                            color: $font_color1;
                        }
                    }
                    .contact_list{
                        padding-left: 35px;
                        margin-bottom: 20px;
                        @media only screen and (max-width: 1500px) {
                            padding-left: 15px;
                        }
                        ul{
                            li{
                                background-repeat: no-repeat;
                                background-position: left top 6px;
                                padding-left: 35px;
                                margin-bottom: 15px;
                                color: $font_color1;
                                min-height: 25px;
                                padding-top: 3px;
                                @media only screen and (max-width: 991px) {
                                    text-align: left;
                                }
                                p{
                                    font-weight: 600;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                &.adres{
                                    background-image: url("/img/map_box_icon1.png");
                                }
                                &.phone{
                                    background-image: url("/img/map_box_icon2.png");
                                }
                                &.mail{
                                    background-image: url("/img/map_box_icon3.png");
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .phone_toggles{
        > ul{
            //margin: 0 -30px;
            > li{
                //width: 33.33%;
                //padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                float: none;
                //margin-bottom: 60px;
                margin-bottom: 35px;
                /*@media only screen and (max-width: 1500px) {
                    margin-bottom: 40px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }*/
                &.active{
                    .phone_box{
                        box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.1);
                        background: $white;
                        &:after{
                            left: 0;
                            width: 100%;
                        }
                        .phone_top{
                            color: $font_color1;
                            background-image: url("/img/phone_minus.png");
                        }
                    }
                }
            }
        }
        .phone_box{
            background: #dbd8d4;
            cursor: pointer;
            @include anim;
            position: relative;

            &:after{
                content: '';
                width: 0%;
                left: 50%;
                top: 0;
                border-top: 2px solid #002043;
                @include anim;
                position: absolute;
            }
            div.phone_top{
                background-repeat: no-repeat;
                background-position: right 16px top 19px;
                background-image: url("/img/phone_plus.png");
                //@include p;
                font-size: 13px;
                color: #6f6f6f;
                @include anim;
                p{
                    font-weight: 700;
                }
                padding:16px 50px 16px 17px;
            }
            div.phone_bottom{
                padding: 7px 20px 20px 17px;
                display: none;
                .contact_list{
                    ul{
                        li{
                            background-repeat: no-repeat;
                            background-position: left top;
                            padding-left: 33px;
                            margin-bottom: 15px;
                            color: $font_color1;
                            min-height: 25px;
                           // padding-top: 3px;
                            font-size: 13px;
                            p{
                                font-weight: 600;
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                            &.adres{
                                background-image: url("/img/map_box_icon1.png");
                            }
                            &.phone{
                                background-image: url("/img/map_box_icon2.png");
                            }
                            &.mail{
                                background-image: url("/img/map_box_icon3.png");
                            }
                        }
                    }
                }
                .mails{
                    padding-top: 20px;
                    margin-bottom: 15px;
                    ul{
                        padding-left: 15.5px;
                        border-left: 2px solid #f0f0f0;
                        li{
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            .mail_box{
                                font-size: 14px;
                                line-height: 24px;
                                color: $font_color1;
                                span{
                                    color: #6f6f6f;
                                    padding-left: 30px;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    background-image: url("/img/small_mail_icon.png");
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contact_form{
        margin-top: -18px;
        .grid_pd{
            margin-bottom: 30px;
        }
        .submit_btn{
            margin-top: 41px;
            margin-bottom: 41px;
        }
        /*
        background: #f6f1ec;
        padding: 75px 80px;
        margin-bottom: 90px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
            padding: 50px 55px;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 15px;
        }
        .section_header{
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            h2{
                line-height: 45px;
            }
        }
        */
    }
}

.form_holder{
    .g-recaptcha{
       > div{
           margin-left: auto;
       }
    }
    input[type=submit]{
        //outline:none;
        display:block;
        margin-left: auto;
        margin-top: 30px;
        border:none;
        background:$font_color1;
        font-size:16px;
        color:$white;
        text-transform:uppercase;
        letter-spacing:2px;
        font-family:$special_font;
        height:54px;
        padding-left: 46px;
        padding-right: 121px;
        background-repeat: no-repeat;
        background-position: right 44px center;
        background-image: url("/img/more_arr2.png");
    }
    .grid_pd{
        padding-bottom: 30px;
    }
}

.default_form{
    .default_label{
        color: $black;
        font-weight: 700;
        margin: 0;
        line-height: 55px;
        @include p;
    }
    .div_bio{
        padding-top: 11px;
    }
    .default_p{
        font-size: 16px;
        word-wrap: break-word;
        width: 800px;
        margin-top: 16px;
    }
    .default_p_small{
        font-size: 16px;
        word-wrap: break-word;
        width: 700px;
        margin-top: 16px;
    }
    input[type=text], .default_input{
        //outline: none;
        height: 55px;
        @include p;
        color: $font_color1;
        padding: 0 25px;
        border: none;
        width: 100%;
    }
    textarea, .default_textarea{
        resize: none;
        //outline: none;
        @include p;
        color: $font_color1;
        padding: 15px 25px;
        border: none;
        width: 100%;
        min-height: 180px;
    }
    .holder{
        margin-bottom: 30px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
        &.text-right{
            padding-top: 40px;
            margin-bottom: 0;
            @media only screen and (max-width: 1500px) {
                padding-top: 20px;
            }
        }
        > div{
            display: inline-block;
            vertical-align: top;
            &:first-child{
                width: 130px;
                padding-right: 30px;
                text-align: right;
                padding-top: 20px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: left;
                    padding-bottom: 15px;
                }
            }
            &:last-child{
                width: calc(100% - 130px);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }


    }
    /*.rules{
        > div{
            display: inline-block;
            vertical-align: top;
            &.check_holder{
                width: 21px;
                .checkbox_image_box{
                    border: 1px solid #d7d7d7;
                    border-bottom: 2px solid #d7d7d7;
                    width: 100%;
                    background: $white;
                    > div{
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        border-radius: 0;
                        border: none;
                        @include bg_pos;
                        background-size: cover;
                        background-color: transparent;
                        &.yes{
                            &.on{
                                background-image: url("./../img/check_icon.png");
                            }
                        }
                    }
                }

            }
            &.check_desc{
                width: calc(100% - 21px);
                padding-left: 32px;
                color: #6f6f6f;
                font-size: 14px;
                line-height: 21px;
                margin-top: -5px;
            }
        }
    }*/

    p.err {
        padding-top:10px;
        color:red;
    }
}

.form_portfolio{
    .multi_list {
        > li{
            margin: 10px 0 0  10px;
            display: inline-block;
            vertical-align: top;
        }
    }
    .select2-container {
        margin-top: 0;
        width: 100% !important;
        &:after{
            content:'';
            position: absolute;
            top:20%;
            left: -35px;
            height: 60%;
            border-left:1px solid #d5d0c8;
            display: none;
        }
        .select2-selection--single{
            height: 55px;
            background:$white;
            .select2-selection__rendered{
                line-height: 55px;
                padding: 0 25px;
                font-weight: 400;
                @media only screen and (max-width: 991px) {
                    padding: 0 20px;
                }
            }
            .select2-selection__arrow{
                height: 100%;
                width: 35px;
                background-position:left center;
                b{
                    background-position:right center;
                    margin:0;
                    left: 0;
                    @media only screen and (max-width: 991px) {
                        background-position: left center;
                    }
                }
            }
        }
    }

    .section_header2{
        padding-top: 7px;
    }
    .blue_arr_btn{
        position: relative;
        width: 100%;
        padding: 0;
        text-align: center;
        border:none;
        &.clear_btn{
            background:#d1cac1;
            @media only screen and (max-width: 991px) {
                text-align: center !important;
            }
            span{
                color:$font_color1;
                padding-right: 0;
                background-image:none;
            }
        }
    }
    .default_submit{
        text-indent: 999px;
        @include fill;
        opacity: 0;
    }
    .default_form{
        .row{
            margin-bottom: 30px;
        }
    }
    .form_btns{
        padding-top: 40px;
        margin-bottom: 5px;
    }
    .form_portfolio_details{
        padding-top: 14px;
        .image{
            height:0;
            padding-bottom: 150%;
            background-position: center;
            background-size: cover;
        }
        .small_gallery{
            .image{
                width: 100%;
                height: 13.55vw !important;
                padding-bottom: 0 !important;
                //height:0;
                //padding-bottom: 47%;
                //padding-bottom: 150%;
                @include bg_pos;
                background-size: cover;
                margin-bottom: 30px;
                @media only screen and (max-width: 1300px) {
                    height: 260px !important;
                }
            }
        }
        .default_form{
            margin-top: 0;
            div.blue_arr_btn{
                //margin-top: 35px;
            }
        }
    }
}

.form_portfolio {
    .chosen-container-single:not(div.chosen-container:nth-child(2)) {
        display:none;
    }
}

.form_portfolio_details{
    @media only screen and (max-width: 991px) {
        .text-right{
            text-align: left;
            label{
                padding: 0 25px;
            }
        }
    }
}

.blue_arr_btn{
    display: inline-block;
    padding:0 120px;
    background: $font_color1;
    @media only screen and (max-width: 1500px) {
        padding: 0 60px;
    }
    @media only screen and (max-width: 991px) {
        padding: 0 15px !important;
        width: 100%;
        text-align: left !important;
    }
    span{
        text-transform:uppercase;
        letter-spacing:2px;
        color:$white;
        font-family:$special_font;
        @include p;
        line-height: 55px;
        display: inline-block;
        //padding-right: 70px;
        background-repeat: no-repeat;
        background-position: right center;
        //background-image:url('./../img/blue_btn_arr.png');
        @media only screen and (max-width: 1200px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

.graduate_map{
    .blue_arr_btn{
        margin-top: 7px;
        padding: 0 48px;
        margin-bottom: 35px;
    }
    .map_box{
        width: 100%;
        height: 0;
        padding-bottom: 54.212%;
        position: relative;
        margin-bottom: 35px;
        iframe{
            @include fill;
            border:none;
        }
        .map_holder{
            #map{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }
    .graduate_list{
        > ul{
            > li{
                margin-bottom: 35px;
                article{
                    .image{
                        width: 123px;
                        height: 123px;
                        border-radius: 50%;
                        @include bg_pos;
                        background-size: cover;
                        margin-bottom: 15px;
                    }
                    div.desc_holder{
                        .desc{
                            header{
                                padding: 12px 0;
                                margin-bottom: 12px;
                                border-bottom: 1px solid #002043;
                                > div{
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                                .title{
                                    width: calc(100% - 130px);
                                    @media only screen and (max-width: 991px) {
                                        text-align: left;
                                    }
                                    h3{
                                        @include p;
                                        line-height: 26px;
                                        color: $font_color1;
                                        font-family: $special_font;
                                        font-weight: 600;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                                .date{
                                    width: 130px;
                                    font-family: $special_font;
                                    color: $font_color1;
                                    //@include h6;
                                    @include p;
                                    text-align: right;
                                    p{
                                        font-weight: 600;
                                    }
                                }
                            }
                            .default_font{
                                line-height: 25px;
                                color: $font_color2;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: 13px;
                                line-height: 18px;
                                @media only screen and (max-width: 991px) {
                                    text-align: left;
                                }
                                p{
                                    text-align: justify;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    height: 90px;
                                    @media only screen and (max-width: 991px) {
                                        display: -webkit-box;
                                        -webkit-line-clamp: 5;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                            .go_details{
                                color:$font_color2;
                                font-size: 13px;
                                line-height: 13px;
                                text-transform: uppercase;
                                border-top: 1px solid #bfbbb7;
                                padding-top: 18px;
                                margin-top: 18px;
                                p{
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.graduate_filter{
    margin-bottom: 45px;
    overflow: hidden;
    .search_box{
        margin-top: 7px;
        background:$white;
        > div{
            display: inline-block;
            vertical-align: top;
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #002043;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: #002043;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: #002043;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: #002043;
            }
            &:first-child{
                position: relative;

            }
        }
        .select2-container {
            margin-top: 0;
            &:after{
                content:'';
                position: absolute;
                top:20%;
                left: -35px;
                height: 60%;
                border-left:1px solid #d5d0c8;
            }
            .select2-selection--single{
                height: 70px;
                background:$white;
                .select2-selection__rendered{
                    line-height: 70px;
                    padding: 0 35px 0 0px;
                    @media only screen and (max-width: 991px) {
                        padding: 0 20px;
                    }
                }
                .select2-selection__arrow{
                    height: 100%;
                    width: 35px;
                    b{
                        background-position:right center;
                        margin:0;
                        left: 0;
                        @media only screen and (max-width: 991px) {
                            background-position: left center;
                        }
                    }
                }
            }
        }
    }
    .filter_input{
        width: 100%;
        height: 70px;
        background:$white;
        padding: 0 20px;
        border:none;
        color:#002043;
        font-weight: 700;
    }
    .blue_arr_btn{
        position: relative;
        padding: 0;
        text-align: center;
        height: 70px;
        width: 100%;
        margin:0;
        &.filter_submit2{
            border: 2px solid #002043;
            background-color: #ffffff;
            color:#002043;
            span{
                color:#002043;
            }
        }
        span{
            line-height: 70px;
        }
        .filter_submit{
            @include fill;
            opacity: 0;
        }
    }
}

.rules_hide {
    display: none; }

.show_all_rules_btn{
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffffff;
    font-family: "Canela Web", "Spectral", serif;
    font-size: 16px;
    line-height: 55px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: right center;
    border: 0;
    background: #002043;
    padding: 0 40px;
}

.lesson_filters{
    margin-top: -2px;
    margin-bottom: 10px;
    @media only screen and (max-width: 991px) {
        margin-top: 0;
        padding: 0 15px;
    }
    > ul{
        margin: 0 -15px;
        > li{
            padding: 0 15px;
            display: inline-block;
            vertical-align: top;
            width: 33%;
            margin-bottom: 15px;
            @media only screen and (max-width: 991px) {
                padding: 0;
                margin-bottom: 15px;
                border-bottom: 1px solid $black;
                label{
                    text-align: center;
                    width: 100%;
                }
                width: 100%;
            }
            .filter_box {
                border-bottom:1px solid #cbcbcb;
                width: 100%;
                label{
                    margin-bottom: 0;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                > ul {
                    > li{
                        line-height: 16px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 12px;
                        }
                        width: 100%;
                        &:first-child{
                            @media only screen and (max-width: 1300px) {
                                min-height: 31px;
                            }
                        }
                        &:last-child{
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
    .select2-container{
        margin-top: 0;
    }
    .select2-container {
        .select2-selection--single {
            .select2-selection__rendered{
                padding-left: 0;
                padding-top: 8px;
            }
        }
    }
    .select2-container--default {
        .select2-selection--single{
            height: 43px;
            line-height: 43px;
        }
    }
    .select2-container--default {
        .select2-selection--single {
            .select2-selection__arrow{
                height: 100%;
            }
        }
    }
}

.five_in_row_elements{
    margin: 0 -15px;
    > li{
        padding: 0 15px;
        width: 20%;
        .title{
            width: auto !important;
        }
    }
}
.form_portfolio_details{
    @media only screen and (max-width: 991px) {
        .text-right{
            text-align: left;
            label{
                padding: 0 25px;
            }
        }
    }
}

.submit_btn{
    display: inline-block;
    @include p;
    text-transform: uppercase;
    color: $white;
    font-family: $special_font;
    line-height: 55px;
    padding-left: 41px;
    padding-right: 44px;
    background: #002043;
    letter-spacing: 1px;
    position: relative;
    @media only screen and (max-width: 1500px) {
        font-size: 14px;
        padding-left: 31px;
        padding-right: 31px;
    }
    @media only screen and (max-width: 991px) {
        padding: 0;
        width: 100%;
    }
    .default_submit{
        @include fill;
        //opacity: 0;
        background:#002043;
    }
    span{
        padding-right: 92px;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("/img/more_arr2.png");
        @media only screen and (max-width: 1500px) {
            padding-right: 81px;
        }
    }
}

.rules{
    > div{
        display: inline-block;
        vertical-align: top;
        &.check_holder{
            width: 20px;
            .checkbox_image_box{
                border: 1px solid #d7d7d7;
                border-bottom: 2px solid #d7d7d7;
                width: 100%;
                background: $white;
                > div{
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    border-radius: 0;
                    border: none;
                    @include bg_pos;
                    background-size: cover;
                    background-color: transparent;
                    &.yes{
                        &.on{
                            background-color: $white;
                            background-image: url("./../img/check_icon.png");
                        }
                    }
                }
            }

        }
        &.check_desc{
            width: calc(100% - 20px);
            padding-left: 32px;
            color: #6f6f6f;
            font-size: 14px;
            line-height: 21px;
            margin-top: -5px;
            p{
                margin: 0 !important;
            }
        }
    }
}

.department_structure{
    /*padding: 100px 0 40px 0;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 10px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 50px 0 20px 0;
    }*/
    .structure_toggles{
        > ul{
            /*margin: 0 -30px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -20px;
            }*/
            > li{
                margin-bottom: 35px;
                float: none;
                display: inline-block;
                vertical-align: top;
                //width: 50%;
                //padding: 0 30px;
                //display: inline-block;
                //vertical-align: top;
                //margin-bottom: 60px;
                /*@media only screen and (max-width: 1500px) {
                    margin-bottom: 40px;
                    padding: 0 20px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }*/

            }
        }
        .structure_box{
            background: #dbd8d4;
            @include anim;
            position: relative;
            &.active{
                box-shadow: 0px 10px 20px 0px rgba(23, 25, 24, 0.1);
                background: $white;
                .structure_top{
                    color: $font_color1;
                }
                &:after{
                    left: 0;
                    width: 100%;
                }
                .structure_top{
                    background-image: url("./../img/phone_minus.png");
                    background-position: right 16px top 26px;
                }
            }
            &:after{
                content: '';
                width: 0%;
                left: 50%;
                top: 0;
                border-top: 2px solid #001f46;
                @include anim;
                position: absolute;
            }
            .structure_top{
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: right 16px top 21px;
                background-image: url("/img/phone_plus.png");
                font-size: 13px;
                color: #6f6f6f;
                @include anim;
                width: 100%;
                text-align:left;
                p{
                    font-weight: 700;
                }
                padding:16px 50px 16px 17px;
            }
            div.structure_bottom{
                padding: 0px 16px 20px 36px;
                display: none;
                margin-top: -7px;
                .desc{
                    font-size: 14px;
                    line-height: 24px;
                    color: #6f6f6f;
                    @media only screen and (max-width: 991px) {
                        padding: 0;
                    }
                    .more{
                        text-align: right;
                        a{
                            font-weight: 600;
                            letter-spacing: 2px;
                        }
                    }
                    p{
                        margin-bottom: 5px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.main_galery{
    //padding-top: 80px;
    padding-bottom: 60px;
    border-bottom: 1px solid #efefef;
    .sb1{
        .section_header2{
            margin-bottom: 7px;
        }
       &:first-child{
           border-top: none !important;
           padding-top: 0;
       }
    }
    .main_galery_list{
        //margin-bottom: 40px;
        > ul{
            margin: 0 -20px;
            > li{
                padding: 0 20px;
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                //margin-bottom: 40px;
                margin-bottom: 35px;
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                }
                &:nth-child(3n){
                    &:hover{
                        .desc{
                            background: rgba(3, 69, 66, 0.8);
                        }
                    }
                }
                &:nth-child(3n-2){
                    &:hover{
                        .desc{
                            background: rgba(137, 47, 51, 0.8);
                        }
                    }
                }
                &:nth-child(3n-1){
                    &:hover{
                        .desc{
                            background: rgba(148, 84, 28, 0.8);
                        }
                    }
                }
                .gal_box{
                    background:$black;
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 66.7%;
                    @media only screen and (max-width: 991px) {
                        padding-bottom: 62%;
                    }
                    .image{
                        @include bg_pos;
                        @include fill;
                        background-size: cover;
                        opacity: 0.8;
                    }
                    .desc{
                        position: relative;
                        z-index: 2;
                        @include h6;
                        //padding: 57px;
                        color:$white;
                        font-family: $special_font;
                        text-transform: uppercase;

                        background: rgba(0,0,0,0.65);
                        background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 59%);
                        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.65)), color-stop(59%, rgba(0,0,0,0)));
                        background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 59%);
                        background: -o-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 59%);
                        background: -ms-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 59%);
                        background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 59%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );

                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        transition: 0.3s all ease;
                        padding: 33px 40px;
                        //background: transparent;
                        font-family: $main_font;
                        @media only screen and (max-width: 1300px) {
                            padding: 15px;
                        }
                        h3{
                            margin-top: -5px;
                            font-weight: 400;
                            @include h5;
                            line-height: 30px;
                            margin-bottom: 20px;
                            text-transform: none;
                            font-weight: 400;
                            @media only screen and (max-width: 1500px) {
                                font-size: 18px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.galery_details{
    //padding: 80px 0;
    //border-bottom: 1px solid #efefef;
    /*.section_header{
        .default_font{
            color: #838383;
        }
    }*/
    .section_header2{
        margin-bottom: 7px;
    }
    .gal_col {
        .image {
            display: block;
            @include bg_pos;
            background-size: cover;
            width: 100%;
            height: 16.667vw;
            @media only screen and (max-width: 991px) {
                height: 0%;
                padding-bottom: 62%;
            }
        }
        margin-bottom: 30px;
    }
    .back{
        padding-bottom: 10px;
        //padding-top: 35px;
    }
}

.main_donwload{
    //padding-top: 80px;
    //padding-bottom: 100px;
    //border-bottom: 1px solid #efefef;
    .download_list{
        ul{
            li{
                &:hover{
                    .download_box{
                        p{
                            font-weight: 600;
                        }
                    }
                }
                .download_box{
                    padding-left: 45px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url("/img/pdf_icon.png");
                    text-transform: uppercase;
                    color: $font_color1;
                    font-size: 14px;
                    min-height: 61px;
                    line-height: 20px;
                    padding-top: 11px;
                    border-bottom: 1px solid #dfdfdf;
                    > div{
                        background-position: right center;
                        background-repeat: no-repeat;
                        background-image: url("/img/download_arr.png");
                    }
                    p{
                        font-weight: 400;
                        span{
                            font-size: 12px;
                            color: #838383;
                        }
                    }
                }
            }
        }
    }
    .download_footer{
        margin-top: 35px;
        margin-bottom: 35px;
        padding-top: 20px;
        border-top:1px solid #dfdfdf;
        @include p;
        color:#838383;
        font-family: $special_font;
        text-decoration: underline;
        letter-spacing: 2px;
        text-transform: uppercase;
        span{
            color: $black;
            font-weight:600;
        }
    }
}

.default_font {

    //overflow: hidden;

    h1, h2, h3, h4, h5, h6 {
        //font-weight: 600;
        font-family:"Canela Web", "Spectral", serif;
    }

    h1 {
        font-size:48px;
        line-height:58px;
        @media only screen and (max-width: 1500px) {
            font-size:40px;
            line-height:50px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    h2 {
        font-size:40px;
        line-height:50px;
        @media only screen and (max-width: 1500px) {
            font-size:32px;
            line-height:42px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 27px;
            line-height: 37px;
        }
    }

    h3 {
        font-size:32px;
        line-height:42px;
        @media only screen and (max-width: 1500px) {
            font-size:24px;
            line-height:34px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    h4 {
        font-size:24px;
        line-height:34px;
        @media only screen and (max-width: 1500px) {
            font-size:20px;
            line-height:30px;
        }
    }

    h5 {
        font-size:20px;
        line-height:30px;
        @media only screen and (max-width: 1500px) {
            font-size:18px;
            line-height:28px;
        }
    }

    h6 {
        font-size:16px;
        line-height:26px;
    }

    > * {
        padding-bottom:30px;
        margin-bottom:0!important;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 15px;
        }
    }

    > *:last-child {
        padding-bottom:0!important;
    }

    ul, ol {
        padding-left:20px;
        margin-left:0;
    }
    > ul{
        list-style: disc;
        > li{
            > ul{
                list-style: circle;
                > li{
                    > ul{
                        list-style: square;
                    }
                }
            }
        }
    }
    img{
        margin: 0;
    }
    em{
        font-family: 'Arial', sans-serif;
    }
}

.cadre{
    .cadre_box_rows{
        > .cadre_box{
            &:first-child{
                .section_border{
                    border: none;
                    padding-top: 0;
                    margin-top: 0;
                }
            }
        }
    }
    /*padding: 80px 0 20px 0;
    border-bottom: 1px solid #dfd5c9;
    .cadre_header{
        color:$font_color1;
        font-family: $special_font;
        h2{
            font-size: 30px;
            line-height: 48px;
            font-weight: 600;
        }
    }*/
    &.small_cadre{
        padding: 0;
        border-bottom: none;
        .cadre_box{
            padding: 0;
            border: none;
            margin-bottom: 0;
        }
        .cade_list{
            ul{
                li{
                    width: 33%;
                    float:none;
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    /*.cadre_box{
        padding: 70px 70px 70px 70px;
        border:1px solid #dfd5c9;
        margin-bottom: 60px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 1200px) {
            margin-bottom: 0px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .cadre_holder{
            > .row{
                margin: 0 -50px;
                > div{
                    padding: 0 50px;
                }
            }
        }
    }*/
    .cade_list{
        &.main_cadre_list{
            > ul{
                padding-top: 9px;
                > li{
                    margin-bottom: 17px;
                    .title{
                        width: 120px;
                        margin-right: 0 !important;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &:last-child{
                        margin-bottom: 35px;
                    }
                }
            }
            article{
                //padding-left: 17px;
                &.col_box{
                    header{
                        > div{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 62px;
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                margin-bottom: 15px;
                                width: 100%;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        > ul{
            > li{
                //margin-bottom: 35px;
                margin-bottom: 0 !important;
                .default_font{
                    font-size: 18px;
                    line-height: 36px;
                    color: $font_color1;
                }
                &:hover{
                    .desc{
                        .title{
                            color: $font_color1;
                            p{
                                font-weight: 600;
                            }
                        }
                        .who{
                            h3{
                                font-weight: 600;
                                color: $font_color1;
                            }
                        }
                        .spec{
                            color: $font_color1;
                            em{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        //kadra
        //article{
        //    padding-left: 10px;
        //    border-left: 3px solid $font_color1;
        //}
        //.desc{
        //    font-size: 14px;
        //    line-height: 14px;
        //    .title{
        //        color:$black;
        //    }
        //    .who{
        //        color: $black;
        //        font-family: $special_font;
        //        h3{
        //            @include h6;
        //            line-height: 36px;
        //            font-weight: 400;
        //        }
        //    }
        //    .spec{
        //        color: #6f6f6f;
        //    }
        //}
        /*> ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 50%;
                margin-bottom: 60px;
                padding-right: 15px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 20px;
                    width: 100%;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }

            }
        }*/
    }
}

.search_holder{
    display: none;
    &.s_show{
        opacity: 1;
        visibility: visible;
    }
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    @include vetical;
    z-index: 100;
    height: 100%;
    @include anim;
    @media only screen and (max-width: 991px) {
        display: none;
    }
    > div{
        width: 100%;
        .search{
            text-align: left;
            white-space: nowrap;
            @include anim;
            padding-right: 50px;
            &.open{
                background:#828180;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                overflow: hidden;
                padding-right: 0;
                border: 1px solid #828180;
                ul{
                    > li{
                        &.search_box{
                            width: 400px;
                            opacity: 1;
                            visibility: visible;
                        }
                        &.search_btn{
                            background-image: url("./../img/s_close.png");
                        }
                    }
                }
            }
            ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    &.search_box{
                        opacity: 0;
                        visibility: hidden;
                        @include anim;
                        width: 0;
                        input{
                            //outline: none;
                            height: 42px;
                            border: none;
                            //outline: none;
                            &.s_input{
                                width: calc(100% - 100px);
                                border:none;
                                background: $white;
                                @include p;
                                padding: 0 15px;
                            }
                            &.s_submit{
                                font-size: 14px;
                                color:$white;
                                background:#828180;
                                width: 100px;
                                text-transform: uppercase;
                                font-family: $special_font;
                                font-weight: 600;
                                letter-spacing: 1px;
                            }
                        }
                    }
                    &.search_btn{
                        cursor: pointer;
                        width: 42px;
                        height: 0;
                        padding-bottom: 42px;
                        border-radius: 50%;
                        @include bg_pos;
                        background-image: url("./../img/search_icon.png");
                        @include anim;
                    }
                }
            }
        }
    }
}

.cadre_details{
    /*padding: 80px 0;
    border-bottom: 1px solid #efefef;
    > .row{
        margin: 0 -45px;
        > div{
            padding: 0 45px;
        }
    }*/
    .cadre_details_top{
        margin-bottom: 35px;
        .flexed{
            height: 567px;
            @media only screen and (max-width: 991px) {
                height: auto;
            }
        }
    }
    .cadre_details_bottom{
        margin-bottom: 30px;
    }
    .image{
        width: 100%;
        height: 100%;
        @include bg_pos;
        background-size: cover;
        margin-bottom: 65px;
        @media only screen and (max-width: 991px) {
            height: 0;
            padding-bottom: 90%;
            margin-bottom: 0;
        }
    }
    .short_bio{
        background: #001f46;
        width: 100%;
        height: 100%;
        padding:23px 35px;
        //color: #89919c;
        color: $white;
        font-size: 16px;
        line-height: 35px;

        overflow-y: scroll;
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #001f46;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.2);;
        }
        > div{
            border-bottom: 1px solid rgba(255,255,255,0.2);
            padding-bottom: 18px;
            margin-bottom: 18px;
            &.about_short_info{
                font-family: $special_font;
            }
            &.desc{
                font-size: 14px;
                line-height: 30px;
                p{
                    display: -webkit-box;
                    -webkit-line-clamp: 20;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
        }
        h3{
            font-weight: 600;
            font-family: $special_font;
            color: $white;
            @include h5;
            line-height: 34px;
        }
        span{
            font-weight: 600;
            //font-family: $special_font;
            font-family: $main_font;
            color: $white;
            @include p;
            line-height: 35px;
        }
    }
}

.error_page{
    padding-top: 7%;
    padding-bottom: 3%;
    .code{
        @include h1;
        font-family: $special_font;
        color: $font_color1;
    }
    .info{
        color: $font_color2;
        @include h3;
    }
}

@media only screen and (max-width: 991px) {
    .side_left, side_right{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .text-right{
        text-align: center;
    }
}

.movie_arrs{
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
    }
}

.faculty_structure{
    /*padding: 100px 0;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        .section_header{
            text-align: center;
        }
    }*/
    .section_header2{
        margin-bottom: 9px;
    }
    .faculty_structure_list{
        > ul{
           > li{
                &:last-child{
                    margin-bottom: 65px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 0;
                    }
                }
               /*@media only screen and (max-width: 1500px) {
                    margin-bottom: 40px;
               }
               &:last-child{
                   margin-bottom: 0;
               }*/
                .faculty_structure_box{
                    /*padding: 70px 70px 10px 70px;
                    border: 1px solid #dfd5c9;
                    @media only screen and (max-width: 1500px) {
                        padding: 50px 50px 10px 50px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 30px 15px 10px 15px;
                    }
                    &.faculty_department{
                        border:1px solid #f0f0f0;
                        background:#f0f0f0;
                    }*/
                    .faculty_structure_inner_list{
                        > ul{
                           // margin: 0 -30px;
                            > li{
                                margin-bottom: 30px;
                                //padding: 0 30px;
                                //display: inline-block;
                                //vertical-align: top;
                                //width: 50%;
                                //margin-bottom: 60px;
                                /*
                                @media only screen and (max-width: 1500px) {
                                    margin-bottom: 40px;
                                }
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                }
                                @media only screen and (max-width: 991px) {
                                    margin-bottom: 20px;
                                }
                                */
                            }
                        }
                    }
                    .faculty_box{
                        header{
                            font-family: $special_font;
                            color: $font_color1;
                            padding-left: 14px;
                            border-left: 3px solid $font_color1;
                            margin-bottom: 7px;
                            h3{
                                @include h6;
                                font-weight: 600;
                            }
                        }
                        .desc{
                            font-size: 14px;
                            line-height: 30px;
                            color: #6f6f6f;
                        }
                        .contact_list{
                            padding-top: 7px;
                            ul{
                                li{
                                    background-repeat: no-repeat;
                                    background-position: left top 8px;
                                    padding-left: 33px;
                                    margin-bottom: 10px;
                                    color: #6f6f6f;
                                    font-size: 14px;
                                    line-height: 30px;
                                    min-height: 25px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    &.adres{
                                        background-image: url("/img/map_box_icon1.png");
                                    }
                                    &.phone{
                                        background-image: url("/img/map_box_icon2.png");
                                    }
                                    &.mail{
                                        background-image: url("/img/map_box_icon3.png");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.class_schedules{
    .section_header2{
        padding: 0 20px;
        *{
            font-family: $main_font;
        }
    }
    /*
    padding: 100px 0;
    border-bottom: 1px solid #efefef;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        .section_header{
            text-align: center;
        }
    }
    > ul{
        > li{
            margin-bottom: 60px;
            &:last-child{
                margin-bottom: 0;
            }
            @media only screen and (max-width: 1500px) {
                margin-bottom: 40px;
            }
            .class_schedules_box{
                padding: 70px 70px 10px 70px;
                border: 1px solid #dfd5c9;
                @media only screen and (max-width: 1500px) {
                    padding: 50px 50px 10px 50px;
                }
                @media only screen and (max-width: 991px) {
                    padding: 30px 15px 10px 15px;
                }
                .class_schedules_list{
                    > ul{
                        margin: 0 -30px;
                        > li{
                            padding: 0 30px;
                            width: 50%;
                            margin-bottom: 60px;
                            display: inline-block;
                            vertical-align: top;
                            @media only screen and (max-width: 1500px) {
                                margin-bottom: 40px;
                            }
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 20px;
                            }
                            .desc{
                                font-size: 14px;
                                line-height: 14px;
                                header{
                                    padding-left: 14px;
                                    border-left: 3px solid #892f33;
                                    margin-bottom: 15px;
                                }
                                .title{
                                    color:#6f6f6f;
                                    text-transform: uppercase;
                                    p{
                                        font-weight: 600;
                                    }
                                }
                                .who{
                                    color: $font_color1;
                                    font-family: $special_font;
                                    h3{
                                        @include h6;
                                        line-height: 36px;
                                        font-weight: 600;
                                    }
                                }
                                .spec{
                                    color: #6f6f6f;
                                    p{
                                        font-weight: 600;
                                    }
                                    span{
                                        text-transform: uppercase;
                                        color: $font_color1;
                                        font-weight: 600;
                                    }
                                }
                            }
                            .plan{
                                padding-top: 30px;
                                ul{
                                    li{
                                        color: $font_color1;
                                        padding-bottom: 20px;
                                        margin-bottom: 20px;
                                        border-bottom: 1px solid #dfdfdf;
                                        font-size: 14px;
                                        @media only screen and (max-width: 991px) {
                                            text-align: center;
                                            padding-bottom: 10px;
                                            margin-bottom: 10px;
                                        }
                                        .more_btn1{
                                            @media only screen and (max-width: 991px) {
                                                margin-top: 10px;
                                            }
                                            color: #6f6f6f;
                                            font-size: 14px;
                                            font-family: $main_font;
                                            background-image: url("./../img/red_arr.png");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    */
    .filter_box {
        > ul{
            > li{
                &:last-child{
                    padding-left: 0;
                    margin-left: 20px;
                    width: 110px;
                    .chosen-container-single {
                        .chosen-single {
                            span {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .class_schedules_list{
        li{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        button{
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
        h2{
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 15px !important;
            line-height: 27px !important;
        }
        > div {
            > ul {
                > li {
                    width: 100%;

                    .study_level {
                        > .section_header2 {
                            background: #dbd8d4;

                            &.first_level {
                                margin-top: -17px;
                            }

                            h2 {
                                line-height: 53px;
                            }
                        }
                    }

                    .department_list {
                        h2{
                            font-size: 17px !important;
                            line-height: 27px !important;
                        }
                        > ul {
                            > li {
                                margin-bottom: 17px;

                                &:last-child {
                                    margin-bottom: 35px;
                                }

                                &.active {
                                    .department_list_top {
                                        background-image: url("./../img/plan_minus.png");
                                    }
                                }

                                .department_list_top {
                                    border-bottom: 2px solid #002043;
                                    cursor: pointer;
                                    padding-right: 30px;
                                    background-repeat: no-repeat;
                                    background-position: right center;
                                    background-image: url("./../img/plan_plus.png");
                                    padding: 0 20px;
                                    width: 100%;
                                    text-align: left;
                                    h2,span {
                                        text-transform: uppercase;
                                        font-family: $main_font;
                                        letter-spacing: 2px;
                                        font-weight: 400;
                                        //@include h5;
                                        //line-height: 53px;
                                        font-size: 15px !important;
                                        line-height: 27px !important;
                                        @media only screen and (max-width: 1300px) {
                                            font-size: 17px;
                                            line-height: 27px;
                                        }
                                    }
                                }

                                .department_list_bottom {
                                    display: none;

                                    .direction {
                                        > ul {
                                            border-bottom: 2px solid #002043;
                                        }
                                    }

                                    .direction, .spec {
                                        > ul {
                                            display: inline-block;

                                            > li {
                                                padding: 12.5px 0px;
                                                @include p;
                                                display: inline-block;
                                                color: $black;
                                                font-family: $main_font;
                                                text-transform: uppercase;
                                                margin-right: 7px;

                                                &:last-child {
                                                    margin-right: 0;
                                                }

                                                p {
                                                    font-weight: 600;
                                                }

                                                h2 {
                                                    letter-spacing: 2px;
                                                    @include h5;

                                                    font-weight: 600;
                                                    @media only screen and (max-width: 1300px) {
                                                        font-size: 17px;
                                                        line-height: 27px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .download_list {
                                        margin-top: -14px;

                                        ul {
                                            li {
                                                &:hover {
                                                    .download_box {
                                                        p {
                                                            font-weight: 600;
                                                        }
                                                    }
                                                }

                                                .download_box {
                                                    padding-left: 45px;
                                                    background-repeat: no-repeat;
                                                    background-position: left center;
                                                    background-image: url("/img/pdf_icon.png");
                                                    text-transform: uppercase;
                                                    color: $font_color1;
                                                    font-size: 14px;
                                                    line-height: 30px;
                                                    letter-spacing: 2px;
                                                    padding-top: 15px;
                                                    padding-bottom: 15px;
                                                    border-bottom: 1px solid #dfdfdf;

                                                    > div {
                                                        background-position: right center;
                                                        background-repeat: no-repeat;
                                                        background-image: url("/img/download_arr.png");
                                                    }

                                                    p {
                                                        font-weight: 400;

                                                        span {
                                                            font-size: 12px;
                                                            color: #838383;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}




.dropdown-toggle {
    //outline:none!important;
    &.active {
        color:#000!important;
    }
}

.dropdown-menu {
    &.active {
        opacity:1!important;
        visibility: initial!important;
    }
}

.flexed{
    display: flex;
    @media only screen and (max-width: 991px) {
        display: block;
        text-align: center;
    }
    > div{
        float: none;
        &.col-md-2{
            > div{
                position: relative;
                height: 100%;
            }
        }
        .left_more{
            @include fill;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -ms-flex-align: flex-end;
            -webkit-align-items: flex-end;
            -webkit-box-align: flex-end;

            align-items: flex-end;
            @media only screen and (max-width: 991px) {
                display: block;
                position: relative;
            }
            > div{
                width: 100%;
                //height: 82px;
                //height: 85px;
                height: 60px;
                //@include vetical;
                border-top: 1px solid #cbcbcb;
                padding-top: 17px;
                @media only screen and (max-width: 991px) {
                    display: block;
                    height: auto;
                }
                a{
                    //margin-bottom: 30px;
                    margin-top: -6px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

.row{
    &.grid_pd{
        margin:0 -17.5px;
        > div, > li{
            padding: 0 17.5px;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
    }
}

.side_navigation{
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    .section_header2 {
        h2 {
            a {
                font-size: 15px !important;
                font-family: $main_font;
                font-weight: 700;
                color: #010101;

                &:hover {
                    text-decoration: underline!important;
                }
            }
        }
    }

    .on > a, li > div:hover > a {
        text-decoration: underline!important;
    }

    > ul{
        > li{
           // border-bottom:1px solid #cbcbcb;
            border-bottom:1px solid #cbcbcb;
            > div{
                > a{
                    font-family: $main_font;
                    font-weight: 700;
                    color: #010101;
                    letter-spacing: 2px;
                    font-size:13px;
                    line-height: 1.5;
                    //line-height: 13px;
                    display: block;
                    margin-top: -3px;
                    padding: 17px 0 17px 0;
                    position: relative;
                    @include anim;
                    @media only screen and (max-width: 1700px) {
                        padding: 32px 0 32.6px 0;
                    }
                    @media only screen and (max-width: 1500px) {
                        font-size: 11.5px;
                        line-height: 11.5px;
                        padding: 30px 0 30.3px 0;
                    }
                    &:hover{
                        color: $font_color1;
                        &:after{
                            width: 90%;
                            border-bottom:1px solid $font_color1;
                        }
                    }
                    //&:after{
                    //    content: '';
                    //    width: 100%;
                    //    position: absolute;
                    //    bottom: 0;
                    //    left: 0;
                    //    border-bottom:1px solid #cbcbcb;
                    //    @include anim;
                    //}
                }
            }

            &.lion > ul {
                display:block;
            }

            > ul {
                display:none;
                padding-bottom:17px;

                > li {

                    > div {
                        padding-bottom:5px;
                        > a {
                            font-weight: 300;
                            color: #010101;
                            font-family: $main_font;
                        }
                    }

                    &.lion > ul {
                        display:block;
                    }

                    > ul {
                        display:none;
                        padding:5px 0 15px 20px;

                        > li {

                            a {
                                font-weight: 300;
                                color:#010101;
                                font-family: $main_font;
                            }
                        }
                    }
                }
            }
        }
    }
}

.pagi{
    margin-top: -10px;
    margin-bottom: 30px;
    text-align: right;
    ul{
        li{
            display: inline-block;
            vertical-align: middle;
            &.arr_left1, &.arr_left2{
                cursor:pointer;
                margin-right: 20px;
            }
            &.sites{
                padding: 0 18px;
                > ul{
                    > li{
                        margin-right: 19px;
                        a{
                            cursor:pointer;
                            color: #5b5b5b;
                            font-size:11px;
                            line-height: 11px;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    > li.active{
                        a{
                            font-weight: bold;
                        }
                    }
                }
            }
            &.arr_right2, &.arr_right1{
                cursor:pointer;
                margin-left: 20px;
            }
        }
    }
}

.under_top_margin{
    margin-top: 35px;
}

.news_details, .only_text_page, .events_details, .universal{
    .events_details_top{
        margin-bottom: 35px;
        .events_details_top_holder{
            height: 386px;
            @media only screen and (max-width: 991px) {
                height: auto;
            }
            &.flexed{
                margin-left: -17.5px;
                margin-right: -17.5px;
                > div{
                    padding-left: 17.5px;
                    padding-right: 17.5px;
                }
            }
        }
    }
    .news_details_top{
        //margin-bottom: 36px;
        margin-bottom: 22px;
        .news_details_top_holder{
            //display: flex;
            height: 554px;
            overflow: hidden;
            @media only screen and (max-width: 991px) {
                height: auto;
                .image_holder{
                    height:0;
                    padding-bottom: 62%;
                    .image{
                        @include fill;
                    }
                }
            }
            &.flexed{
                margin-left: -17.5px;
                margin-right: -17.5px;
                > div{
                    padding-left: 17.5px;
                    padding-right: 17.5px;
                }
            }
            > div{
                &.image_holder{
                    //width: 70%;
                    position: relative;
                    height: 100%;
                    .image{
                        display: block;
                        width: 100%;
                        height:100%;
                        //padding-bottom: 100%;
                        @include bg_pos;
                        background-size: cover;
                    }
                }
                &.last_holder{
                    //width: 40.577%;
                    .same_category{
                        height: 100%;
                        //padding-left: 60px;
                        //padding-left: 35px;
                        .section_header2{
                            margin-bottom: 10px;
                        }
                        ul{
                            height: calc(100% - 30px);
                            li{
                                height: calc(100% / 3);
                                &:hover{
                                    .desc{
                                        header{
                                            .title{
                                                h3{
                                                    font-weight: 600;
                                                }
                                            }
                                            .date{
                                                p{
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                                        .default_font{
                                            color: $font_color1;
                                        }
                                        .go_details{
                                            color: $font_color1;
                                            p{
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }
                                .desc{
                                    header{
                                        padding: 12px 0;
                                        margin-bottom: 12px;
                                        border-bottom: 1px solid #002043;
                                        > div{
                                            display: inline-block;
                                            vertical-align: middle;
                                        }
                                        .title{
                                            width: calc(100% - 130px);
                                            @media only screen and (max-width: 991px) {
                                                text-align: left;
                                            }
                                            h3{
                                                @include p;
                                                line-height: 26px;
                                                color: $font_color1;
                                                font-family: $special_font;
                                                font-weight: 400;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 1;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        .date{
                                            width: 130px;
                                            font-family: $special_font;
                                            color: $font_color1;
                                            //@include h6;
                                            @include p;
                                            text-align: right;
                                            p{
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                    .default_font{
                                        line-height: 25px;
                                        color: $font_color2;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-size: 13px;
                                        line-height: 18px;
                                        p{
                                            text-align: justify;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 3;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            height: 54px;
                                            @media only screen and (max-width: 991px) {
                                                display: -webkit-box;
                                                -webkit-line-clamp: 3;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                    }
                                    .go_details{
                                        color:$font_color2;
                                        font-size: 13px;
                                        line-height: 13px;
                                        text-transform: uppercase;
                                        border-top: 1px solid #bfbbb7;
                                        padding-top: 18px;
                                        margin-top: 18px;
                                        p{
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .news_details_bottom{
        .details{
            //margin-bottom: 55px;
            &.for_only_text{
                margin-top: -11px;
               // padding-top: 12px;
               // margin-bottom: 20px;
                .details_header{
                    margin-bottom: 20px;
                }
            }
            .details_header{
                //margin-top: -11px;
                margin-bottom: 46px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
                .date{
                    @include p;
                    color: #838383;
                    margin-bottom: 9px;

                    > div {
                        display: inline-block;
                        vertical-align: top;
                        padding-right:20px;

                        &.social {
                            position:relative;
                            bottom:8px;
                        }
                    }
                }
                h2{
                    //margin-top: -23px;
                    font-size: 48px;
                    line-height: 58px;
                    font-family: $special_font;
                    color: $black;
                    font-weight: 600;
                    @media only screen and (max-width: 1500px) {
                        font-size: 36px;
                        line-height: 46px;
                    }
                    @media only screen and (max-width: 1200px) {
                        font-size: 30px;
                        line-height: 40px;
                    }
                }
                .header_desc{
                   padding-top: 9px;
                   // margin-top: -14px;
                    @include h6;
                    line-height: 44px;
                    color: $black;
                }
            }
            .desc_box{
                margin-bottom: 20px;
                .default_font{
                    color: $black;
                }
            }
            @include p;
            line-height: 35px;
            .default_font{
                color: $black;
            }
            p{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .details_gallery{
            .small_gallery{
                .section_header2{
                    margin-bottom: 17px;
                }
                ul{
                    margin-top: -9px;
                    li{
                        margin-bottom: 35px;
                        .image{
                            display: block;
                            width: 100%;
                            height: 0;
                            padding-bottom: 62%;
                            @include bg_pos;
                            background-size: cover;
                        }
                    }
                }
            }
        }
        .download_list{
            margin-top: -5px;
            ul{
                li{
                  margin-bottom: 35px;
                    &:hover{
                        .download_box{
                            p{
                                font-weight: 600;
                            }
                        }
                    }
                    .download_box{
                        padding-left: 45px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url("/img/pdf_icon.png");
                        text-transform: uppercase;
                        color: $font_color1;
                        font-size: 14px;
                        line-height: 30px;
                        letter-spacing: 2px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #dfdfdf;
                        > div{
                            background-position: right center;
                            background-repeat: no-repeat;
                            background-image: url("/img/download_arr.png");
                        }
                        p{
                            font-weight: 400;
                            span{
                                font-size: 12px;
                                color: #838383;
                            }
                        }
                    }
                }
            }
        }
        .tags{
            padding-bottom: 35px;
            > div{
                display: inline-block;
                vertical-align: top;
                line-height: 30px;
                &:first-child{
                    color: $font_color1;
                    margin-right: 16px;
                    letter-spacing: 2px;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 16px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            display: inline-block;
                            color: #838383;
                            //background: $white;
                            padding: 0 12px;
                            line-height: 31px;
                            @include anim;
                            &:hover{
                                color: $white;
                                background: $font_color1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.return_btn{
    display: inline-block;
    font-size:16px;
    color:$black;
    padding-left: 75px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    background-repeat: no-repeat;
    background-position: left center;
    background-image:url("./../img/return_arrow123.png");
    position: relative;
    top: -6px;
    margin-bottom: 5px;
}

.page2{
    background:#edeae6;
    .pagi_box{
        margin-top: 0px;
        .pagi{
            margin-top: -51px;
        }
    }
}

.library_top{
    height: 460px;
    //height: 560px;
    position: relative;
    width: 100%;
    background: $black;
    @media only screen and (max-width: 991px) {
        height: 460px;
    }
    .image{
        @include fill;
        @include bg_pos;
        background-size: cover;
        opacity: 0.5;
    }
    .top_content{
        width: 100%;
        position: absolute;
        left: 0;
        //bottom: 97px;
        bottom: 37px;
        @media only screen and (max-width: 991px) {
            bottom:35px;
        }
        .search_side, .btns_side{
            display: inline-block;
            vertical-align: bottom;
            width: 50%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        .subpage_header{
            font-family: $special_font;
            margin-bottom: -10px;
            padding-bottom: 35px;
            @media only screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 0px;
            }
            h1{
                @include h2;
                color: $white;
                //font-weight: 600;
                text-transform: uppercase;
                @media only screen and (max-width: 991px) {
                    font-size: 20px;
                }
            }
        }
        .library_search{
            div.i_holder{
                display: inline-block;
                vertical-align: top;
                position: relative;
                &.for_text{
                    width: calc(100% -  178px);
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
                &.for_submit{
                    width: 178px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #6f6f6f;
                }
                ::-moz-placeholder { /* Firefox 19+ */
                    color: #6f6f6f;
                }
                :-ms-input-placeholder { /* IE 10+ */
                    color: #6f6f6f;
                }
                :-moz-placeholder { /* Firefox 18- */
                    color: #6f6f6f;
                }
                input{
                    border: none;
                    //outline: none;
                    height: 60px;
                    width: 100%;
                    @media only screen and (max-width: 991px) {
                        height: 50px;
                    }
                    &.search_input{
                        padding: 0 30px;
                        font-size: 16px;
                        color: #6f6f6f;
                        font-weight: 700;
                        @media only screen and (max-width: 991px) {
                            padding: 0 15px;
                            font-size: 10px;
                            text-align:center;
                        }
                    }
                    &.search_submit{
                        background-color: #00579a;
                        color: $white;
                        font-weight: 700;
                        text-transform: uppercase;
                        background-image: url("./../img/library_search_icon.png");
                        background-position: left 42px center;
                        background-repeat: no-repeat;
                        padding-left: 77px;
                        font-size: 14px;
                        text-align: left;
                        @include anim;
                        letter-spacing: 2px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                            font-size: 10px;
                            padding-left: 0px;
                            background-image:none;
                        }
                        &:hover{
                            background-color: $font_color1;
                        }
                    }
                }
            }
        }
        .btns_side{
            text-align: right;
           // margin-bottom: -21px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                padding: 15px 15px 0 15px;
            }
        }
        .side_btn{
            display: inline-block;
            padding: 0 45px;
            color:$white;
            @include anim;
            //line-height: 90px;
            //font-size: 17px;
            font-size: 14px;
            line-height: 60px;
            text-transform: uppercase;
            width: 468px;
            font-weight: 700;
            text-align: left;
            letter-spacing: 2px;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding: 0 15px !important;
                text-align: center;
                font-size: 10px;
                line-height: 50px;
            }
            &.side_btn1{
                background:#00579a;
                @media only screen and (max-width: 991px) {
                    background:#002043;
                }

                &:hover{
                    background-color: $font_color1;
                }
            }
            &.side_btn2{
                background:#0578c2;
                &:hover{
                    background-color: $font_color1;
                }
            }
        }
    }
}

.library_home{
    .main_news{
        .news_list{
            &.library_news{
                margin-top: -7px;
                ul {
                    li {
                        margin-bottom: 25px;
                        div.desc_holder {
                            .desc {
                                header{
                                    padding-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.library_open{
    border:1px solid #d2c7bb;
    margin-top: -1px;
    margin-bottom: 35px;
    padding-bottom: 17px;
    header{
        padding-left: 34px;
        border-left: 3px solid #00579a;
        font-family: $special_font;
        color: $black;
        margin: 33px 0;
        margin-left: -1.5px;
        @media only screen and (max-width: 991px) {
            text-align: center;
            padding-left: 0;
        }
        h3{
            font-weight: 600;
            @include h6;
        }
    }
    .open_box{
        margin-left: -1.5px;
        padding-left: 34px;
        padding-right: 34px;
        //margin-bottom: 33px;
        margin-bottom: 25px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        &:last-child{
            margin-bottom: 33px;
        }
        h4{
            color: $black;
            font-weight: 600;
            @include p;
            margin-bottom: 14px;
        }
        ul{
            > li{
                color:#5b5b5b;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}

.base_list{

    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    ul{
        li{
            display: inline-block;
            vertical-align: middle;
            //width: calc(100% / 6);
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            @include anim;
            margin-bottom: 35px;
            margin-right: 7%;
            @media only screen and (max-width: 1700px) {
                margin-right: 6%;
            }
            @media only screen and (max-width: 1500px) {
                margin-right: 4%;
            }
            @media only screen and (max-width: 1300px) {
                margin-right: 3%;
            }
            @media only screen and (max-width: 1100px) {
                margin-right: 4%;
            }
            &:last-child{
                margin-right: 0;
            }
            @media only screen and (max-width: 991px) {
                width: 50%;
                margin-right: 0;
            }
            img{
                //max-width: 50%;
                max-height: 40px;
                width: auto;
                @media only screen and (max-width: 1500px) {

                }
                @media only screen and (max-width: 1100px) {
                    max-height: 30px;
                }
            }
            &:hover{
                -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                filter: grayscale(0%);
            }
        }
    }
}

.library_footer_general{
    height: 160px;
    @include vetical;
    font-size: 14px;
    letter-spacing: 2px;
    color: $white;
    text-transform: uppercase;
    @include bg_pos;
    background-size: cover;
    background-image: url("./../img/library_footer_bg.jpg");
    @media only screen and (max-width: 991px) {
        font-size: 11px;
        text-align: center;
        height: 100px;
    }
    .row{
        > div{
            display: inline-block;
            vertical-align: middle;
            float: none;
            .footer_logos_holder{
                display: inline-block;
                background: $white;
                padding: 0 15px;
                text-align: center;
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 57px;
                        &:last-child{
                            margin-right: 0;
                        }
                        img{
                            max-height: 70px;
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-top: 15px;
                }
            }
        }
    }
    p{
        font-weight: 600;
    }
}

.library_faq{
    .library_faq_toggles{
        .library_faq_list{
            margin-top: -1px;
            &.hybrid{
                padding-top: 30px;
            .faq_top p{
                font-weight: 400;
                font-family: "HK Grotesk";
            }
            }
            > ul{
              > li{
                border: 1px solid #dfd5c9;
                  margin-bottom: 17px;
                  &.active{
                      .faq_top{
                          background-image: url("./../img/faq_toggle_arr2.png");
                          background-color: #00579a;
                          color: $white;
                      }
                  }
                  &:last-child{
                      margin-bottom: 35px;
                  }
                  .faq_top{
                      @include anim;
                      background-color: $white;
                      padding: 12px 40px 12px 16px;
                      @include h6;
                      font-family: $special_font;
                      color: $black;
                      background-position: right 16px top 22px;
                      background-repeat: no-repeat;
                      cursor: pointer;
                      background-image: url("./../img/faq_toggle_arr1.png");
                      width: 100%;
                      text-align: left;
                      p, span{
                          font-weight: 600;
                      }
                  }
                  .faq_bottom{
                      display: none;
                      background: $white;
                      padding:6px 16px;
                      color: #5b5b5b;
                      font-size: 14px;
                      line-height: 30px;
                      &.default_font{
                          h1,h2,h3,h4,h5,h6,p,ul,ol,a{
                              padding-bottom: 10px;
                              &:last-child{
                                  padding-bottom: 0;
                              }
                          }
                      }
                  }
              }
            }
        }
    }
}

.library_offer_form{
    .section_header2{
        text-transform: none;
    }
}

.add_form{
    padding-top: 6px;
    select{
        width: 100%;
    }
    label{
        width: 100%;
        font-size: 14px;
        color: #5b5b5b;
        font-weight: 400;
        margin-bottom: 10px;
        sup{
            font-size:100%;
            top:0;
            font-weight: 600;
            color:#ed2a2a;
        }
    }
    input{
        //outline: none;
        &.library_input{
            border: 1px solid #dfd5c9;
            background: $white;
            padding: 0 16px;
            font-size: 14px;
            color: #5b5b5b;
            height: 45px;
            width: 100%;
            //margin-bottom: 35px;
            margin-bottom: 15px;
        }
    }
    .library_textarea{
        //outline: none;
        resize: none;
        border: 1px solid #dfd5c9;
        background: $white;
        padding: 12px 16px;
        font-size: 14px;
        color: #5b5b5b;
        min-height: 200px;
        width: 100%;
        //margin-bottom: 35px;
        margin-bottom: 15px;
    }
    rules{
        margin-bottom: 35px;
    }
    .submit_btn{
        margin: 35px 0;
    }
}

.details_gallery{
    .small_gallery{
        .section_header2{
            margin-bottom: 17px;
        }
        ul{
            margin-top: -9px;
            li{
                margin-bottom: 35px;
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 62%;
                    @include bg_pos;
                    background-size: cover;
                    display: block;
                }
            }
        }
    }
}
.download_list{
    margin-top: -5px;

    li{
        &:hover{
            .download_box{
                p{
                    font-weight: 600;
                }
            }
        }
        .download_box{
            padding-left: 45px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("/img/pdf_icon.png");
            text-transform: uppercase;
            color: $font_color1;
            font-size: 14px;
            min-height: 61px;
            line-height: 20px;
            padding-top: 11px;
            border-bottom: 1px solid #dfdfdf;
            > div{
                background-position: right center;
                background-repeat: no-repeat;
                background-image: url("/img/download_arr.png");
            }
            p{
                font-weight: 400;
                span{
                    font-size: 12px;
                    color: #838383;
                }
            }
        }
    }
}
.tags{
    padding-bottom: 35px;
    > div{
        display: inline-block;
        vertical-align: top;
        line-height: 30px;
        &:first-child{
            color: $font_color1;
            margin-right: 16px;
            letter-spacing: 2px;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 16px;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    display: inline-block;
                    color: #838383;
                    //background: $white;
                    padding: 0 12px;
                    line-height: 31px;
                    @include anim;
                    &:hover{
                        color: $white;
                        background: $font_color1;
                    }
                }
            }
        }
    }
}

.search_summary{
    .summary_search_holder{
        > input{
            //outline: none;
            display: inline-block;
            vertical-align: top;
            border: none;
            height: 50px;
            &:first-child{
                padding: 0 30px;
                font-size: 15px;
                width: calc(100% - 300px);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            &:last-child{
                width: 300px;
                color: $white;
                font-family: $special_font;
                font-weight: 600;
                font-size: 18px;
                background: $font_color1;
                text-transform: uppercase;
                letter-spacing: 2px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
    .search_summary_list{
        ul{


            li{
                width:100%;
                border-bottom: 1px solid #cbcbcb;
                &:last-child{
                    border-bottom: none;
                }
                a{
                    @include h6;
                    line-height: 31px;
                    font-family: $special_font;
                    color: $font_color1;
                    font-weight: 400;
                    padding: 10.5px 0 26px;
                    display: inline-block;
                    &:hover{
                        font-weight:600;
                    }
                }

                .search_model_human_tag {
                    color: red;
                    font-weight: bolder;
                    padding: 10px 0 0 0;
                }
            }
        }
    }
}

.main_popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.8);
    .paggination_box{
        display: none !important;
    }
    .vertical_align{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;

        height: 100%;
        width: 100%;

        > div{
            width: 100%;
        }
    }
    .popup_pd{
        padding: 0 14px;
        @media only screen and (max-width: 1500px) {
            padding: 0;
        }
    }
    .popup_box{
        padding: 35px;
        background: #eeeae7;
        position: relative;
        .n_popup_close{
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            top: -25px;
            right: -25px;
            background-color: $font_color1;
            @include bg_pos;
            cursor: pointer;
            background-image: url("./../img/p_close.png");
            background-size: 20px;
            @media only screen and (max-width: 991px) {
                top: 0;
                right: 0;
                border-radius: 0;
            }
        }
        .section_header2{
            margin-bottom: 20px;
            h2{
                color:$black;
            }
        }
        .desc{
            margin-bottom: 30px;
            overflow-y: scroll;
            /* width */
            &::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #eeeae7;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $font_color1;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
            > div{
                max-height: 514px;
                @media only screen and (max-width: 1700px) {
                    max-height: 414px;
                }
                @media only screen and (max-width: 1500px) {
                    max-height: 360px;
                }
            }
            h1,h2,h3,h4,h5,h6{
                color: $font_color1;
                font-family: $special_font;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            p{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            img{
                margin-bottom: 30px;
            }
            font-size:13px;
            line-height: 18px;
            color: $font_color2;
        }
        .sign_up_btn{
            display:inline-block;
            font-family: $special_font;
            background:$font_color1;
            padding:16px 50px;
            @media only screen and (max-width: 991px) {
                padding: 16px;
                width: 100%;
                text-align:center;
            }
            span{
                @include p;
                color:$white;
                text-transform:uppercase;
                letter-spacing:2px;
                padding-right: 73px;
                background-position:right center;
                background-repeat: no-repeat;
                background-image:url("./../img/more_arr2.png");
                @media only screen and (max-width: 991px) {
                    background-image:none;
                    padding-right: 0;
                    text-align:center;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
    }
}

.form_title{
    .section_header2{
        h2{
            font-size:24px;
        }
    }
}

.page, .page2{
    &.page_contrast{
        .lp_details {
            .fast_nav_box {
                .nav_content{
                    border: 1px solid yellow;
                    background-color: #000000;
                    a{
                        color: red !important;
                        *{
                            color: inherit !important;
                        }
                    }
                }
            }
        }
        .fast_nav_box {
            background: black !important;
        }
        .see_more .news_list ul li div.desc_holder .desc .default_font{
            color: yellow !important;
        }
        .news{
            .arrow{
                background-color: rgba(255,255,255,0.5) !important;
                span{
                    color: black !important;
                }
            }
        }
        td,th{
            background: $black !important;
        }

        .cadre .cade_list > ul > li .default_font{
            color: yellow;
        }
        .events_list > ul > li .event_box div.desc div.event_box_bottom .short_desc .default_font{
            color: yellow !important;
            p{
                color: yellow !important;
            }
        }
        .n_popup_close{
            span{
                color: white !important;
            }
        }
        .popup_box{
            background: black !important;
            border: 1px solid white;
        }
        select{
            color: black !important;
        }
        button{
            background-color: transparent;
            span{
                color: black !important;
            }
            &.structure_top{
                span{
                    color: yellow !important;
                }
            }
        }
        .calendar{
            button{
                background-color: transparent;
                span{
                    color: white !important;
                }
            }
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered{
            color: yellow !important;
        }
        color:yellow;
        background:$black;
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: yellow;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: yellow;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: yellow;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: yellow;
        }
        .page_content{
            background:$black;
        }
        .page_header {
            .header_bottom {
                div.menu_box {
                    nav.menu {
                        ul{
                            li{
                                &:hover{
                                    background: yellow !important;
                                }
                                ul{
                                    li{
                                        border-bottom:1px solid yellow !important;
                                       ul{
                                           li{
                                               &:hover{
                                                   div{
                                                       background: yellow !important;
                                                   }
                                               }
                                           }
                                       }
                                    }
                                }
                            }
                        }
                        > ul {
                            > li {
                                &:last-child{
                                    > ul{
                                        border-left: none;
                                        border-right: 4px solid yellow;
                                    }
                                }
                                > ul{
                                    border-left: 4px solid yellow;
                                    > li{
                                        background:$black;
                                        > ul{
                                            > li{
                                                background:$black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        section, .page_footer{
            background: transparent !important;
        }
        h1,h2,h3,h4,h5,h6,p,li{
            color:yellow !important;
        }
        a{
            color: red !important;
        }
        .section_border{
            border-top:2px solid yellow;
        }
        .flexed {
            > div {
                .left_more {
                    > div{
                        border-top: 1px solid yellow;
                    }
                }
            }
        }
        .event_calendar {
            .events_holder {
                > div.events_list {
                    ul {
                        li{
                            border-top: 1px solid yellow;
                        }
                    }
                }
            }
        }
        .mycalendar2_datepicker {
            .calendar_content {
                background: $black;
                border: 1px solid yellow;
            }
        }
        .side_navigation {
            > ul {
                > li{
                    border-bottom: 1px solid yellow;
                }
            }
        }
        .tilts {
            .tilts_list {
            > ul{
            > li {
                article{
                    background: transparent;
                    border: 1px solid yellow;
                }
            }
            }
            }
        }
        .events_list {
            > ul {
                > li {
                    .event_box {
                        div.desc{
                            &:after{
                                background: $black;
                            }
                            .event_box_top{
                                .date{
                                    background: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
        .news {
            .carousel_content {
                ul {
                    li {
                        div.desc_holder {
                            .desc {
                                header{
                                    border-bottom: 1px solid yellow;
                                }
                                .default_font{
                                    color: yellow;
                                }
                                .go_details{
                                    border-top: 1px solid yellow;
                                }
                            }
                        }
                    }
                }
            }
        }
        .download_box{
            border-bottom: 1px solid yellow !important;
        }
        .filter_box {
            .chosen-container-single {
                .chosen-single {
                    background:$black;
                }
                .chosen-drop{
                    background:$black;
                    border:1px solid yellow;
                }
                .chosen-results {
                    li{
                        color: red;
                        background: $black !important;
                    }
                }
                .chosen-search {
                    input{
                        color:red;
                        border:1px solid yellow;
                    }
                }
            }
        }
        .field_radio {
            .field_radio_box {
                .radio_image_box{
                    background:yellow;
                    border:1px solid yellow;
                    .radio_box{
                        &.on{
                            background: red;
                        }
                    }
                }
            }
        }
        .field_checkbox {
            .field_checkbox_box {
                .checkbox_image_box{
                    background: yellow;
                    border: 1px solid yellow;
                    > div{
                        &.on{
                            &.yes{
                                background: red;
                            }
                        }
                    }
                }
            }
        }
        .myform2_dropzone_box{
            border: 1px solid yellow;
            .dropzone_box{
                > div{
                    color: red;
                }
            }
        }
        .default_form {
            input[type=text], .default_input{
                background: $black;
                color: yellow;
                border: 1px solid yellow;
            }
            textarea, .default_textarea{
                border: 1px solid yellow;
                background: $black;
                color: yellow;
            }
        }
        .submit_btn{
            color: red;
            border: 1px solid yellow;
            background: $black;
        }
        .form_holder {
            input[type=submit]{
                border: 1px solid yellow;
                background-color: $black;
                color: red;
            }
        }
        .page_footer {
            .newsletter {
                .nl_holder{
                    &.n_border{
                        border-top:2px solid yellow;
                    }
                }
            }
        }
        .contact {
            .contact_data_boxes {
                > ul {
                    > li {
                        .contact_data_box{
                            background: $black;
                            border: 1px solid yellow;
                            header{
                                border-left: 3px solid yellow;
                            }
                        }
                    }
                }
            }
            .phone_toggles {
                .phone_box{
                    background: $black;
                    border: 1px solid yellow;
                    div.phone_bottom {
                        .mails {
                            ul{
                                border-left:2px solid yellow;
                                li{
                                    .mail_box {
                                        span{
                                            color:red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page_footer {
            .newsletter {
                .newsletter_holder {
                    input.n_input{
                        color: yellow;
                        background: $black;
                        border: 1px solid yellow;
                    }
                    input.n_submit{
                        color: red;
                        background: $black;
                        border: 1px solid yellow;
                    }
                }
            }
        }
        .contact {
            .map_desc_box{
                background: $black;
            }
        }
        .default_form {
            .default_label{
                color: yellow;
            }
        }
        .department_structure {
            .structure_toggles {
                .structure_box {
                    background: $black;
                    border: 1px solid yellow;
                }
            }
        }
        .page_header {
            .header_top{
                &:before{
                    border-top: 1px solid yellow;
                }
            }
        }
        .page_header {
            .header_bottom{
                border-bottom: 1px solid yellow;
            }
        }
        .slider {
            .slider_nav {
                div.slide_choice {
                    > ul {
                        > li{
                            background: yellow;
                            &.on{
                                background: red;
                            }
                        }
                    }
                }
            }
        }
        .main_donwload {
            .download_list {
                ul {
                    li {
                        .download_box {
                            p {
                                span{
                                    color: yellow;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mycalendar2_yearslider {
            .base_bg_color{
                background: $black;
            }
        }
        .filter_box{
            border-bottom:1px solid yellow;
        }
        .mycalendar2_yearslider {
            .slide {
                .month{
                    &.active{
                        border-bottom: 2px solid red;
                    }
                }
                .day {
                    &.active{
                        border: 1px solid red;
                        background: $black;
                        color:yellow !important
                    }
                    .name{
                        color: yellow;
                    }
                }
            }
        }
        .main_news {
            .news_list {
                ul {
                    li {
                        div.desc_holder {
                            .desc {
                                header{
                                    border-bottom: 1px solid yellow;
                                }
                                .default_font{
                                    color: yellow;
                                }
                                .go_details{
                                    border-top: 1px solid yellow;
                                }
                            }
                        }
                    }
                }
            }
        }
        .news_details{
            .news_details_top{
                .news_details_top_holder {
                    > div.last_holder {
                        .same_category {
                            ul {
                                li {
                                    .desc {
                                        header {
                                            border-bottom:1px solid yellow;
                                        }
                                        .default_font{
                                            color: yellow;
                                        }
                                        .go_details{
                                            border-top:1px solid yellow;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cadre {
            .cade_list {
                article{
                    border-left: 3px solid yellow;
                }
                .desc {
                    .spec{
                        color: yellow;
                    }
                }
            }
        }
        .event_calendar {
            .events_holder {
                > div.arrs {
                    > div.arr_top {
                        background-image:url("./../img/e_arr_top_c.png");
                    }
                    > div.arr_bottom {
                        background-image:url("./../img/e_arr_bottom_c.png");
                    }
                }
            }
        }
        .main_donwload {
            .download_list {
                ul {
                    li {
                        .download_box{
                            background-image: url("./../img/pdf_icon_c.png");
                            > div{
                                background-image: url("./../img/download_arr_c.png");
                            }
                        }
                    }
                }
            }
        }
        .main_donwload {
            .download_footer {
                border-top: 1px solid yellow;
            }
        }
        .page_header {
            .header_top {
                > ul {
                    > li.header_top_right {
                        > div.right_box1 {
                            > div.langs {
                                .dropdown {
                                    .btn{
                                        color: yellow !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page_header {
            .header_top {
                > ul {
                    > li.header_top_right {
                        > div.right_box1 {
                            > div.langs {
                                .dropdown {
                                    .btn {
                                        .caret{
                                            background-image: url("./../img/langs_arr_c.png");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page_header {
            .header_top {
                > ul {
                    > li.header_top_right {
                        > div.right_box1 {
                            > div.langs {
                                .dropdown {
                                    .dropdown-menu {
                                        border: 2px solid yellow;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dropdown-menu{
            background: $black;
        }
        .page_header {
            .header_top {
                > ul {
                    > li.header_top_right {
                        > div.right_box1 {
                            > div.langs {
                                .dropdown {
                                    .dropdown-menu {
                                        > li {
                                            > a{
                                                &:hover{
                                                    background: yellow;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .mycalendar2_datepicker {
            .calendar_content {
                header.months_change {
                    .left {
                        svg{
                            fill:yellow !important;
                        }
                    }
                }
            }
        }
        .mycalendar2_datepicker {
            .calendar_content {
                header.months_change {
                    .right {
                        svg{
                            fill:yellow !important;
                        }
                    }
                }
            }
        }
        .mycalendar2_datepicker {
            .calendar_content {
                header.months_change {
                    .title {
                        color: yellow;
                    }
                }
            }
        }
        .events_list {
            > ul {
                > li {
                    .event_box {
                        div.desc {
                            .event_box_top {
                                .info {
                                    ul {
                                        li.start{
                                            background-image: url("./../img/clock_icon_c.png");
                                        }
                                        li.spots{
                                            background-image: url("./../img/person_icon_c.png");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .events_list {
            > ul {
                > li {
                    .event_box {
                        div.desc {
                            .event_box_top {
                                .arr_box{
                                    background-image: url("./../img/event_arr1_c.png");
                                }
                            }
                        }
                    }
                }
            }
        }
        .filter_box {
            .chosen-container-single {
                .chosen-single {
                    div {
                        b{
                            background-image: url("./../img/select_arr_c.png");
                        }
                    }
                }
            }
        }
        .class_schedules {
            .class_schedules_list {
                > div {
                    > ul {
                        > li {
                            .study_level {
                                > .section_header2{
                                    background: $black;
                                    border-bottom: 1px solid yellow;
                                }
                            }
                            .department_list {
                                > ul {
                                    > li {
                                        .department_list_top {
                                            background-image: url("./../img/plan_plus_c.png");
                                            border-bottom: 2px solid yellow;
                                        }
                                        &.active{
                                            .department_list_top{
                                                background-image: url("./../img/plan_minus_c.png");
                                            }
                                        }
                                        .department_list_bottom {
                                            .direction {
                                                > ul{
                                                    border-bottom: 1px solid yellow;
                                                }
                                            }
                                            .download_list {
                                                ul {
                                                    li {
                                                        .download_box {
                                                            background-image:url("./../img/pdf_icon_c.png");
                                                            > div{
                                                                background-image: url("./../img/download_arr_c.png");
                                                            }
                                                            p{
                                                                span{
                                                                    color: yellow;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .download_list {
            li {
                .download_box{
                    background-image: url("./../img/pdf_icon_c.png");
                    > div{
                        background-image: url("./../img/download_arr_c.png");
                    }
                }
            }
        }
        .slider {
            > ul {
                > li {
                    .slider_content {
                        header.slider_header {
                            ul {
                                li.date{
                                    border-right: 1px solid yellow;
                                }
                            }
                            .desc {
                                p{
                                    background-image: url("./../img/more_arr1_c.png");
                                }
                            }
                        }
                    }
                }
            }
        }
        .show_more_btn{
            background-image:url("./../img/show_more_arr_c.png");
        }
        .department_structure {
            .structure_toggles {
                .structure_box {
                    div.structure_top {
                        background-image:url("./../img/plan_plus_c.png");
                    }
                }
            }
        }
        .department_structure {
            .structure_toggles {
                > ul {
                    > li.active {
                        .structure_box{
                            .structure_top{
                                background-image:url("./../img/plan_minus_c.png");
                            }
                        }
                    }
                }
            }
        }
        .contact {
            .phone_toggles {
                .phone_box {
                    div.phone_top {
                        background-image:url("./../img/plan_plus_c.png");
                    }
                }
            }
        }
        .contact {
            .phone_toggles {
                > ul {
                    > li.active {
                        .phone_box {
                            .phone_top{
                                background-image:url("./../img/plan_minus_c.png");
                            }
                        }
                    }
                }
            }
        }
        .contact {
            .contact_data_boxes {
                > ul {
                    > li {
                        .contact_data_box {
                            .contact_list {
                                ul {
                                    li.phone {
                                        background-image: url("./../img/map_box_icon2_c.png");
                                    }
                                    li.mail {
                                        background-image: url("./../img/map_box_icon3_c.png");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .contact {
            .phone_toggles {
                .phone_box {
                    div.phone_bottom {
                        .contact_list {
                            ul {
                                li.phone{
                                    background-image: url("./../img/map_box_icon2_c.png");
                                }
                                li.mail {
                                    background-image: url("./../img/map_box_icon3_c.png");
                                }
                            }
                        }
                    }
                }
            }
        }
        .contact {
            .phone_toggles {
                .phone_box {
                    div.phone_bottom {
                        .mails {
                            ul {
                                li {
                                    .mail_box {
                                        span{
                                            background-image: url("./../img/small_mail_icon_c.png");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .contact {
            .map_desc_box {
                .contact_list {
                    ul {
                        li.adres{
                            background-image: url("./../img/map_box_icon1_c.png");
                        }
                        li.phone{
                            background-image: url("./../img/map_box_icon2_c.png");
                        }
                        li.mail {
                            background-image: url("./../img/map_box_icon3_c.png");
                        }
                    }
                }
            }
        }
        .search_summary {
            .summary_search_holder {
                > input{
                    &:first-child{
                        background:black;
                        border:1px solid yellow;
                    }
                    &:last-child{
                        background:black;
                        border:1px solid yellow;
                    }
                }
            }
        }

        .page_header{
            &.library_header {
                .header_border{
                    border-top:1px solid yellow;
                }
            }
        }

        .library_open{
            border:1px solid yellow;
            header{
                border-left: 3px solid red;
            }
        }
        .library_top {
            .top_content {
                .side_btn{
                    background:$black !important;
                    color:yellow !important;
                }
                .library_search {
                    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: yellow !important;
                    }
                    ::-moz-placeholder { /* Firefox 19+ */
                        color: yellow !important;
                    }
                    :-ms-input-placeholder { /* IE 10+ */
                        color: yellow !important;
                    }
                    :-moz-placeholder { /* Firefox 18- */
                        color: yellow !important;
                    }
                    div.i_holder {
                        border:1px solid yellow;
                        input.search_input{
                            background:$black;
                            color: yellow;
                        }
                        input.search_submit{
                            background: $black;
                            color:red;
                            padding-left: 0;
                            text-align:center;
                        }
                    }
                }
            }
        }
        .library_faq {
            .library_faq_toggles {
                .library_faq_list {
                    > ul {
                        > li {
                            &.active{
                                .faq_top{
                                    background-image:url("./../img/faq_toggle_arr2_c.png");
                                }
                            }
                            border: 1px solid yellow;
                            .faq_top{
                                background-color:$black;
                                background-image:url("./../img/faq_toggle_arr1_c.png");
                            }
                            .faq_bottom{
                                background-color:$black;
                            }
                        }
                    }
                }
            }
        }
        .submit_btn {
            .default_submit{
                background:$black;
            }
        }
        .class_schedules .class_schedules_list > div > ul > li .department_list > ul > li .department_list_top h2, .class_schedules .class_schedules_list > div > ul > li .department_list > ul > li .department_list_top span{
            color: yellow !important;
        }
        .lp_details .white_box, .fast_nav_box{
            background: $black !important;
        }
        .library_footer_general .row > div .footer_logos_holder{
            span{
                color: $black !important;
            }
        }
        .library_faq .library_faq_toggles .library_faq_list > ul > li .faq_top span {
            color: yellow !important;
        }
        .add_form label{
            color: yellow !important;
        }
    }
}

.on_active{
    a{
        color:#0c1c73 !important;
    }
}

.logo_box{
    a{
        @media only screen and (max-width: 991px) {
            padding-bottom: 0 !important;
        }
    }
   svg{
       @media only screen and (max-width: 991px) {
           //display:none;
       }
   }
}

.no_anim_logo{
    display:none;
    @media only screen and (max-width: 991px) {
        display:block;
    }
}

.download_box{
    > div{
        p{
            padding-right: 25px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.mycalendar2_yearslider {
    @media only screen and (max-width: 1500px) {
        .slide {
            .month {
                padding: 15px 5px;
                text-align: center;
            }
        }
    }
    @media only screen and (max-width: 1300px) {
        .slide{
            &.days{
                overflow-x: scroll;
                /* width */
                &::-webkit-scrollbar {
                    height:5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #1a243c;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .slide{
            &.months{
                margin-top: 12px;
                overflow-x: scroll;
                /* width */
                &::-webkit-scrollbar {
                    height:5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #1a243c;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}

.lp_slider{
    height: 100vh;
    width: 100%;
    position: relative;
    > ul{
        @include fill;
        > li{
            @include fill;
            background: $black;
            .image{
                @include fill;
                @include bg_pos;
                background-size: cover;
                opacity: 0.4;
            }
            .slider_content{
                @include fill;
                @include vetical;
                header.slider_header{
                    text-align: center;
                    padding-top: 13%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    ul{
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                        }
                        &.from_to_date{
                            > li.date{
                                border-right: 0;
                                padding-right: 0;
                                margin-right: 0;
                                &:after{
                                    content: '-';
                                    margin: 0 10px;
                                }
                                &:last-child{
                                    &:after{
                                        display: none;
                                    }
                                }
                            }
                        }
                        li{
                            display: inline-block;
                            vertical-align: middle;
                            color: #a9a8a7;
                            @media only screen and (max-width: 991px) {

                            }
                            &.date{
                                font-family: $special_font;
                                font-size: 1.5vw;
                                line-height: 1.5vw;

                                padding-right: 21px;
                                margin-right: 21px;
                                border-right: 1px solid rgba(255,255,255,0.2);
                                @media only screen and (max-width: 991px) {
                                    @include h6;
                                    line-height: 18px;
                                    color: $white;
                                    text-align: left;
                                }
                            }
                            &.date2{
                                // @include p
                                font-size: 0.85vw;
                                @media only screen and (max-width: 991px) {
                                    @include p;
                                    color: $white;
                                }
                            }
                        }
                    }
                    .desc{
                        color: $white;
                        font-size: 36px;
                        line-height: 46px;
                        margin-bottom: 2.7vw;
                        @media only screen and (max-width: 1920px) {
                            @include h6;

                            line-height: 35px;
                        }
                        @media only screen and (max-width: 991px) {
                            @include p;
                        }
                        p{
                            color: $white;
                            display: inline-block;
                            @media only screen and (max-width: 991px) {
                                padding-right: 0;
                                padding-bottom: 60px;
                                background-position: right bottom;
                                text-align: left;
                                display: none;
                            }
                        }
                    }
                    .sign_up_btn{
                        background: #002043;
                        padding: 18px 47px;
                        display: inline-block;
                        span{
                            font-family: $special_font;
                            color: $white;
                            @include p;
                            letter-spacing: 2px;
                            padding-right: 95px;
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-image: url("./../img/sign_arr.png");
                        }
                    }

                    h1, h2{
                        //margin: 3vw 0;
                        color: $white;
                        font-size: 4vw;
                        font-family: $special_font;
                        margin: 0;
                        @media only screen and (max-width: 1920px) {
                            //margin: 2.7vw 0 2vw 0;
                            color: $white;
                            font-size: 6.8vw;
                            letter-spacing: 10px;
                        }
                        @media only screen and (max-width: 1700px) {
                            margin: 1.7vw 0;
                        }
                        @media only screen and (max-width: 1500px) {
                            margin: 1.5vw;
                        }
                        @media only screen and (max-width: 1300px) {
                            margin: 1.3vw 0;
                        }
                        @media only screen and (max-width: 991px) {
                            @include h3;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.lp_content{
    background: #edeae6;
}

.lp_details{
    margin-bottom: 35px;
    .small_gallery{

    }
    .default_font{
        &.description{
            margin-top: -10px;
        }
        &.description2{
            margin-top: -3px;
        }
    }
    .white_box{
        background: $white;
        color: $black;
        padding: 21px 33px;
        margin-bottom: 35px;
        @include p;
        line-height: 36px;
    }
    .fast_nav_box{
        background: #002043;
        > div{
            padding-left: 38px;
            padding-right: 38px;
        }
        .nav_header{
            color:$white;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 2px;
            line-height: 80px;
            border-bottom:1px solid #183554;
        }
        .nav_content{
            padding-top: 30px;
            padding-bottom: 30px;
            ul{
                padding-left: 18px;
                list-style: disc;
                li{
                    color: #cebaa3;
                    a{
                        color:#aaaaaa;
                        font-size:14px;
                        line-height: 38px;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        @include anim;
                        &:hover{
                            color:$white;
                        }
                    }
                }
            }
        }
    }
}

.lp_program{
    margin-bottom: 35px;
    .program_content{
        .program_list{
            margin-bottom: 25px;
            .p_list_box, li{
                padding-left: 18px;
                border-left: 2px solid #002043;
                font-size:18px;
                line-height: 30px;
            }
        }
        .desc{
            margin-bottom: 25px;
        }
        .images{
            > ul{
                > li{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    .image{
                        width: 100%;
                        height: 0;
                        padding-bottom: 68%;
                        @include bg_pos;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

.lp_gallery{
    padding-bottom: 35px;
    .lp_gal_list{
        ul{
            li{
                margin-bottom: 35px;
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 66.7%;
                    @include bg_pos;
                    background-size: cover;
                }
            }
        }
    }
    .desc{
        .default_font{
            h3,h4,h5,h6{
                font-family: $main_font;
            }
        }
        .desc_top{
            margin-bottom: 40px;
        }
        .desc_bottom{
            .default_font{
                > *{
                    padding-bottom: 10px;
                }
            }
        }
    }
}

.lp_contact{
    padding-bottom: 35px;
    .rules{
        padding-top: 19px;
    }
    .form_holder input[type=submit]{
        width: calc(100% - 3px);
        text-align: left;
    }
    .holder2{
        margin-bottom: 30px;
        .submit_btn{
            width: 100%;
            text-align: center;
        }
        .field_checkbox {
            .field_checkbox_box {
                padding: 0;

                div.field_checkbox_input{
                    padding: 0;
                    display: block;
                    .checkbox_image_box{
                        height: 10px;
                        > div{
                            //padding-bottom: 10px !important;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    //.field_checkbox {
    //    .field_checkbox_box {
    //        padding: 0;
    //
    //        > div.field_checkbox_input{
    //            padding: 0;
    //            display: block;
    //            .checkbox_image_box{
    //                > div{
    //                    width: 100%;
    //                }
    //            }
    //        }
    //    }
    //}
    .checkbox_image_box{
        border: 1px solid #d7d7d7;
        border-bottom: 2px solid #d7d7d7;
        width: 25px;
        background: $white;
        height: 21px;
        cursor: pointer;
        > div{
            height: 0;
            //padding-bottom: 10px !important;
            border-radius: 0;
            border: none;
            @include bg_pos;
            background-size: cover;
            background-color: transparent;
            &.yes{
                &.on{
                    background-color: $white;
                    background-image: url("./../img/check_icon.png");
                }
            }
        }
    }
    .flex_map{
        display: flex;
        height: 413px;
        width: calc(100% + 35px);
        > div{
            width: 100%;
        }
        div{
            &.ticket_side, &.map_side{
                height: 100%;
            }
            &.map_side{
                position: relative;
                width: 100%;
                iframe{
                    @include fill;
                    border: none;
                }
            }
            &.ticket_side{
                background: #01162e;
                > div{
                    padding-left: 40px;
                    padding-right: 40px;
                }
                div.ticket_header{
                    color:$white;
                    text-transform: uppercase;
                    font-size: 13px;
                    letter-spacing: 2px;
                    line-height: 80px;
                    border-bottom:1px solid #183554;
                }
                .ticket_content{
                    padding-top: 35px;
                    padding-bottom: 35px;
                    color:#aaaaaa;
                    font-size: 14px;
                    h3{
                        margin-bottom: 35px;
                        @include h6;
                        font-family: $special_font;
                        color:$white;
                    }
                    .date{
                        margin-bottom: 15px;
                    }
                    ul{
                        margin-bottom: 35px;
                        @media only screen and (max-width: 991px) {
                            text-align: left;
                        }
                        li{
                            font-size: 13px;
                            line-height: 30px;
                            padding-left: 20px;
                            background-repeat: no-repeat;
                            background-position: left center;
                            &.hour{
                                background-image: url('./../img/ticket_icon1.png');
                            }
                            &.spots{
                                background-image: url('./../img/ticket_icon2.png');
                            }
                        }
                    }
                    .buy_btn{
                        font-family: $special_font;
                        background: #edeae6;
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        color: $font_color1;
                        @include p;
                        line-height: 55px;
                        font-weight: 600;
                        letter-spacing: 2px;
                    }
                }
            }
        }
    }
}

.public_procurement{
    .news_list{
        margin-top: -12px;
        ul{
            position: relative;
            li{
                white-space: normal;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                //margin-bottom: 15px;
                padding-bottom: 35px;
                border-bottom: 1px solid #002043;
                &:hover{
                    article{
                        .desc_holder{
                            .default_font{
                                font-weight: 600;
                                p{
                                    font-weight: inherit;
                                }
                            }
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                div.desc_holder{
                    .desc{
                        header{
                            padding: 12px 0;
                            //margin-bottom: 12px;
                            > div{
                                display: inline-block;
                                vertical-align: middle;
                            }
                            .title{
                                width: calc(100% - 130px);
                                @media only screen and (max-width: 991px) {
                                    text-align: left;
                                }
                                h3{
                                    @include p;
                                    line-height: 26px;
                                    color: $font_color1;
                                    font-family: $special_font;
                                    font-weight: 600;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            .date{
                                width: 130px;
                                font-family: $special_font;
                                color: $font_color1;
                                //@include h6;
                                @include p;
                                text-align: right;
                                p{
                                    font-weight: 600;
                                }
                            }
                        }
                        .default_font{
                            line-height: 25px;
                            color: $font_color2;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 13px;
                            line-height: 18px;
                            p{
                                text-align: justify;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                height: 90px;
                                @media only screen and (max-width: 991px) {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .go_details{
                            color:$font_color2;
                            font-size: 13px;
                            line-height: 13px;
                            text-transform: uppercase;
                            //padding-top: 18px;
                            margin-top: 18px;
                            p{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contact{
    .bank_numbers{
        .library_open_hours{
            padding-bottom: 15px;
            margin: 0 -17.5px;
            > div{
                display: inline-block;
                vertical-align: top;
                width: 33%;
                padding:0 17.5px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                ul{
                    padding-top: 15px;
                    li{
                        margin-bottom: 15px;
                        .text-right{
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

.skiplinks{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    @media only screen and (max-width: 991px) {
        display: none !important;
    }
    ul{
        li{
            a{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                white-space: nowrap;
                z-index: -1;
                &:focus{
                    opacity: 1;
                    z-index: 9999;
                }
            }
        }
    }
    a{
        background: rgba(0,0,0,0.8);
        color: $white;
        font-size: 16px;
        display: inline-block;
        padding: 5px 15px;
    }
}

.search_summary{
    .search_errors{
        padding: 30px 0;
    }
}

.side_navigation{
    @media only screen and (max-width: 991px) {
        position: relative;
        .section_header2{
            text-align: left;
            padding-right: 50px;
        }
        div.side_rwd_btn{
            width: 30px;
            position: absolute;
            top: 6px;
            right: 0;
            > div{
                height: 3px;
                margin-bottom: 3px;
                width: 100%;
                background: $black;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        > ul{
            display: none;
        }
    }
}



.page3{
    overflow: hidden;
}

.baner_frame {
    overflow: hidden;
    position:relative;

    ul {
        position:relative;
        margin:0;
        padding:0;
        white-space: nowrap;
        li {
            display:inline-block;
            list-style-type: none;

            div.image_bg {
                background-size:cover;
                background-position:center;
                width:100%;

                &.movie_box {
                    position:relative;
                    iframe {
                        max-width:1000000px!important;
                        max-height:1000000px!important;
                        width:100%!important;
                        height:100%!important;
                    }
                }
            }
        }
    }

    .arr_left {
        width:60px;
        background-color:#fff;
        position:absolute;
        top:0;
        left:0;
        height:100%;
        cursor:pointer;

        background:rgba(255,255,255,0.7) url('/img/left.svg') center center no-repeat;
        background-size:50px auto;
    }

    .arr_right {
        width:60px;
        background-color:#fff;
        position:absolute;
        top:0;
        right:0;
        height:100%;
        cursor:pointer;

        background:rgba(255,255,255,0.7)url('/img/right.svg') center center no-repeat;
        background-size:50px auto;
    }
}

.default_font{
    &.span_row{
        span{
            display: inline-block;
            margin-right: 40px;
            padding-bottom: 0;
            &:first-child{
                width: 150px;
                margin-right: 0;
                @media only screen and (max-width: 991px) {
                    width: auto;
                    margin-right: 20px;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.lesson_plan{
    .lesson_filters{
        margin-top: -2px;
        margin-bottom: 10px;
        @media only screen and (max-width: 991px) {
            margin-top: 0;
            padding: 0 15px;
            .chosen-container .chosen-drop{
                clip-path: none;
                position: relative;
                display: none;
            }
            .chosen-container.chosen-with-drop .chosen-drop{
                display: block;
            }
        }
        > ul{
            margin: 0 -15px;
            > li{
                padding: 0 15px;
                display: inline-block;
                vertical-align: top;
                width: 33%;
                margin-bottom: 15px;
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    margin-bottom: 15px;
                    border-bottom: 1px solid $black;
                    label{
                        text-align: center;
                        width: 100%;
                    }
                    width: 100%;
                }
                .filter_box {
                    border-bottom:1px solid #cbcbcb;
                    width: 100%;
                    label{
                        margin-bottom: 0;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    > ul {
                        > li{
                            line-height: 16px;
                            @media only screen and (max-width: 1500px) {
                                font-size: 12px;
                            }
                            width: 100%;
                            &:first-child{
                                @media only screen and (max-width: 1300px) {
                                    min-height: 31px;
                                }
                            }
                            &:last-child{
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .lasseon_tables{
        padding-bottom: 35px;
        @media only screen and (max-width: 991px) {
            overflow-x: scroll;
            padding-bottom: 15px;
            margin-bottom: 20px;
            &::-webkit-scrollbar {
                width: 5px;
                height: 3px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: $white;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $font_color1;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
        .lesson_table{
            border-collapse: collapse;
            width: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            @media only screen and (max-width: 1500px) {
                font-size: 10px;
                line-height: 14px;
            }
            @media only screen and (max-width: 991px) {
                width: 991px;
            }
            thead{
                border: 1px solid #002043;
                th{
                    text-align:center;
                    border-right: 1px solid #54677d;
                    &:last-child{
                        border-right: none;
                    }
                    &:nth-child(odd){
                        background:#002043;
                    }
                    &:nth-child(even){
                        background:#032a55;
                    }
                    p{

                        color:$white;
                        padding: 8px 12px;
                        @media only screen and (max-width: 1300px) {
                            padding: 8px 5px;
                        }
                    }
                }
            }
            tbody{
                border: 1px solid #dfdfdf;
                tr{
                    border-bottom: 1px solid #dfdfdf;
                    &:nth-child(odd){
                        background:#f8f8f8;
                    }
                    &:nth-child(even){
                        background:$white;
                    }
                    td{
                        text-align: center;
                        border-right: 1px solid #dfdfdf;
                        &:last-child{
                            border: none;
                        }
                        p{
                            color:$black;
                            padding: 13px 12px;
                            @media only screen and (max-width: 1300px) {
                                padding: 13px 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.library_download{
    .download_sections{
        margin-top: -10px;
        .download_list{
            > ul{
                > li{
                    margin-bottom: 35px;
                    &:hover{
                        .download_box{
                            border-bottom: 1px solid $black;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    .header_bottom{
        padding-top: 56px !important;
        .menu_box{
            margin-top: -35px;
        }
        .header_top{
            display: block;
            white-space: normal;
            margin-left: 0;
            width: auto;
            text-align: left;
            padding: 0 15px;
            margin-top: 0;
            padding-top: 15px;
            &:before{
                display: none;
            }
            > div{
                > div{
                    width: 100%;
                    &.header_top_left {
                        .fast_links {
                            ul {
                                display: block;
                                &.fast_btns{
                                    padding-left: 0;
                                }
                                li{
                                    width: 100%;
                                    margin-right: 0;
                                    .search_btn1{
                                        display: none;
                                    }
                                    div{
                                        a{
                                            padding-right: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.header_top_right{
                        padding-left: 0;
                        margin-top: -84px;
                        margin-left: 30px;
                        .right_box1, .right_box3{
                            display: none;
                        }
                        .right_box2{
                            margin-right: 0;
                            a{
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
.library_header{

    @media only screen and (max-width: 991px) {
        .rwd_top ul > li{
            margin-right: 30px;
        }
    }
}
.rwd_top{
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
        .mobile_contact{
            a{
                margin: 0 !important;
            }
            img{
                margin: 0 !important;
                display: block !important;
            }
        }

    }
    position: absolute;
    top: 33px;
    left: 0;
    width: 100%;
    text-align: right;
    ul{
        > li{
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            &:last-child{
                margin-right: 0 !important;
            }
            .search_btn{
                margin-right: 33px;
            }
        }
    }
    .search_popup_box_holder{
        display: none;
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 9;
        .search_popup_box{
            @include fill;
            @include vetical;
            background: rgba(0,0,0,0.8);
            > div{
                padding: 0 15px;
                width: 100%;
            }
            .rwd_sb{
                background: #edeae6;
                padding:20px 15px 15px 15px;
                .text-right{
                    text-align: right;
                    .rwd_sb_close{
                        margin-top: -10px;
                    }
                }
                .rwd_sb_header{
                    text-align: left;
                    font-size: 20px;
                    line-height: 28px;
                    color: $font_color1;
                    font-family: $special_font;
                    margin-bottom: 10px;
                }
                form{
                    border: 1px solid #002043;
                }
                .i1, .i2{
                    width: 100%;
                    height: 44px;
                    padding: 0 15px;
                    border: none;
                }
                .i1{
                    text-align: center;
                }
                .i2{
                    background: #002043;
                    color: $white;
                    text-transform: uppercase;
                    font-family: $special_font;
                }
            }
        }
    }
}

.ue_mobile{
    display: none;
    text-align:right;
    padding-top: 15px;
    @media only screen and (max-width: 991px) {
        display: block;
    }
    img{
        position: relative;
        z-index: 5;
    }
}

.header_bottom{
    .menu_open{
        position: absolute;
        top:81px;
        right: 15px;
        display: none;
        @media only screen and (max-width: 991px) {
            display: block;
        }
    }
}

.captcha_holder{
    padding-top: 30px;
}

.bank_numbers_list{
    @media only screen and (max-width: 991px) {
        margin-bottom: 40px;
    }
    > li{
        display: inline-block;
        vertical-align: top;
        float:none;
    }
}

.contact{
    .mail, .mail_box{
        a{
            color: #002043;
        }
    }
}
.rwd_langs_holder{
    .langs{
        margin-right: 10px;
    }
}

.mobile_hide{
    @media only screen and (max-width: 991px) {
        display: none !important;
    }
}

.mobile_show{
    display: none;
    @media only screen and (max-width: 991px) {
        display: inline-block;
        vetical-align:middle;
    }
}

.mobile_footer{
    > div{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.mobile_show2{
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
    }
}

.langs{
    margin-right: 0;
    @media only screen and (max-width: 1200px) {
        margin-right: 0;
    }
    .dropdown{
        &.open{
            .dropdown-menu{
                top: 50%;
                opacity: 1;
                visibility: visible;
            }
        }
        .btn{
            padding: 0;
            width: 35px;
            border: none;
            background: transparent;
            color: $white;
            font-size: 13px;
            font-weight: 700;
            position: relative;
            z-index: 1111;
            .caret{
                margin-left: 6px;
                border:none;
                width: 6px;
                height: 4px;
                @include bg_pos;
                background-image: url("/img/langs_arr.png");
            }
        }
        .dropdown-menu{
            top: 0%;
            padding: 20px 0 5px 0;
            min-width: 0;
            width: 35px;
            text-align: left;
            text-transform: uppercase;
            border: 2px solid $white;
            display: block;
            visibility: hidden;
            opacity: 0;
            @include anim;
            border-radius: 0;
            > li{
                width: 100%;
                > a{
                    padding: 0;
                    font-size:13px;
                    @include anim;
                    position: relative;
                    color: $black;
                    font-weight:700;
                    &:hover{
                        background: $font_color1;
                        color: $white;
                    }
                }
            }
        }
    }
}

.lp_details{
    .fast_nav_box{
        margin-bottom: 35px;
    }
}

.small_gallery{
    .galery_item{
        .desc{
            padding: 35px 34px;
            color: $white;
            font-family: $special_font;
            background: rgba(0,0,0,0.65);
            background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.65)), color-stop(100%, rgba(0,0,0,0)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
            .date{
                padding-top: 15px;
                @include h6;
                @media only screen and (max-width: 1200px) {
                    padding-top: 5px;
                }
                @media only screen and (max-width: 991px) {
                    @include p;
                }
            }
        }
        .desc{
            h3{
                @include h5;
                line-height: 34px;
                @media only screen and (max-width: 991px) {
                    @include h6;
                    line-height: 28px;
                }
            }
        }
    }
}

.main_cadre_list2{
    margin-bottom: 30px;
    ul{
        li{
            .default_font{
                display: inline-block;
                position: relative;
                &:after{
                    content: '';
                    @include anim;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0;
                    border-bottom: 2px solid #000000;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
            &:hover{
               .default_font{
                   &:after{
                       width: 100%;
                   }
               }
            }
        }
    }
}

.current_date{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;

    align-items: flex-end;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 20px;
    }
    .filter_holder{
        padding-bottom: 2px;
    }
    @media only screen and (max-width: 991px) {
        display: block;
        margin-bottom: 0;
        .section_header2{
            span{
                width: 100%;
                display: block;
            }
        }
    }
    .select_box_holder{
        width: auto;
        padding:0;
    }
    > div{
        //&.filter_holder{
        //    padding-left: 24px;
        //    width: calc(100% - (190px * 2));
        //}
        .filter_box{
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
             .chosen-container-single .chosen-single{
                height: auto;
                line-height: unset;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    line-height: unset;
                    @media only screen and (max-width: 991px) {
                        width: auto !important;
                    }
                    &:last-child{
                        width: 310px;
                        padding-left: 12px;
                        @media only screen and (max-width: 1700px) {
                            width: 262px;
                        }
                        @media only screen and (max-width: 1500px) {
                            width: 205px;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.current_date_holder{
    margin-bottom: 20px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 0;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
    }
}
.event_box{
    padding-bottom: 5px;
    > div{
        > div{
            float: none;
            display: inline-block;
            vertical-align: bottom;
        }
    }
}
.event_calendar{
    .event_box{
        .grid_pd{
            display: flex;
            height: 315px;
            margin-bottom: 15px;
            @media only screen and (max-width: 991px) {
                display: block;
                height: auto;
            }
            .calendar{
                height:100%;
                background: $font_color1;
            }
        }
    }
}
.arrows {
    text-align: right;
    margin-bottom: 15px;
    button{
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
    }
    //@include fill;
    //@include vetical;
    //height: 300px;
    //@media only screen and (max-width: 1700px) {
    //    height: 266px;
    //}
    //@media only screen and (max-width: 1500px) {
    //    height: 226px;
    //}
    //@media only screen and (max-width: 1300px) {
    //    height: 17vw;
    //}
    //@media only screen and (max-width: 991px) {
    //    height: 100%;
    //}
    //> div{
    //    width: 100%;
    //    display: flex;
    //    flex-direction: row;
    //    flex-wrap: wrap;
    //    justify-content: space-between;
    //}
    .arrow {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.5);
        cursor: pointer;
        position: relative;
        z-index: 3;
        @include bg_pos;
        background-size: 25px;
        @include anim;
        //@media only screen and (max-width: 1700px) {
        //    margin-bottom: 50px;
        //}
        //@media only screen and (max-width: 1500px) {
        //    margin-bottom: 90px;
        //}
        //@media only screen and (max-width: 1300px) {
        //    margin-bottom: 20%;
        //}
        //@media only screen and (max-width: 991px) {
        //    margin-bottom: 0;
        //}
        &:hover{
            background-color: rgba(255,255,255,1);
        }
        &.arr_left{
            background-image: url("./../img/car_arr_left.png");
        }
        &.arr_right{
            background-image: url("./../img/car_arr_right.png");
        }
        &.slide_play{
            background-image: url("./../img/play2.svg");
            display: none;
        }
        &.slide_pause{
            background-image: url("./../img/pause2.svg");
        }
    }

    .arr_left {

    }
}

.tilt_box{
    position: relative;
    .cover, .img{
        @include fill;
    }
    .img{
        @include bg_pos;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
    }
    &.with_image{
        .cover{
            z-index: 2;
            background: rgba(255,255,255,0.8);
        }
    }
}

.lp_contact{
    .rules{
        input[type="radio"], input[type="checkbox"]{
            margin: 0;
        }
        .check_holder{
            margin-top: -7px;
        }
        .check_desc{
            width: calc(100% - 20px);
            padding-top: 4px;
            padding-left: 10px;
        }
    }
}

.gal_filters{
    padding-top: 35px;
    select{
        width: 275px !important;
    }
    .search_summary {
        .summary_search_holder {
            > input.filter_gallery_search {
                width: calc(100% - 110px);
                padding: 0 15px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            > input.filter_gallery_submit{
                width: 110px;
                font-size: 11px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    font-size: 13px;
                }
            }
        }
    }
    .filter_box_holder{
        margin: 0 -20px;
    }
    .filter_box{
        vertical-align: middle;
        padding: 0 20px;
        margin-right: -4px;
        width: 33.33%;
        @media only screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 15px;
        }
        > ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            > li{
                display: block;
                &:last-child{
                    width: 280px !important;
                    @media only screen and (max-width: 1700px) {
                        width: 250px !important;
                    }
                    @media only screen and (max-width: 1500px) {
                        width: 190px !important;
                    }
                    @media only screen and (max-width: 1300px) {
                        width: 100% !important;
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }
}

.mobile_top_links{
    padding-top: 56px;
    padding-left: 25px;
    ul{
        li{
            margin-bottom: 5px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.library_form_contact{
    margin-bottom: 50px;
    .myform2_contact_form{
        .row{
            margin-bottom: 20px;
        }
    }
}

button{
    background-color: transparent;
    border: 0;
    padding: 0;
}

*{
    &:focus{
        outline: 5px auto;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 0 !important;
        outline-color: coral !important;
    }
}

.page_search_holder{
    position: relative;
    .page_search_box{
        display: none;
        position: absolute;
        top: calc(100% + 20px);
        right: 50%;
        transform: translateX(50%);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        background: $theme_color1;
        padding: 5px;
        > div{
            display: inline-block;
            vertical-align: top;
            input{
                height: 30px;
                font-size: 14px;
                text-transform: uppercase;
            }
            &:first-child{
                width: 200px;
                input{
                    padding: 0 10px;
                    width: 100%;
                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: #908f8e;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: #908f8e;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: #908f8e;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: #908f8e;
                    }
                    border: none;
                    height: 30px;
                    background: transparent;
                    vertical-align: top;
                    display: inline-block;
                    color: #000000;
                }
            }
            &:last-child{
                input{
                    text-transform: uppercase;
                    color: $font_color1;
                    font-family: $main_font;
                    letter-spacing: 1px;
                    background: transparent;
                    border: 0;
                    font-weight: 500;
                }
            }
        }
    }
}

.lesson_filters{
    select{
        width: 100%;
    }
}

.select2-container--default {
    text-align: left;
    .select2-selection--single {
        border: none;
        border-radius: 0;
        background: transparent;
        color: $font_color1;
        //font-size: 13px;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: 700;
        height: 43px;
        .select2-selection__rendered{
            color: $font_color1;
            padding-left: 0;
            line-height: 43px;
        }
        .select2-selection__arrow{
            width: 80px;
            height: 100%;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            background-image: url("./../img/select_arr.png");
            background-position: right center;
            b{
                display: none;
            }
        }
    }
    .select2-search--dropdown {
        background: $theme_color1;
        padding: 4px;
        background: $theme_color1;
        border: 1px solid #f1ebe4;
        -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
        .select2-search__field{
            background: $theme_color1;
            color: $font_color1;
            border: 1px solid #aaa;
            outline: none;
            padding: 4px 20px 4px 5px;
        }
    }
}

.select2-results__option{
    background: $theme_color1;
    font-size: 12px;
    line-height: 15px;
    color: #002043;
    font-weight: 700;
    padding: 5px 6px;
    text-transform: uppercase;
    letter-spacing: 2px;
    &.select2-results__option--highlighted{
        background-color: $font_color1 !important;
    }
}

.select2-dropdown{
    border: 1px solid #e0e5e8;
    border-top: none;
}

.select2-container--default .select2-results__option[aria-selected=true]{
    background-color: #F8F8F8;
}

.select2-results{
    background: $theme_color1;
    border: 1px solid #f1ebe4;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 4px 4px;
    padding: 0 4px 4px 4px;
}

.vertical_btn_holder{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    > div{
        width: 100%;
    }
}

.general_footer_top{
    .mobile_hide{
        img{
            height: 50px;
            width: auto;
            margin-bottom: 10px;
        }
    }
}

.slider_image {
    &.slider_image_pc{
        @media only screen and (max-width: 1200px) {
            display: none !important;
        }
    }
    &.slider_image_tablet{
        display: none;
        @media only screen and (max-width: 1200px) {
            display: block !important;
        }
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
    }
    &.slider_image_mobile{
        display: none;
        @media only screen and (max-width: 767px) {
            display: block !important;
        }
    }
    video#vid{
        object-fit: cover;
    }
}

.graduate_filter{
    .elect2-container{
        width: 100% !important;
    }
}

.gm-style-iw-c{
    @media only screen and (min-width: 991px) {
        width: 536px !important;
        max-width: 536px !important;
        height: 222px !important;
        max-height: 222px !important;
        padding: 30px 35px !important;
        background:#f9fafc !important;
        border-radius: 0 !important;
    }
}

.gm-style .gm-style-iw-t::after{
    background:#f9fafc !important;
}

.gm-style-iw-d{
    @media only screen and (min-width: 991px) {
        height: auto !important;
        max-height: none !important;
        overflow: visible !important;
    }
}
.map_popup{
    .image{
        height: 100px;
        width: 100px;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        margin: 0 auto 15px auto;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        img{
            @include fill;
        }
    }
    .name{
        font-family: $special_font;
        color: $font_color1;
        @include h6;
        @media only screen and (max-width: 991px) {
            text-align: center;
            margin-bottom: 14px;
        }
    }
    .desc{
        margin-bottom: 12px;
        color: $font_color2;
        font-size: 12px;
        line-height: 17px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
    }
    .social{
        padding: 17px 0;
        border-top: 1px solid #bbbcbe;
        border-bottom: 1px solid #bbbcbe;
        margin-bottom: 14px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                margin-right: 12px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .map_btn_popup{
        font-size: 12px;
        line-height: 26px;
        display: inline-block;
        padding: 0 16px;
        text-transform: uppercase;
        @media only screen and (max-width: 991px) {
            width: 100%;
            text-align: center;
        }
        &.map_btn_popup1{
            color: $font_color2;
            background:#ede9e6;
        }
        &.map_btn_popup2{
            color: $white;
            background:$font_color1;
        }
    }
}

.gm-style-iw-d{
    @media only screen and (max-width: 991px) {
        padding-bottom: 15px;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }
}


.form_portfolio_details{
    input[type="text"]:disabled {
        padding: 0;
    }
}

.form_portfolio_details{
    label{
        font-weight: 700 !important;
    }
    .col-md-4.text-right{
        .default_font{
            font-weight: 700 !important;
        }
    }
}

.gm-style .gm-style-iw-c{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}
